import React from "react";
import styled from "@emotion/styled/macro";

const Case = props => {
  return (
    <CaseBox
      onClick={() => {
        window.open(props.path);
      }}
    >
      <CaseImage {...props.cssProps}>{props.img}</CaseImage>
      <CaseDate>{props.date}</CaseDate>
      <CaseTitle>{props.text}</CaseTitle>
    </CaseBox>
  );
};

export default Case;

let CaseBox = styled.div`
  width: 100%;
  height: auto;
  margin-top: 2.5rem;
  margin-right: 10px;
  display: flex;
  cursor: pointer;
  flex-direction: column;

  @media screen and (min-width: 800px) {
    margin-top: 0;
    margin-right: 1rem;
    width: 21.125rem;
  }

  &:hover span {
    cursor: pointer;

    &:before,
    &:after {
      width: 100%;
      opacity: 1;
    }
  }
`;

let CaseImage = styled.div`
  width: 100%;
  height: 10.4375rem;

  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #171c26;

  svg,
  path {
    fill: #828896;
  }

  @media screen and (min-width: 800px) {
    svg,
    path {
      width: 100%;
      height: 100%;
      max-width: ${props => (props.increaseWidth ? "17.725rem" : "10.75rem")};
      max-height: 4rem;
    }
  }

  img {
    opacity: 0.5;
  }
`;

let CaseDate = styled.span`
  font-size: 0.875rem;
  color: #a2b8e3;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0.5rem 0 0 0;
`;

let CaseTitle = styled.span`
  font-size: 1.25rem;
  color: #f9f9f9;
  font-weight: 600;

  @media screen and (min-width: 800px) {
    font-size: 21px;
    line-height: 1.625rem;
    max-width: 21.125rem;
    font-weight: 500;

    transition: all 0.2s ease-in-out;
    position: relative;

    &:before,
    &:after {
      left: 0;
      content: "";
      position: absolute;
      bottom: -10px;
      width: 0px;
      height: 2px;
      margin: 5px 0 0;
      transition: all 0.2s ease-in-out;
      transition-duration: 0.75s;
      opacity: 0;
      background-color: white;
    }
  }
`;
