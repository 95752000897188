import React from "react";
//os svgs que estavam quebrados no XD, importei como png
import Hyperledger from "../../../assets/press/hyperledger.png";
import { ReactComponent as TedX } from "../../../assets/press/tedxMauá.svg";
import { ReactComponent as P22on } from "../../../assets/press/p22on.svg";
import { ReactComponent as VoceSA } from "../../../assets/press/voce-sa.svg";
import { ReactComponent as Estadao } from "../../../assets/press/estadao.svg";
import { ReactComponent as Exame } from "../../../assets/press/exame.svg";
import OlharDigital from "../../../assets/press/olhar-digital.png";
import Insper from "../../../assets/press/insper.png";

import { ReactComponent as CoinTelegraph } from "../../../assets/press/cointelegraph.svg";
import { Wrapper, Reveal } from "../../../components";
import styled from "@emotion/styled/macro";
import PressCarousel from "../../../components/Press/PressCarousel";
import { useTranslation, Trans } from "react-i18next";

const Press = () => {
  const { t, i18n } = useTranslation("home");

  const cases = [
    {
      img: <Exame />,
      path:
        "https://exame.com/tecnologia/startup-usa-blockchain-para-monitorar-infectados-pelo-coronavirus",
      text: "Startup uses blockchain for coronavirus monitoring"
    },
    {
      img: <Estadao />,
      path:
        "https://politica.estadao.com.br/blogs/gestao-politica-e-sociedade/tecnologias-de-monitoramento-da-covid-19-e-mecanismos-de-controle-social/",
      text: "Monitoring technologies and social control mechanisms"
    },
    {
      img: <CoinTelegraph />,
      cssProps: {
        increaseWidth: true
      },
      path: "https://cointelegraph.com.br/news/covid-19-blockchain-privacy-and-monitoring",
      text: "Blockchain on COVID-19 monitoring and privacy"
    },
    {
      img: <CoinTelegraph />,
      cssProps: {
        increaseWidth: true
      },
      path:
        "https://cointelegraph.com.br/news/startup-brasileira-blockforce-lanca-mapa-em-tempo-real-sobre-o-avanco-do-coronavirus-no-brasil",
      text: "Blockforce releases monitoring map about COVID-19"
    },
    {
      img: <img src={OlharDigital} alt={"olhar digital"} width="130" />,
      path:
        "https://olhardigital.com.br/noticia/projeto-gloria-plataforma-de-inteligencia-artificial-sera-usada-para-o-combate-a-violencia-contra-a-mulher-no-brasil/85086",
      text: "Blockforce and partners launch Glória as a platform to combat violence against women"
    },
    {
      img: <img src={Insper} alt={"insper"} width="130" />,
      path:
        "https://medium.com/blockchain-insper/blockchain-nas-institui%C3%A7%C3%B5es-governamentais-43996bc0be7e",
      text: "Blockchain in government entities"
    },
    {
      img: <img src={Hyperledger} alt={"Hyperledger"} />,
      text: "Hyperledger adds 11 new members"
    },
    {
      img: <P22on />,
      path:
        "https://www.p22on.com.br/en/2018/11/02/o-que-e-blockchain-e-como-se-aplica-certificacao/",
      text: "Socio environmental blockchain & Certificaition (FGVCES & P22ON)"
    },
    {
      img: <VoceSA />,
      path: "https://www.instagram.com/p/BuMmNUWF1d8/?hl=en",
      text: "What about blockchain?"
    },
    {
      img: <TedX />,
      path: "https://www.youtube.com/watch?v=LqLA-PGAb-o",
      text: "Blockchain and Social Impact"
    }
  ];

  return (
    <Reveal>
      <PressInit>
        <Wrapper id="press">
          <Title>
            <Trans t={t} i18nKey="press">
              Press
            </Trans>
          </Title>
        </Wrapper>
        <CarouselWrapper>
          <PressCarousel cases={cases} />
        </CarouselWrapper>
      </PressInit>
    </Reveal>
  );
};

let PressInit = styled.div`
  width: 100%;
  height: 100%;
`;

let Title = styled.span`
  font-size: 1.375rem;
  color: #7e91b5;
`;

let CarouselWrapper = styled.div`
  position: relative;
  max-width: 1549px;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  margin: 0 auto;
  .flickity-enabled:focus {
    outline: none;
  }
  @media (max-width: 500px) {
    padding: 0 32px;
  }

  /* .flickity-button {
    width: 5% !important;
  } */
`;

export default Press;
