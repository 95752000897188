/*
  MOEDA
*/
import CubeMoeda from "./assets/cases/moeda/cube.png";
import LogoMoeda from "./assets/cases/moeda/moeda-logo.png";
import MoedaHeader from "./assets/cases/moeda/moeda-header.png";
import Moeda_EthProtocol from "./assets/cases/moeda/eth-protocol.png";
import Moeda_HyperledgerProtocol from "./assets/cases/moeda/hyperledger-protocol.png";
import Moeda_StellarProtocol from "./assets/cases/moeda/stellar-protocol.png";
import Moeda_MoedaProtocol from "./assets/cases/moeda/moeda-protocol.png";
import MoedaMid1 from "./assets/cases/moeda/middle1.png";
import MoedaMid2 from "./assets/cases/moeda/middle2.png";
import MoedaMid3 from "./assets/cases/moeda/middle3.png";
import MoedaMid4 from "./assets/cases/moeda/middle4.png";
import MoedaMid5 from "./assets/cases/moeda/middle5.png";
import MoedaMid6 from "./assets/cases/moeda/middle6.png";
import Moeda_LabelOne from "./assets/cases/moeda/label-1.png";
import Moeda_LabelTwo from "./assets/cases/moeda/label-2.png";
import Moeda_LabelThree from "./assets/cases/moeda/label-3.png";
import Moeda_LabelFour from "./assets/cases/moeda/label-4.png";
/*
  END-MOEDA
*/
/*
  FAIR FASHION
*/
import CubeFairFashion from "./assets/cases/fairFashion/cube.png";
import FairFashionHeader from "./assets/cases/fairFashion/header.png";
import FairFashionLogo from "./assets/cases/fairFashion/logo.png";
import FairFashionLogo1 from "./assets/cases/fairFashion/COPPEAD.png";
import FairFashionLogo2 from "./assets/cases/fairFashion/laudes.png";
import FairFashionLogo3 from "./assets/cases/fairFashion/Einstitute.png";
import FairFashionMiddle1 from "./assets/cases/fairFashion/middle1.png";
import FairFashionMiddle2 from "./assets/cases/fairFashion/middle2.png";
import FairFashionMiddle3 from "./assets/cases/fairFashion/middle3.png";
import FairFashionMiddle4 from "./assets/cases/fairFashion/middle4.png";
import FairFashionMiddle5 from "./assets/cases/fairFashion/middle5.png";
import FairFashionMiddle6 from "./assets/cases/fairFashion/middle6.png";
/*
  END FAIR FASHION
*/
/*
  GLORIA
*/
import CubeGloria from "./assets/cases/gloria/cube.png";
import GloriaHeader from "./assets/cases/gloria/header.png";
import GloriaLogo from "./assets/cases/gloria/logo.png";
import GloriaMiddle1 from "./assets/cases/gloria/middle1.png";
import GloriaMiddle2 from "./assets/cases/gloria/middle2.png";
import GloriaMiddle3 from "./assets/cases/gloria/middle3.png";
import JusticeLabel from "./assets/cases/label_justice.png";
/*
  END GLORIA
*/
/*
  WELIGHT
*/
import CubeWeLight from "./assets/cases/welight/cube.png";
import WeLightHeader from "./assets/cases/welight/header.png";
import WeLightLogo from "./assets/cases/welight/logo.png";
import WeLightMiddle1 from "./assets/cases/welight/middle1.png";
import WeLightMiddle2 from "./assets/cases/welight/middle2.png";
import WeLightMiddle3 from "./assets/cases/welight/middle3.png";
import WeLightMiddle4 from "./assets/cases/welight/middle4.png";
import WeLightMiddle5 from "./assets/cases/welight/middle5.png";
import ResponsibleLabel from "./assets/cases/label_responsible.png";
import PartnerLabel from "./assets/cases/label_partner.png";
/*
  END WELIGHT
*/
/*
  BANCOIN
*/
import CubeBancoin from "./assets/cases/bancoin/cube.png";
import BancoinHeader from "./assets/cases/bancoin/header.png";
import BancoinLogo from "./assets/cases/bancoin/logo.png";
import BancoinMiddle1 from "./assets/cases/bancoin/middle1.png";
import BancoinMiddle2 from "./assets/cases/bancoin/middle2.png";
import BancoinMiddle3 from "./assets/cases/bancoin/middle3.png";
import BancoinMiddle4 from "./assets/cases/bancoin/middle4.png";
import BancoinMiddle5 from "./assets/cases/bancoin/middle5.png";
import BancoinMiddle6 from "./assets/cases/bancoin/middle6.png";
import LabelInequalities from "./assets/cases/label_inequalities.png";
/*
  END BANCOIN
*/
/*
  ORGANIS8
*/
import CubeOrganis from "./assets/cases/organis8/cube.png";
import OrganisHeader from "./assets/cases/organis8/header.png";
import OrganisLogo from "./assets/cases/organis8/logo.png";
import OrganisMiddle1 from "./assets/cases/organis8/middle1.png";
import OrganisMiddle2 from "./assets/cases/organis8/middle2.png";
import OrganisMiddle3 from "./assets/cases/organis8/middle3.png";
import OrganisMiddle4 from "./assets/cases/organis8/middle4.png";
import OrganisMiddle5 from "./assets/cases/organis8/middle5.png";
import OrganisMiddle6 from "./assets/cases/organis8/middle6.png";
/*
  END ORGANIS8
*/
/*
  AMAZONOW
*/
import CubeAmazonow from "./assets/cases/amazonow/cube.png";
import AmazonowHeader from "./assets/cases/amazonow/header.png";
import AmazonowLogo from "./assets/cases/amazonow/logo.png";
import AmazonowMiddle1 from "./assets/cases/amazonow/middle1.jpg";
import AmazonowMiddle2 from "./assets/cases/amazonow/middle2.jpg";
import AmazonowMiddle3 from "./assets/cases/amazonow/middle3.jpg";
import LabelEnv from "./assets/cases/label_environment.png";
import LabelLife from "./assets/cases/label_life.png";
/*
  END AMAZONOW
*/
/*
  TETRAPAK
*/
import CubeTetrapak from "./assets/cases/tetrapak/cube.png";
import TetrapakHeader from "./assets/cases/tetrapak/header.png";
import TetrapakLogo from "./assets/cases/tetrapak/logo.png";
/*
  END TETRAPAK
*/
/*
  FGV EAESP
*/
import CubeFgveaesp from "./assets/cases/fgv-eaesp/cube.png";
import FgveaespHeader from "./assets/cases/fgv-eaesp/header.png";
import FgveaespLogo from "./assets/cases/fgv-eaesp/logo.png";
import FgveaespMiddle from "./assets/cases/fgv-eaesp/middle.png";
import LabelCleanEnergy from "./assets/cases/label_cleanEnergy.png";
import LabelEconomy from "./assets/cases/label_economy.png";
/*
  END FGV EAESP
*/
/*
  FGV Realio
*/
import CubeRealio from "./assets/cases/realio/cube.png";
import RealioHeader from "./assets/cases/realio/header.png";
import RealioLogo from "./assets/cases/realio/logo.png";
import RealioMiddle1 from "./assets/cases/realio/middle1.png";
import RealioMiddle2 from "./assets/cases/realio/middle2.png";
import RealioMiddle3 from "./assets/cases/realio/middle3.png";
import RealioMiddle4 from "./assets/cases/realio/middle4.png";
import RealioMiddle5 from "./assets/cases/realio/middle5.png";
import RealioMiddle6 from "./assets/cases/realio/middle6.png";
/*
  END Realio
*/
/*
  FGV Desviralize
*/
import CubeDesviralize from "./assets/cases/desviralize/cube.png";
import DesviralizeHeader from "./assets/cases/desviralize/header.png";
import DesviralizeLogo from "./assets/cases/desviralize/logo.png";
import DesviralizeMiddle1 from "./assets/cases/desviralize/middle1.png";
import DesviralizeMiddle2 from "./assets/cases/desviralize/middle2.png";
import DesviralizeMiddle3 from "./assets/cases/desviralize/middle3.png";
import DesviralizeMiddle4 from "./assets/cases/desviralize/middle4.png";
import DesviralizeMiddle5 from "./assets/cases/desviralize/middle5.png";
import DesviralizeMiddle6 from "./assets/cases/desviralize/middle6.png";
import IndustryLabel from "./assets/cases/label_industry.png";
/*
  END Desviralize
*/
/*
  FGV FGV CES
*/
import CubeFgvces from "./assets/cases/fgv-ces/cube.png";
import FgvcesHeader from "./assets/cases/fgv-ces/header.png";
import FgvcesLogo from "./assets/cases/fgv-ces/logo.png";
import FgvcesMiddle from "./assets/cases/fgv-ces/middle.png";
/*
  END FGV CES
*/

const traceability = { name: "territories.traceability", color: "#1777b0" };
const dataGovernance = { name: "territories.data-governance", color: "#00ca97" };
const newEconomies = { name: "territories.new-economies", color: "#6633ca" };
const blockchainDisruptor = { name: "territories.blockchain-disruptor", color: "#d36200" };

export default {
  moeda: {
    path: "moeda",
    title: "Moeda",
    img: CubeMoeda,
    titleImg: LogoMoeda,
    mainImg: MoedaHeader,
    slogan: "moeda.slogan",
    mainColor: "#00CC99",
    website: "moedaseeds.com",
    services: "moeda.services",
    territories: [traceability, newEconomies],
    overview: "moeda.overview",
    challenge: "moeda.challenge",
    solution: "moeda.solution",
    protocol: [
      Moeda_EthProtocol,
      Moeda_HyperledgerProtocol,
      Moeda_StellarProtocol,
      Moeda_MoedaProtocol
    ],
    imageMid: [MoedaMid1, MoedaMid2, MoedaMid3, MoedaMid4, MoedaMid5, MoedaMid6],
    results: "moeda.results",
    labels: [Moeda_LabelOne, Moeda_LabelTwo, Moeda_LabelThree, Moeda_LabelFour],
    quote: "moeda.quote",
    quoter: "moeda.quoter"
  },
  fairfashion: {
    path: "fairfashion",
    title: "fairfashion.title",
    img: CubeFairFashion,
    titleImg: FairFashionLogo,
    upperTitleImg: [FairFashionLogo2, FairFashionLogo1, FairFashionLogo3],
    mainImg: FairFashionHeader,
    slogan: "fairfashion.slogan",
    mainColor: "#03CDB9",
    website: "fairfashion.com.br",
    services: "fairfashion.services",
    territories: [traceability],
    overview: "fairfashion.overview",
    challenge: "fairfashion.challenge",
    solution: "fairfashion.solution",
    protocol: [Moeda_HyperledgerProtocol],
    imageMid: [
      FairFashionMiddle1,
      FairFashionMiddle2,
      FairFashionMiddle3,
      FairFashionMiddle4,
      FairFashionMiddle5,
      FairFashionMiddle6
    ],
    results: "fairfashion.results",
    labels: [Moeda_LabelTwo, Moeda_LabelFour],
    quote: "fairfashion.quote",
    quoter: "fairfashion.quoter",
    quote2: "fairfashion.quote2",
    quoter2: "fairfashion.quoter2"
  },
  gloria: {
    path: "gloria",
    title: "gloria.title",
    img: CubeGloria,
    titleImg: GloriaLogo,
    mainImg: GloriaHeader,
    slogan: "gloria.slogan",
    mainColor: "#A0009C",
    website: "eusouagloria.com.br",
    services: "gloria.services",
    territories: [dataGovernance],
    overview: "gloria.overview",
    challenge: "gloria.challenge",
    solution: "gloria.solution",
    protocol: [],
    imageMid: [GloriaMiddle1, GloriaMiddle2, GloriaMiddle3],
    results: "gloria.results",
    labels: [Moeda_LabelOne, JusticeLabel],
    quote: "gloria.quote",
    quoter: "gloria.quoter"
  },
  welight: {
    path: "welight",
    title: "welight.title",
    img: CubeWeLight,
    titleImg: WeLightLogo,
    mainImg: WeLightHeader,
    slogan: "welight.slogan",
    mainColor: "#F4964E",
    website: "welight.co",
    services: "welight.services",
    territories: [traceability],
    overview: "welight.overview",
    challenge: "welight.challenge",
    solution: "welight.solution",
    protocol: [Moeda_HyperledgerProtocol],
    imageMid: [WeLightMiddle1, WeLightMiddle2, WeLightMiddle3, WeLightMiddle4, WeLightMiddle5],
    results: "welight.results",
    labels: [ResponsibleLabel, PartnerLabel],
    quote: "welight.quote",
    quoter: "welight.quoter"
  },
  bancoin: {
    path: "bancoin",
    title: "Bancoin",
    img: CubeBancoin,
    titleImg: BancoinLogo,
    mainImg: BancoinHeader,
    slogan: "bancoin.slogan",
    mainColor: "#FF422C",
    website: "bancoin.com.br",
    services: "bancoin.services",
    territories: [newEconomies],
    overview: "bancoin.overview",
    challenge: "bancoin.challenge",
    solution: "bancoin.solution",
    protocol: [Moeda_HyperledgerProtocol, Moeda_StellarProtocol],
    imageMidCss: true,
    imageMid: [
      BancoinMiddle1,
      BancoinMiddle2,
      BancoinMiddle3,
      BancoinMiddle4,
      BancoinMiddle5,
      BancoinMiddle6
    ],
    results: "bancoin.results",
    labels: [Moeda_LabelTwo, LabelInequalities],
    quote: "bancoin.quote",
    quoter: "bancoin.quoter"
  },
  "ache-organicos": {
    path: "ache-organicos",
    title: "ache-organicos.title",
    img: CubeOrganis,
    titleImg: OrganisLogo,
    mainImg: OrganisHeader,
    slogan: "ache-organicos.slogan",
    mainColor: "#6DC248",
    website: "organis8.com",
    services: "ache-organicos.services",
    territories: [dataGovernance, traceability],
    overview: "ache-organicos.overview",
    challenge: "ache-organicos.challenge",
    solution: "ache-organicos.solution",
    protocol: [Moeda_HyperledgerProtocol],
    imageMid: [
      OrganisMiddle1,
      OrganisMiddle2,
      OrganisMiddle3,
      OrganisMiddle4,
      OrganisMiddle5,
      OrganisMiddle6
    ],
    results: "ache-organicos.results",
    labels: [Moeda_LabelTwo, ResponsibleLabel],
    quote: "ache-organicos.quote",
    quoter: "ache-organicos.quoter"
  },
  amazonow: {
    path: "amazonow",
    title: "amazonow.title",
    img: CubeAmazonow,
    titleImg: AmazonowLogo,
    titleImgSpecial: true,
    mainImg: AmazonowHeader,
    slogan: "amazonow.slogan",
    mainColor: "#48A230",
    website: "amazonialerta.org",
    specialCarouselCss: {
      position: "relative",
      bottom: "23px"
    },
    services: "amazonow.services",
    territories: [dataGovernance],
    overview: "amazonow.overview",
    challenge: "amazonow.challenge",
    solution: "amazonow.solution",
    protocol: [],
    imageMidCss: true,
    imageMid: [AmazonowMiddle1, AmazonowMiddle2, AmazonowMiddle3],
    results: "amazonow.results",
    labels: [LabelEnv, LabelLife, JusticeLabel],
    quote: "amazonow.quote",
    quoter: "amazonow.quoter"
  },
  tetrapak: {
    path: "tetrapak",
    title: "tetrapak.title",
    img: CubeTetrapak,
    titleImg: TetrapakLogo,
    mainImg: TetrapakHeader,
    slogan: "tetrapak.slogan",
    mainColor: "#346CAA",
    website: "tetrapak.com",
    services: "tetrapak.services",
    territories: [traceability],
    overview: "tetrapak.overview",
    challenge: "tetrapak.challenge",
    solution: "tetrapak.solution",
    protocol: [],
    imageMid: null,
    results: "tetrapak.results",
    labels: [LabelEnv, LabelLife, JusticeLabel],
    quote: "tetrapak.quote",
    quoter: "tetrapak.quoter"
  },
  ghgprotocol: {
    path: "ghgprotocol",
    title: "ghgprotocol.title",
    img: CubeFgveaesp,
    titleImg: FgvcesLogo,
    titleText: "ghgprotocol.titleText",
    mainImg: FgveaespHeader,
    slogan: "ghgprotocol.slogan",
    mainColor: "#2a79d2",
    website: "ghgprotocolbrasil.com.br",
    services: "ghgprotocol.services",
    territories: [traceability],
    overview: "ghgprotocol.overview",
    challenge: "ghgprotocol.challenge",
    solution: "ghgprotocol.solution",
    protocol: [],
    bookStyle: true,
    imageMid: FgveaespMiddle,
    imageMidPath:
      "http://mediadrawer.gvces.com.br/publicacoes-2/original/relatorio-executivo-ghg-blockchain.pdf",
    results: "ghgprotocol.results",
    labels: [LabelCleanEnergy],
    quote: "ghgprotocol.quote",
    quoter: "ghgprotocol.quoter"
  },
  // "rio-doce": {
  //   path: "rio-doce",
  //   title: "rio-doce.title",
  //   img: CubeFgvces,
  //   titleImg: FgvcesLogo,
  //   titleText: "rio-doce.titleText",
  //   mainImg: FgvcesHeader,
  //   slogan: "rio-doce.slogan",
  //   mainColor: "#003F87",
  //   website: "gvces.com.br",
  //   services: "rio-doce.services",
  //   territories: [dataGovernance],
  //   overview: "rio-doce.overview",
  //   challenge: "rio-doce.challenge",
  //   solution: "rio-doce.solution",
  //   protocol: [],
  //   imageMidPath:
  //     "http://mediadrawer.gvces.com.br/publicacoes-2/original/relatorio-executivo-ghg-blockchain.pdf",
  //   imageMid: FgvcesMiddle,
  //   results: "rio-doce.results",
  //   labels: [LabelEconomy, LabelInequalities, JusticeLabel],
  //   quote: "",
  //   quoter: ""
  // },
  realio: {
    path: "realio",
    title: "realio.title",
    img: CubeRealio,
    titleImg: RealioLogo,
    mainImg: RealioHeader,
    slogan: "realio.slogan",
    mainColor: "#102746",
    website: "realio.fund",
    services: "realio.services",
    territories: [newEconomies, blockchainDisruptor],
    overview: "realio.overview",
    challenge: "realio.challenge",
    solution: "realio.solution",
    protocol: [],
    imageMid: [
      RealioMiddle1,
      RealioMiddle2,
      RealioMiddle3,
      RealioMiddle4,
      RealioMiddle5,
      RealioMiddle6
    ],
    results: "realio.results",
    labels: [IndustryLabel],
    quote: "realio.quote",
    quoter: "realio.quoter"
  },
  desviralize: {
    path: "desviralize",
    title: "desviralize.title",
    img: CubeDesviralize,
    titleImg: DesviralizeLogo,
    mainImg: DesviralizeHeader,
    sizeInCarousel: "55px",
    sizeInTitle: "200px",
    slogan: "desviralize.slogan",
    mainColor: "#FF5A2E",
    website: "desviralize.org",
    services: "desviralize.services",
    territories: [dataGovernance],
    overview: "desviralize.overview",
    challenge: "desviralize.challenge",
    solution: "desviralize.solution",
    protocol: [Moeda_EthProtocol, Moeda_StellarProtocol, Moeda_HyperledgerProtocol],
    imageMid: [
      DesviralizeMiddle1,
      DesviralizeMiddle2,
      DesviralizeMiddle3,
      DesviralizeMiddle4,
      DesviralizeMiddle5,
      DesviralizeMiddle6
    ],
    results: "desviralize.results",
    labels: [PartnerLabel, LabelLife, IndustryLabel],
    quote: "desviralize.quote",
    quoter: "desviralize.quoter"
  }
};
