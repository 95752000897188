import React from "react";
import styled from "@emotion/styled/macro";

let Svg = styled.svg`
  animation: ${({ inView, delay }) =>
    inView ? `g 1s ${delay || 0}ms cubic-bezier(0.7, 0.7, 0, 1) forwards` : ""};
  transform: scale(0);

  @keyframes g {
    to {
      transform: scale(1);
    }
  }
`;

export default ({ inView, delay }) => (
  <Svg viewBox="0 0 521 521" inView={inView} delay={delay}>
    <defs>
      <linearGradient id="a" x1=".5%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#B81E7C" />
        <stop offset="50%" stopColor="#1B5997" />
        <stop offset="100%" stopColor="#58BF9B" />
      </linearGradient>
      <path
        id="c"
        d="M259.423 287.057c15.23 0 27.575-12.348 27.575-27.58 0-15.232-12.346-27.58-27.575-27.58s-27.574 12.348-27.574 27.58c0 15.232 12.345 27.58 27.574 27.58z"
      />
      <filter
        id="b"
        width="123.8%"
        height="123.8%"
        x="-11.9%"
        y="-6.5%"
        filterUnits="objectBoundingBox"
      >
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius=".563"
          result="shadowSpreadOuter1"
        />
        <feOffset dy="3" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feMorphology in="SourceAlpha" radius="1" result="shadowInner" />
        <feOffset dy="3" in="shadowInner" result="shadowInner" />
        <feComposite
          in="shadowOffsetOuter1"
          in2="shadowInner"
          operator="out"
          result="shadowOffsetOuter1"
        />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
      </filter>
    </defs>
    <g fill="none" transform="translate(1.153 1.045)">
      <path
        stroke="#878787"
        strokeDasharray="1.124999970197678,3.375000059604645"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        d="M259.986 469.423c115.615 0 209.34-93.744 209.34-209.383 0-115.639-93.725-209.383-209.34-209.383-115.615 0-209.34 93.744-209.34 209.383 0 115.64 93.725 209.383 209.34 209.383z"
        opacity=".204"
        id="f"
      />
      <g stroke="url(#a)">
        <circle cx="259.423" cy="259.477" strokeWidth=".787" r="259" />
        <circle cx="259.986" cy="260.04" strokeWidth="1" r="150" />
        <circle
          cx="259.423"
          cy="259.477"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          r="50"
        />
      </g>
      <path
        stroke="#878787"
        strokeDasharray="1.124999970197678,3.375000059604645"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        d="M259.423 364.732c-58.118 0-105.232-47.124-105.232-105.255 0-58.13 47.114-105.254 105.232-105.254 58.119 0 105.233 47.124 105.233 105.254s-47.114 105.255-105.233 105.255z"
        opacity=".204"
        id="e"
      />
      <g
        strokeDasharray="1.124999970197678,3.375000059604645"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity=".204"
        transform="matrix(-1 0 0 1 518.847 0)"
      >
        <use fill="#000" filter="url(#b)" xlinkHref="#c" />
        <use stroke="#878787" strokeWidth="1" xlinkHref="#c" />
      </g>
      <circle fill="#B81E7C" r="6.19">
        <animateMotion dur="48s" repeatCount="indefinite">
          <mpath xlinkHref="#f" />
        </animateMotion>
      </circle>
      <circle fill="#0080FF" r="6.19">
        <animateMotion
          dur="24s"
          repeatCount="indefinite"
          keyPoints="1;0"
          keyTimes="0;1"
          calcMode="linear"
        >
          <mpath xlinkHref="#e" />
        </animateMotion>
      </circle>
      <circle fill="#58BF9B" r="6.19">
        <animateMotion dur="12s" repeatCount="indefinite">
          <mpath xlinkHref="#c" />
        </animateMotion>
      </circle>
    </g>
  </Svg>
);
