import React from "react";
import styled from "@emotion/styled/macro";
import { Wrapper, Section, Reveal } from "../../../components";
import { history } from "../../../lib";

import blockchainConsultingCover from "../../../assets/solutions/blockchain_consulting.png";
import blockchainDevelopmentCover from "../../../assets/solutions/hexagon_green.png";

import { useTranslation } from "react-i18next";

export default () => {
  const { t } = useTranslation("home");

  const navigateToSolutionPage = uri => {
    history.navigate(`${uri}`);
  };

  const solutions = [
    {
      uri: t("whatWeDo.consultingLink"),
      title: t("whatWeDo.blockchainConsulting"),
      description: t("whatWeDo.blockchainConsultingDescription"),
      image: blockchainConsultingCover,
      buttonLabel: t("whatWeDo.learnMore"),
      borderColor: "#691CC9"
    },
    {
      uri: "/products",
      title: t("whatWeDo.blockchainDevelopment"),
      description: t("whatWeDo.blockchainDevelopmentDescription"),
      image: blockchainDevelopmentCover,
      buttonLabel: t("whatWeDo.checkOurProducts"),
      borderColor: "#00CE9B"
    }
  ];

  return (
    <Reveal>
      <Wrapper style={{ position: "relative", zIndex: 10, marginTop: "56px" }}>
        <Section title={t("whatWeDo.title")} id="WHAT_WE_DO">
          <SolutionCardRow>
            {solutions.map(solution => (
              <SolutionCard key={solution.title} style={{ borderTopColor: solution.borderColor }}>
                <SolutionContent>
                  <SolutionTitle
                    dangerouslySetInnerHTML={{ __html: solution.title }}
                  ></SolutionTitle>
                  <SolutionDescription>{solution.description}</SolutionDescription>
                  <CallToActionButton onClick={() => navigateToSolutionPage(solution.uri)}>
                    {solution.buttonLabel}
                  </CallToActionButton>
                </SolutionContent>
                <SolutionImage
                  style={{ backgroundImage: `url(${solution.image})` }}
                ></SolutionImage>
              </SolutionCard>
            ))}
          </SolutionCardRow>
        </Section>
      </Wrapper>
    </Reveal>
  );
};

export const SolutionCardRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 60px;
  padding-bottom: 40px;
  @media (max-width: 900px) {
    flex-direction: column;
    margin-top: 0px;
  }
`;

export const SolutionCard = styled.div`
  flex: 1;

  background-color: #171c26;

  border-top-style: solid;
  border-top-width: 6px;

  display: flex;
  flex-direction: row;

  @media (max-width: 1078px) {
    &:not(:first-of-type) {
      margin-top: 36px;
    }
    margin-right: 0 !important;
  }

  min-height: 420px;
  padding: 24px;

  &:first-of-type {
    margin-right: 18px;
  }

  @media (max-width: 800px) {
    flex-direction: column-reverse;
  }
`;

export const SolutionContent = styled.div`
  flex: 6;
  display: flex;
  flex-direction: column;

  padding: 12px;
  margin-right: 24px;
`;

export const SolutionImage = styled.div`
  flex: 5;
  min-width: 170px;
  min-height: 170px;
  margin: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const SolutionTitle = styled.h1`
  line-height: 1.3;
  font-size: 28px;

  margin-bottom: 0;
  margin-top: 0;

  font-weight: 600;
`;

export const SolutionDescription = styled.p`
  margin-top: 12px;
  color: white;
`;

export const CallToActionButton = styled.button`
  border: 2px solid #7e90b4;
  border-radius: 8px;

  color: #8da1ca;
  text-transform: uppercase;
  font-weight: bold;

  background: transparent;
  outline: none;

  width: 100%;
  margin-top: auto;
  padding: 18px;

  &:hover {
    color: #c5d8ff;
    border-color: #c5d8ff;
    cursor: pointer;
  }
`;
