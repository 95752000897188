import React, { useEffect } from "react";
import styled from "@emotion/styled/macro";
import { Logo, Wrapper } from "../../components";
import { Footer, Nav } from "../../components";
import Contact from "../Home/sections/Contact";
import { default as TeamComponent } from "../Home/sections/Team";

export default function Team({ scroll, location }) {
  useEffect(() => {
    scroll.restore(location, "auto");
  }, []);

  return (
    <div>
      <Wrapper>
        <LogoMargin>
          <Logo />
        </LogoMargin>
      </Wrapper>
      <Nav />
      <TeamComponent />
      <Contact />
      <Footer />
    </div>
  );
}

let LogoMargin = styled.div`
  width: 100%;
  height: 100%;
  margin: 2rem 0 0 0;
`;
