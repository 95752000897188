import styled from "@emotion/styled/macro";
import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

let caseMnt = document.getElementById("case");

export default ({ size, top }) => {
  let { current: el } = useRef(document.createElement("div"));
  let ref = useRef();

  useEffect(() => {
    caseMnt.appendChild(el);

    setTimeout(
      () => {
        let { style } = ref.current;

        style.opacity = 1;
        style.transform = "none";
      },
      window.matchMedia("(max-width: 500px)").matches ? 30 : 300
    );

    return () => {
      caseMnt.removeChild(el);
    };
  }, []);

  let { innerWidth: w, innerHeight: h } = window;
  let transform = `translateY(${top + size / 2 - h / 2}px) scale(${size / w}, ${size / h})`;

  return createPortal(<CaseWrapper transform={transform} ref={ref} />, el);
};

let CaseWrapper = styled.div`
  background: #fff;
  position: fixed;
  background: white;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  will-change: transform;
  opacity: 0;
  transition: transform 0.5s cubic-bezier(0.3, 0.3, 0.6, 1);
  transform: ${({ transform }) => transform};
`;
