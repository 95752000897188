import styled from "@emotion/styled/macro";
import React from "react";

let getRotation = (dir = "right") => {
  switch (dir.toLowerCase()) {
    case "down":
      return 0.25;
    case "left":
      return 0.5;
    case "top":
      return 0.75;
    default:
      return 0;
  }
};

export default props => (
  <ArrowWrapper {...props}>
    <svg width="48" height="12" viewBox="0 0 27 12">
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M0 6h26M21 0l6 6-6 6"
      />
    </svg>
  </ArrowWrapper>
);

export let ArrowWrapper = styled.div`
  height: 32px;
  width: 48px;
  margin: 0 -8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${p => `rotate(${getRotation(p.dir)}turn)`};
  outline: 0;

  &:hover {
    path {
      animation: p 0.6s cubic-bezier(0.6, 0, 0.6, 1);
    }
  }

  @keyframes p {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    49% {
      opacity: 0;
      transform: translateX(50%);
    }
    50% {
      transform: translateX(-50%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;
