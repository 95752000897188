import React, { useState } from "react";
import styled from "@emotion/styled/macro";
import css from "@emotion/css/macro";
import { Section, InnerWrapper, Reveal, Wrapper } from "../../../components";
import scopeOfWorkBG from "../../../assets/scope_of_work_bg.png";
import { useTranslation, Trans } from "react-i18next";
import Modal from "react-modal";
const linkBlog = "https://medium.com/@blockforce.in";

Modal.setAppElement("#root");
Modal.defaultStyles.overlay.backgroundColor = "transparent";
Modal.defaultStyles.overlay.zIndex = "20";
Modal.defaultStyles.content.backgroundColor = "transparent";
Modal.defaultStyles.content.border = "0";
Modal.defaultStyles.content.display = "flex";
Modal.defaultStyles.content.justifyContent = "center";
Modal.defaultStyles.content.alignItems = "center";
Modal.defaultStyles.content.padding = "0";
Modal.defaultStyles.content.top = 0;
Modal.defaultStyles.content.left = 0;
Modal.defaultStyles.content.right = 0;
Modal.defaultStyles.content.bottom = 0;
export default () => {
  const { t } = useTranslation("home");

  const [isModalVisible, setModalVisible] = useState(false);
  const [isModalVisible2, setModalVisible2] = useState(false);
  const [isModalVisible3, setModalVisible3] = useState(false);
  const [isModalVisible4, setModalVisible4] = useState(false);

  return (
    <Reveal style={{ position: "relative", zIndex: 15 }}>
      {inView => (
        <Wrapper style={{ position: "relative", zIndex: 15 }}>
          <Modal isOpen={isModalVisible}>
            <ScopeModal style={{ borderTopColor: "#00cd9b" }}>
              <h1>
                <Trans t={t} i18nKey="scopeOfWork.dataGovernance">
                  DATA GOVERNANCE
                </Trans>
              </h1>

              <h2>
                <Trans t={t} i18nKey="scopeOfWork.recordKeeper">
                  Class of solutions: Record Keeper
                </Trans>
              </h2>

              <p style={{ color: "#999", fontFamily: "Titillium Web" }}>
                <Trans t={t} i18nKey="scopeOfWork.recordKeeperDescription">
                  This field is related to initiatives whose primary purpose is to ensure that
                  records cannot be corrupted and that they can be audited on demand. Projects could
                  be led by one organization that primarily benefits from it, or they could provide
                  a common service for multiple organizations. Government entities tend to be
                  suitable for a range of initiatives where the focus is on the key value drivers of
                  immutability and traceability. These initiatives do not involve digital assets or
                  a strong consensus-based decision mechanism. The intent of use of the distributed
                  ledger is resiliency, rather than decentralization across parties.
                </Trans>
              </p>

              <CloseButton
                style={{
                  border: "none",
                  fontSize: "40px"
                }}
                onClick={() => {
                  setModalVisible(false);
                  document.body.style.overflowY = "auto";
                }}
              >
                &times;
              </CloseButton>
              <a href={t("scopeOfWork.recordKeeperLink")}>
                <ButtonBlog style={{ fontWeight: 600 }}>
                  <Trans t={t} i18nKey="scopeOfWork.readMoreBlog">
                    READ MORE ON OUR BLOG
                  </Trans>
                </ButtonBlog>
              </a>
            </ScopeModal>
          </Modal>
          <Modal isOpen={isModalVisible2}>
            <ScopeModal style={{ borderTopColor: "#1777b0" }} open={isModalVisible2}>
              <h1>
                <Trans t={t} i18nKey="scopeOfWork.traceability">
                  TRACEABILITY
                </Trans>
              </h1>

              <h2>
                <Trans t={t} i18nKey="scopeOfWork.efficiencyPlay">
                  Class of solutions: Efficiency Play
                </Trans>
              </h2>

              <p style={{ color: "#999", fontFamily: "Titillium Web" }}>
                <Trans t={t} i18nKey="scopeOfWork.efficiencyPlayDescription">
                  These initiatives attempt to improve efficiencies in existing business processes
                  within a company or at an industry level. They tend to preserve the current
                  business models and the actors within. Decentralization is attempted only at the
                  technology architecture level, if at all. In these initiatives, there is no new
                  market such as those created in the digital asset market initiative. The use of
                  blockchain is limited to activities once a transaction or interaction is complete.
                  That is, blockchain facilitates the chain as a whole, involving all stakeholders.
                  The key value drivers of blockchain for these initiatives are the distributed
                  ledger and the immutability and traceability of records. Consensus can be strong
                  or not, depending on how much decentralization is sought in the use case. Smart
                  contract usage is optional. These initiatives tend to not have new digital assets
                  or to use cryptocurrencies for payments.
                </Trans>
              </p>

              <CloseButton
                style={{
                  border: "none",
                  fontSize: "40px"
                }}
                onClick={() => {
                  setModalVisible2(false);
                  document.body.style.overflowY = "auto";
                }}
              >
                &times;
              </CloseButton>

              <a href={t("scopeOfWork.efficiencyPlayLink")}>
                <ButtonBlog style={{ fontWeight: 600 }}>
                  <Trans t={t} i18nKey="scopeOfWork.readMoreBlog">
                    READ MORE ON OUR BLOG
                  </Trans>
                </ButtonBlog>
              </a>
            </ScopeModal>
          </Modal>
          <Modal isOpen={isModalVisible3}>
            <ScopeModal style={{ borderTopColor: "#6824c9" }} open={isModalVisible3}>
              <h1>
                <Trans t={t} i18nKey="scopeOfWork.newEconomies">
                  NEW ECONOMIES
                </Trans>
              </h1>

              <h2>
                <Trans t={t} i18nKey="scopeOfWork.digitalAssetMarket">
                  Class of solutions: Digital Asset Market
                </Trans>
              </h2>

              <p style={{ color: "#999", fontFamily: "Titillium Web" }}>
                <Trans t={t} i18nKey="scopeOfWork.digitalAssetMarketDescription">
                  These initiatives are new markets that facilitate the creation (or representation)
                  and trading of new digital assets. Digital asset markets tend to use all the value
                  drivers of blockchain, including its ability to create/represent digital assets,
                  the distributed ledger, a strong consensus mechanism, the immutability and
                  traceability of records, acceptance of cryptocurrency tokens, and smart contracts.
                  Blockchain’s capabilities in tracking the provenance of an asset, as a consensus
                  mechanism to consummate a transaction, plus having the ability to improve clearing
                  and settlement functions and records management, all contribute to developing
                  these markets. Note that a digital asset market is a specific kind of blockchain
                  disruptor, one that uses blockchain’s ability to represent a digital asset and
                  offers a market based on it.
                </Trans>
              </p>

              <CloseButton
                style={{
                  border: "none",
                  fontSize: "40px"
                }}
                onClick={() => {
                  setModalVisible3(false);
                  document.body.style.overflowY = "auto";
                }}
              >
                &times;
              </CloseButton>

              <a href={t("scopeOfWork.digitalAssetMarketLink")}>
                <ButtonBlog style={{ fontWeight: 600 }}>
                  <Trans t={t} i18nKey="scopeOfWork.readMoreBlog">
                    READ MORE ON OUR BLOG
                  </Trans>
                </ButtonBlog>
              </a>
            </ScopeModal>
          </Modal>
          <Modal isOpen={isModalVisible4}>
            <ScopeModal style={{ borderTopColor: "#d66413" }} open={isModalVisible4}>
              <h1>
                <Trans t={t} i18nKey="scopeOfWork.blockchainDisruptor">
                  BLOCKCHAIN DISRUPTOR
                </Trans>
              </h1>

              <h2>
                <Trans t={t} i18nKey="scopeOfWork.DAO">
                  Class of solutions: Distribuited Autonomous Organizations
                </Trans>
              </h2>

              <p style={{ color: "#999", fontFamily: "Titillium Web" }}>
                <Trans t={t} i18nKey="scopeOfWork.DAODescription">
                  These initiatives rely primarily on a blockchain foundation to achieve
                  decentralization of business and/or technology functions. Their critical business
                  functionality is enabled by most of blockchain’s capabilities, including the
                  distributed ledger, a strong consensus mechanism, the immutability and
                  traceability of records, and acceptance of cryptocurrency tokens. In most cases,
                  they employ smart contracts to encapsulate certain business functionality. Their
                  business models may or may not be new. Many of them tend to raise capital through
                  initial coin offerings (ICOs).
                </Trans>
              </p>

              <CloseButton
                style={{
                  border: "none",
                  fontSize: "40px"
                }}
                onClick={() => {
                  setModalVisible4(false);
                  document.body.style.overflowY = "auto";
                }}
              >
                &times;
              </CloseButton>

              <a href={t("scopeOfWork.DAOLink")}>
                <ButtonBlog style={{ fontWeight: 600 }}>
                  <Trans t={t} i18nKey="scopeOfWork.readMoreBlog">
                    READ MORE ON OUR BLOG
                  </Trans>
                </ButtonBlog>
              </a>
            </ScopeModal>
          </Modal>
          <Section
            style={{ backgroundImage: `url(${scopeOfWorkBG})` }}
            title={t("scopeOfWork.title")}
            id="scope"
          >
            <SectionDescription>
              <Trans t={t} i18nKey="scopeOfWork.description">
                We operate based on 4 macro-territories that shape our vision of impact.
              </Trans>
            </SectionDescription>

            <ScopeWrapper>
              <ScopeColumn>
                <Reason inView={inView}>
                  <ScopeTitle color="#00CC99">
                    <ScopeSubtitle style={{ display: "inline-block" }}>
                      <Trans t={t} i18nKey="scopeOfWork.governance">
                        DATA GOVERNANCE
                      </Trans>
                    </ScopeSubtitle>
                    <OpenModalButton
                      onClick={() => {
                        setModalVisible(true);
                        setModalVisible2(false);
                        setModalVisible3(false);
                        setModalVisible4(false);
                        document.body.style.overflowY = "hidden"; // Stop screen when modal is open
                        //document.body.style.background = `rgba(0, 0, 0, 0.2)`;
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <circle cx="12" cy="12" r="10" />
                        <line x1="12" y1="8" x2="12" y2="16" />
                        <line x1="8" y1="12" x2="16" y2="12" />
                      </svg>
                    </OpenModalButton>
                  </ScopeTitle>
                  <ScopeText style={{ padding: "0 50px 0 0" }}>
                    <Trans t={t} i18nKey="scopeOfWork.governanceText">
                      Immutable and secure blockchain-based records management by one entity, for
                      self or for a community.
                    </Trans>
                  </ScopeText>
                </Reason>

                <Reason delay={150} inView={inView}>
                  <ScopeTitle color="#1777b0">
                    <ScopeSubtitle style={{ display: "inline-block" }}>
                      <Trans t={t} i18nKey="scopeOfWork.traceability">
                        TRACEABILITY
                      </Trans>
                    </ScopeSubtitle>
                    <OpenModalButton
                      onClick={() => {
                        setModalVisible2(true);
                        setModalVisible(false);
                        setModalVisible3(false);
                        setModalVisible4(false);
                        document.body.style.overflowY = "hidden";
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <circle cx="12" cy="12" r="10" />
                        <line x1="12" y1="8" x2="12" y2="16" />
                        <line x1="8" y1="12" x2="16" y2="12" />
                      </svg>
                    </OpenModalButton>
                  </ScopeTitle>
                  <ScopeText style={{ padding: "0 68px 0 0" }}>
                    <Trans t={t} i18nKey="scopeOfWork.traceabilityText">
                      Efficiency and visibility improvements in transactions, interactions and
                      tracking provenance of assets towards a network of value.
                    </Trans>
                  </ScopeText>
                </Reason>
                <Reason delay={300} inView={inView}>
                  <ScopeTitle color="#6824c9">
                    <ScopeSubtitle style={{ display: "inline-block", overflowX: "100px" }}>
                      <Trans t={t} i18nKey="scopeOfWork.newEconomies">
                        NEW ECONOMIES
                      </Trans>
                    </ScopeSubtitle>
                    <OpenModalButton
                      onClick={() => {
                        setModalVisible3(true);
                        setModalVisible2(false);
                        setModalVisible(false);
                        setModalVisible4(false);
                        document.body.style.overflowY = "hidden";
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <circle cx="12" cy="12" r="10" />
                        <line x1="12" y1="8" x2="12" y2="16" />
                        <line x1="8" y1="12" x2="16" y2="12" />
                      </svg>
                    </OpenModalButton>
                  </ScopeTitle>
                  <ScopeText style={{ padding: "0 60px 0 0" }}>
                    <Trans t={t} i18nKey="scopeOfWork.newEconomiesText">
                      New markets based on representative digital assets formed from nondigital
                      ones, physical and virtual.
                    </Trans>
                  </ScopeText>
                </Reason>

                <Reason delay={300} inView={inView}>
                  <ScopeTitle color="#d66413">
                    <ScopeSubtitle style={{ display: "inline-flex" }}>
                      <Trans t={t} i18nKey="scopeOfWork.blockchainDisruptor">
                        BLOCKCHAIN DISRUPTOR
                      </Trans>
                    </ScopeSubtitle>
                    <OpenModalButton
                      onClick={() => {
                        setModalVisible4(true);
                        setModalVisible2(false);
                        setModalVisible3(false);
                        setModalVisible(false);
                        document.body.style.overflowY = "hidden";
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <circle cx="12" cy="12" r="10" stroke="#7e90b4" />
                        <line x1="12" y1="8" x2="12" y2="16" />
                        <line x1="8" y1="12" x2="16" y2="12" />
                      </svg>
                    </OpenModalButton>
                  </ScopeTitle>
                  <ScopeText style={{ padding: "0 60px 0 0" }}>
                    <Trans t={t} i18nKey="scopeOfWork.blockchainDisruptorText">
                      New communities or organizations that intrinsically rely on a blockchain
                      foundation.
                    </Trans>
                  </ScopeText>
                </Reason>
              </ScopeColumn>
            </ScopeWrapper>
          </Section>
        </Wrapper>
      )}
    </Reveal>
  );
};

const ScopeTitle = styled.div`
  border-left: 20px solid ${props => props.color};
  padding-left: 15px;
  height: 46px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
`;

const ScopeColumn = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

const OpenModalButton = styled.button`
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: relative;
  padding: 0px;
  border: 0;
  margin-left: 5px;
  background: transparent;
  svg {
    stroke: #7e90b4;
  }
  &:hover {
    svg {
      fill: #7e90b4;
      stroke: black;
    }
  }
`;

let SectionDescription = styled.h2`
  padding: 0px 0 10px;
  position: relative;
  top: -20px;

  @media (max-width: 768px) {
    font-size: 25px;
    padding: 0px 0px 0px 0px;
  }
`;

let ScopeModal = styled.div`
  background: #1a202b;
  z-index: 20;
  max-width: 700px;
  border: none;
  border-top-style: solid;
  border-top-width: 6px;
  text-align: left;
  padding: 6px 40px 0;
  animation: fadeIn 0.5s;
  position: relative;
  display: flex;
  flex-direction: column;
  .in {
    opacity: 1;
  }

  @media (min-width: 401px) and (max-width: 768px) {
    align-items: center;
  }

  h2 {
    font-weight: 500;
  }

  p {
    color: #999;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  h1 {
    color: #fff;
    font-size: 20px;
  }

  h2 {
    color: #7e90b4;
    font-size: 18px;
  }

  p {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
  }
`;

let ScopeWrapper = styled(InnerWrapper)`
  display: flex;
  flex-direction: row;
  align-content: center;

  @media (min-width: 1001px) {
    .hexagon {
      position: relative;
      right: 85px;
      top: 61px;
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: auto;
    .hexagon {
      order: -1;
    }
  }
`;

let ScopeSubtitle = styled.h4`
  font-size: 17px;
  padding: 1px 0 0 1px;
`;

let ScopeText = styled.p`
  font-size: 13px;
  color: #999;
`;

let Reason = styled.div`
  /* max-width: 520px; */
  ${"" /* margin-top: 48px; */}
  opacity: 0;
  flex: 1;

  ${({ inView, delay }) =>
    inView
      ? css`
          animation: circleAppear 300ms ${delay || 0}ms ease-in forwards;

          @keyframes circleAppear {
            to {
              opacity: 1;
            }
          }
        `
      : ""}

  @media (max-width: 768px) {
    margin-top: 20px;
  }

  h3 {
    font-size: 28px;
    font-weight: 300;
    margin: 0 0 12px;
  }

  p {
    font-weight: 300;
    line-height: 1.5;
    width: 100%;
    /* max-width: 412px; */
    margin: 0;
  }
`;

let ButtonBlog = styled.button`
  white-space: nowrap;
  margin-top: 32px;
  background: #1a202b;
  width: 50%;
  padding: 0 5px;
  appearance: none;
  background: none;
  border: 2px solid;
  border-radius: 7px;
  cursor: pointer;
  font: inherit;
  color: #7e90b4;
  border-color: #7e90b4;
  font-size: 1em;
  padding: 1em 2.5em;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.5s ease-in;
  font-weight: 600;
  margin-bottom: 16px;
  &:hover,
  &:focus {
    color: ${props => (props.light ? "#fff" : "#111")};
    background: ${props => (props.light ? "#111" : "#fff")};
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

let CloseButton = styled.button`
  appearance: none;
  background: none;
  cursor: pointer;
  font: inherit;
  color: #7e90b4;
  border-color: #7e90b4;
  text-decoration: none;
  position: absolute;
  top: 4px;
  right: 33px;
`;
