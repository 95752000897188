import styled from "@emotion/styled";
import React from "react";
import { useTranslation } from "react-i18next";
const CMS_URL = "https://cms.blockforce.in";

export const Authors = ({ post }) => {
  const authors = post["autores"];
  const date = new Date(post.data_publicacao);
  const { i18n } = useTranslation("blog");
  return (
    authors &&
    authors.length && (
      <AuthorContainer href={authors[0]["linkedin"]} target="_blank">
        <AuthorImage src={`${CMS_URL}${authors[0]["imagem"][0].formats.thumbnail.url}`} />
        <LabelContainer>
          <AuthorsName>{authors.map(({ nome }) => nome).join(", ")}</AuthorsName>
          <DateLabel>
            {date.toLocaleDateString(i18n.language, {
              year: "numeric",
              month: "long",
              day: "numeric"
            })}
          </DateLabel>
        </LabelContainer>
      </AuthorContainer>
    )
  );
};
const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  > p {
    margin: 0;
  }
`;
const DateLabel = styled.p`
  font-size: 14px;
  opacity: 0.8;
  margin-left: 8px;
`;
const AuthorContainer = styled.a`
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-wrap: wrap;
`;
const AuthorImage = styled.img`
  margin-right: 12px;
  width: 45px;
  height: 45px;
  border-radius: 22.5px;
  border: 2px solid transparent;
  position: relative;
  background-clip: padding-box;
  background-image: linear-gradient(white, white),
    linear-gradient(
      90deg,
      rgba(0, 205, 144, 1) 0%,
      rgba(103, 46, 204, 1) 100%,
      rgba(0, 212, 255, 1) 100%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
`;

const AuthorsName = styled.p`
  font-size: 16px;
  font-weight: 600;
`;
