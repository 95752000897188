import React, { useState } from "react";
import styled from "@emotion/styled/macro";
import { Section } from "../../../components";
import { SectionText, SectionDescription, SectionBelow, SectionBelowCustom } from "../ui-kit";
import { ReactComponent as Pc } from "../../../assets/product/digitalCurrency/pc.svg";
import { ReactComponent as Phone } from "../../../assets/product/digitalCurrency/phone.svg";
import { ReactComponent as PaymentProvider } from "../../../assets/product/digitalCurrency/paymentProvider.svg";
import { ReactComponent as Api } from "../../../assets/product/digitalCurrency/api.svg";
import { ReactComponent as TraditionalBanks } from "../../../assets/product/digitalCurrency/traditionalBanks.svg";
import { ReactComponent as PhoneCoin } from "../../../assets/product/digitalCurrency/phoneCoin.svg";
import { ReactComponent as Send } from "../../../assets/product/digitalCurrency/send.svg";
import { ReactComponent as Receive } from "../../../assets/product/digitalCurrency/receive.svg";
import { ReactComponent as SelfCustod } from "../../../assets/product/digitalCurrency/selfCustod.svg";
import { ReactComponent as Tracking } from "../../../assets/product/digitalCurrency/tracking.svg";
import { ReactComponent as STO } from "../../../assets/product/digitalCurrency/securityToken.svg";
import { ReactComponent as ICO } from "../../../assets/product/digitalCurrency/ico.svg";
import { ReactComponent as StableCoin } from "../../../assets/product/digitalCurrency/stablecoin.svg";
import { ReactComponent as Token } from "../../../assets/product/digitalCurrency/token.svg";

import bitcoin from "../../../assets/product/digitalCurrency/bitcoin.png";
import eth from "../../../assets/product/digitalCurrency/ethereum.png";
import stellar from "../../../assets/product/digitalCurrency/stellar.png";
import binance from "../../../assets/product/digitalCurrency/binance.png";
import ripple from "../../../assets/product/digitalCurrency/ripple.png";
import libra from "../../../assets/product/digitalCurrency/libra.png";
import celo from "../../../assets/product/digitalCurrency/celo.png";
import Role from "../components/Role";
import Modal from "../../../components/Modal/Modal";
import { useTranslation, Trans } from "react-i18next";

const DigitalCurrencyConnect = () => {
  const { t } = useTranslation("digitalCurrencyConnect");

  const roles = [
    {
      img: <Phone />,
      title: t("fintech"),
      desc:
        "The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz."
    },
    {
      img: <PaymentProvider />,
      title: t("paymentProviders"),
      desc:
        "The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz."
    },
    {
      img: <TraditionalBanks />,
      title: t("traditionalBanks"),
      desc:
        "The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz."
    },
    {
      img: <Api />,
      title: t("financialAPIs"),
      desc:
        "The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz."
    }
  ];

  const protocolsType = [
    {
      img: bitcoin,
      desc: "bitcoin"
    },
    {
      img: eth,
      desc: "ethereum"
    },
    {
      img: stellar,
      desc: "stellar"
    },
    {
      img: binance,
      desc: "binance"
    },
    {
      img: ripple,
      desc: "ripple"
    },
    {
      img: celo,
      desc: "celo"
    }
  ];

  const [modalStatus, setModalStatus] = useState(false);
  return (
    <DigitalCurrency>
      <Modal modalStatus={modalStatus} setModalStatus={setModalStatus} />
      <CurrencyHeader>
        <Section title={t("sectionTitle")} id="product">
          <SectionText>
            <SectionDescription>
              <Trans t={t} i18nKey="sectionDescription">
                Cryptum
              </Trans>
            </SectionDescription>
            <SectionBelow>
              <Trans t={t} i18nKey="sectionBelow">
                Digital transformation of your business in a simple and cost-efficient way.
              </Trans>
            </SectionBelow>
          </SectionText>
        </Section>
        <PcSvg>
          <Pc />
        </PcSvg>
      </CurrencyHeader>
      <Section title={t("ifYouAre")}>
        <Role roles={roles} />
      </Section>
      <Demo>
        <DemoTitle>
          <Trans t={t} i18nKey="requestDemo">
            Request a demo
          </Trans>
        </DemoTitle>
        <DemoDocumentation href={t("landingLink")} target="_blank">
          <Trans t={t} i18nKey="contactUs">
            Contact Us
          </Trans>
        </DemoDocumentation>
      </Demo>
      <Solution>
        <FirstSolution>
          <Section title={t("ourSolution")} id="solution">
            <SectionText>
              <SectionBelowCustom>
                <Trans t={t} i18nKey="ourSolutionBelow">
                  Develop your project in an easy and plug and play way.
                  <br />
                  End-to-end platform to management of digital currencies.
                </Trans>
              </SectionBelowCustom>
            </SectionText>
          </Section>
          <PhoneCoin />
        </FirstSolution>
        <OurSolution>
          <h3>Nossa solução permite: </h3>
          <WalletContainer>
            <Wallet>
              <WalletTitle>
                <Trans t={t} i18nKey="walletTitle">
                  Create a Digital Currency
                  <br /> Account / Wallet
                </Trans>
              </WalletTitle>
              <WalletContent>
                <Send />
                <WalletDesc>
                  <Trans t={t} i18nKey="send">
                    Send
                  </Trans>
                </WalletDesc>
              </WalletContent>
              <WalletContent>
                <Receive />
                <WalletDesc>
                  <Trans t={t} i18nKey="receive">
                    Receive
                  </Trans>
                </WalletDesc>
              </WalletContent>
              <WalletContent>
                <SelfCustod />
                <WalletDesc>
                  <Trans t={t} i18nKey="selfCustod">
                    Self-Custod
                  </Trans>
                </WalletDesc>
              </WalletContent>
              <WalletContent>
                <Tracking />
                <WalletDesc>
                  <Trans t={t} i18nKey="tracking">
                    Tracking
                  </Trans>
                </WalletDesc>
              </WalletContent>
            </Wallet>
            <Wallet>
              <WalletTitle>
                <Trans t={t} i18nKey="issueDigitalCurrency">
                  Issue a Digital Currency
                </Trans>
              </WalletTitle>
              <WalletContent>
                <STO />
                <WalletDesc>
                  <Trans t={t} i18nKey="securityToken">
                    Security Token (STO)
                  </Trans>
                </WalletDesc>
              </WalletContent>
              <WalletContent>
                <ICO />
                <WalletDesc>
                  <Trans t={t} i18nKey="initialCoinOffer">
                    Initial Coin Offer (ICO)
                  </Trans>
                </WalletDesc>
              </WalletContent>
              <WalletContent>
                <StableCoin />
                <WalletDesc>
                  <Trans t={t} i18nKey="stablecoin">
                    Stablecoin
                  </Trans>
                </WalletDesc>
              </WalletContent>
              <WalletContent>
                <Token />
                <WalletDesc>
                  <Trans t={t} i18nKey="tokenAsset">
                    Token/Asset
                  </Trans>
                </WalletDesc>
              </WalletContent>
            </Wallet>
          </WalletContainer>
        </OurSolution>
      </Solution>
      <InteractWith>
        <InteractTitle>
          <Trans t={t} i18nKey="startInteracting">
            Start interacting with Cryptum.
          </Trans>
        </InteractTitle>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://cryptum.gitbook.io/main"
          style={{ width: "100%" }}
        >
          <InteractDocumentation>
            <Trans t={t} i18nKey="documentation">
              Documentation
            </Trans>
          </InteractDocumentation>
        </a>
      </InteractWith>
      <Title>
        <Trans t={t} i18nKey="benefits">
          Benefits
        </Trans>
      </Title>
      <Benefits>
        <BenefitsSection>
          <BenefitsContainer width={50}>
            <TitleGreen>
              <Trans t={t} i18nKey="simplicity">
                Simplicity
              </Trans>
            </TitleGreen>
            <SectionBelowCustom fontSize={22}>
              <Trans t={t} i18nKey="standardizeTheFlow">
                We standardize the flow of transactions and create a single connection for all
                digital currencies. Thus, there is no need to deal with the specifics of digital
                currencies and each protocol.
              </Trans>
            </SectionBelowCustom>
          </BenefitsContainer>
          <BenefitsContainer width={33} border={true}>
            <TitleGreen>
              <Trans t={t} i18nKey="lowCost">
                Low cost of implementation
              </Trans>
            </TitleGreen>
            <SectionBelowCustom fontSize={22}>
              <Trans t={t} i18nKey="integrateIntoBusiness">
                To integrate technology into the business, it does not require developers who
                specialize in Blockchain.
              </Trans>
            </SectionBelowCustom>
          </BenefitsContainer>
        </BenefitsSection>
        <PcMiddle />
        <BenefitsSecondSection>
          <BenefitsSection noBorder={true}>
            <BenefitsContainer width={33} border={true}>
              <TitleGreen>
                <Trans t={t} i18nKey="acessibility">
                  Acessibility
                </Trans>
              </TitleGreen>
              <SectionBelowCustom fontSize={22}>
                <Trans t={t} i18nKey="meetMainDemands">
                  We meet the main demands for Blockchain in the areas that are most mature in the
                  market. The documentation is self-explanatory, facilitating integration into the
                  product and without borders.
                </Trans>
              </SectionBelowCustom>
            </BenefitsContainer>
          </BenefitsSection>
          <BenefitsSection noBorder={true}>
            <BenefitsContainer width={33} border={true}>
              <TitleGreen>
                <Trans t={t} i18nKey="adaptation">
                  Adaptation
                </Trans>
              </TitleGreen>
              <SectionBelowCustom fontSize={22}>
                <Trans t={t} i18nKey="ifYouDesire">
                  If you desire some protocol or add-on feature, we can include it.
                </Trans>
              </SectionBelowCustom>
            </BenefitsContainer>
          </BenefitsSection>
        </BenefitsSecondSection>
      </Benefits>
      <Title>
        <Trans t={t} i18nKey="integratedProtocols">
          Integrated Protocols
        </Trans>
      </Title>
      <Protocols>
        {protocolsType.map(i => {
          return (
            <Protocol
              style={{ background: i.under_development ? "#b0b4be" : "#ebebeb" }}
              key={i.desc}
            >
              <img src={i.img} alt={i.desc} />
            </Protocol>
          );
        })}
      </Protocols>
    </DigitalCurrency>
  );
};

const Demo = styled.div`
  height: 100%;
  width: 100%;
  margin: 40px 0;

  display: flex;
  flex-direction: column;
  justify-center: center;
  align-items: center;

  @media screen and (min-width: 800px) {
    margin: 40px 0;
  }
`;
const OurSolution = styled.div`
  color: #000;
  @media screen and (min-width: 800px) {
    margin-top: 80px;
  }
`;
const DemoTitle = styled.span`
  font-size: 30px;
  font-weight: 600;
  line-height: 56px;
  margin-bottom: 20px;
  color: #000;

  @media screen and (min-width: 800px) {
    font-size: 48px;
    margin-bottom: 58px;
  }
`;

const DemoDocumentation = styled.a`
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  width: 100%;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(17, 120, 178);
  background: -moz-linear-gradient(90deg, rgba(17, 120, 178, 1) 0%, rgba(0, 205, 153, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(17, 120, 178, 1) 0%, rgba(0, 205, 153, 1) 100%);
  background: linear-gradient(90deg, rgba(17, 120, 178, 1) 0%, rgba(0, 205, 153, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1178b2",endColorstr="#00cd99",GradientType=1);
  border: 0;
  height: 64px;

  @media screen and (min-width: 800px) {
    max-width: 464px;
    border-radius: 5px;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.87;
  }
`;

const Protocols = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  ${"" /* justify-content: space-between; */}
  box-sizing: border-box;
  padding-top: 50px;

  @media screen and (min-width: 800px) {
    flex-direction: row;
  }

  > img {
    margin-bottom: 34px;
  }
`;

const Protocol = styled.div`
  height: 133px;
  width: 100%;
  background-color: #ebebeb;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  @media screen and (min-width: 800px) {
    margin: 0 20px 40px 0;
    width: calc(338px - 40px);
  }
`;

const Benefits = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  margin: 34px 0;
  flex-direction: column;

  @media screen and (min-width: 800px) {
    flex-direction: row;
  }
`;

const BenefitsSection = styled.div`
  height: 100%;
  /* border-top: ${props => (props.noBorder ? `0` : `2px solid #00CD99`)}; */
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  svg {
    margin-top: 30px;
  }

  @media screen and (min-width: 800px) {
    /* flex-direction: row; */

    svg {
      width: 687px;
      height: 316px;
      margin-top: 110px;
    }
  }
`;

const BenefitsSecondSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 800px) {
    /* flex-direction: row; */
  }

  > div {
    margin-right: 40px;
  }
`;

const BenefitsContainer = styled.div`
  height: 100%;
  /* border-top: ${props => (!props.border ? `0` : `2px solid #00CD99`)}; */
  ${"" /* width: ${props => props.width ? `${props.width}%` : `100%`}; */}
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TitleGreen = styled.span`
  margin: 25px 0 28px 0;
  color: #00cd99;
  font-size: 35px;
  line-height: 46px;
  font-weight: 600;
`;

const Title = styled.span`
  font-size: 16px;
  line-height: 1.625px;
  letter-spacing: 1.5;
  text-transform: uppercase;
  color: #7e90b4;
  font-weight: 600;
  margin: 0;
  margin-top: 0;
  margin-bottom: 16px;
`;

const InteractWith = styled.div`
  width: 100%;
  height: 375px;
  margin: 32px 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  border-radius: 5px;

  background-color: #ebebeb;
  padding: 30px;

  @media screen and (min-width: 800px) {
    margin: 122px 0;
    padding: 0;
  }
`;

const InteractTitle = styled.span`
  font-size: 30px;
  font-weight: 600;
  line-height: 35px;
  margin-bottom: 44px;
  color: #000;

  @media screen and (min-width: 800px) {
    font-size: 48px;
    line-height: 56px;
  }
`;

const PcMiddle = styled(Pc)`
  min-width: unset;
  @media screen and (min-width: 800px) {
    min-width: 350px;
    margin: 36px;
  }
`;

const InteractDocumentation = styled.button`
  border-radius: 5px;
  color: #fff;
  background-color: #00cd99;
  border: 0;

  width: 100%;
  height: 64px;
  text-transform: uppercase;

  font-size: 18px;
  font-weight: 600;
  cursor: pointer;

  @media screen and (min-width: 800px) {
    max-width: 464px;
  }

  &:hover {
    opacity: 0.87;
  }
`;

const DigitalCurrency = styled.div`
  height: 100%;
  width: 100%;
`;

const CurrencyHeader = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  margin-bottom: 70px;

  @media screen and (min-width: 800px) {
    margin-bottom: 85px;
    flex-direction: row;
  }
`;

const PcSvg = styled.div`
  height: 100%;

  svg {
    height: 100%;
    width: 100%;

    @media screen and (min-width: 800px) {
      margin: 55px 0 0 85px;
      height: 316px;
      width: 600px;
      svg {
        min-width: 350px;
      }
    }
  }
`;

const Solution = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    width: 100%;
    max-width: 350px;
  }

  @media screen and (min-width: 800px) {
    flex-direction: row;
  }
`;

const FirstSolution = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 800px) {
    margin-right: 60px;

    svg {
      margin-top: 30px;
      width: 100%;
      max-width: 454px;
      height: 191px;
    }

    > #solution {
      margin: 0 !important;
    }
  }
`;

const Wallet = styled.div`
  height: 100%;
  width: 100%;
  max-width: 338px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  svg {
    max-width: 30px;
    max-height: 50px;
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 800px) {
    /* margin-top: 105px; */
  }
`;
const WalletContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 800px) {
    flex-direction: row;
  }
`;
const WalletTitle = styled.span`
  width: 100%;
  font-size: 22px;
  height: 75px;
  font-weight: 600;
  line-height: 29px;
  color: #000;
  margin-bottom: 20px;
`;

const WalletContent = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 12px;
  min-height: 37px;
`;

const WalletDesc = styled.span`
  width: 100%;
  font-size: 18px;
  line-height: 23px;
  margin-left: 22px;
  color: #000;
`;

export default DigitalCurrencyConnect;
