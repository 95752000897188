import React, { useState } from "react";
import SwiperCore, { EffectCube, Autoplay, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Button } from "./ui-kit";
import { history } from "../lib";
let edge = 18.6;
// let edge = 70;
SwiperCore.use([EffectCube, Autoplay, Scrollbar]);
export default props => {
  const { t } = useTranslation("cases");
  const [slides, setSlides] = useState(props.slides);
  const [swiper, setSwiper] = useState(null);
  const [transitioning, setTransitioning] = useState(false);
  const handleOpen = ({ path }) => {
    if (window.matchMedia("(max-width: 800px)").matches) {
      history.navigate(`/case/${path}`);
    }
    swiper.autoplay.stop();
    setSlides(
      slides.map(slide => {
        if (path == slide.path) {
          console.log(slide);
          return {
            ...slide,
            opened: true
          };
        }
        return slide;
      })
    );
  };

  const goToCase = path => () => {
    setTransitioning(true);

    setTimeout(() => {
      history.navigate(`/case/${path}`);
    }, 600);
  };
  return (
    <Container>
      <Cube
        onSwiper={swiper => setSwiper(swiper)}
        onSlideChange={() => setSlides(slides.map(slide => ({ ...slide, opened: false })))}
        effect="cube"
        scrollbar={{
          el: ".swiper-scrollbar",
          draggable: true
        }}
        autoplay={{
          delay: 3000
        }}
        loop={false}
        grabCursor={true}
        cubeEffect={{
          shadow: false,
          slideShadows: false
        }}
      >
        {slides.map(slide => (
          <SwiperSlide key={slide.path} slide={slide}>
            <FaceContent slide={slide} transitioning={transitioning}>
              <div>
                <h3 style={{ color: slide.mainColor }}>{t(slide.title)}</h3>
              </div>

              <div className="content">
                <p style={{ color: slide.mainColor }}>{t(slide.slogan).replace("<br/>", "")}</p>
              </div>
              {slide.quote && (
                <>
                  <div className="quote">
                    <p>{t(slide.quote)}</p>
                    <span>{t(slide.quoter)}</span>
                  </div>
                </>
              )}
              <div className="button-container">
                <Button light as="a" onClick={goToCase(slide.path)}>
                  {" "}
                  {t("common.see-project")}
                </Button>
              </div>
            </FaceContent>
            <CubeFace slide={slide} onClick={() => handleOpen(slide)}>
              <SlideTitle>{t(slide.title)}</SlideTitle>
            </CubeFace>
          </SwiperSlide>
        ))}
        <div className="swiper-scrollbar"></div>
      </Cube>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Cube = styled(Swiper)`
  width: 600px;
  height: 600px;

  @media screen and (max-width: 800px) {
    width: 250px;
    height: 250px;
    .swiper-slide-active {
      h1 {
        visibility: visible;
        font-size: 50px;
      }
    }
  }
  .swiper-slide-active {
    h1 {
      visibility: visible;
    }
  }
  .swiper-scrollbar {
    width: calc(100% + 400px);
    margin-right: -200px;
    margin-left: -200px;
    @media screen and (max-width: 800px) {
      width: calc(100% + 40px);
      margin-right: -20px;
      margin-left: -20px;
    }
    height: 2px;
    background: rgb(51, 51, 51);
    bottom: -100px;
    z-index: 0;
    .swiper-scrollbar-drag {
      background: linear-gradient(
          90deg,
          rgb(0, 205, 144) 0%,
          rgb(103, 46, 204) 100%,
          rgb(0, 212, 255) 100%
        )
        0% 0% / 100% 100% no-repeat;
      height: 25px;
      margin-top: -12.5px;
      border-radius: 5px;
    }
  }
`;
const FaceContent = styled.div`
  position: absolute;
  z-index: ${({ slide }) => (slide.opened ? `3` : `-1`)};
  transition: all ease ${({ slide }) => (!slide.opened ? "0s" : "0.5s")};
  transition-delay: ${({ slide }) => (slide.opened ? "0s" : "0.5s")};
  width: 100%;
  background: white;
  color: black;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  opacity: ${({ slide }) => (slide.opened ? `1` : `0`)};
  transform: ${({ slide, transitioning }) =>
    slide.opened
      ? transitioning
        ? `scale(7)`
        : `rotateY(0deg) rotateX(0deg) translate3d(0px, 0px, 0px);`
      : `rotateY(-90deg) rotateX(0deg) translate3d(-300px, 0px, 300px);`};

  > * {
    font-size: 18px;
    opacity: ${({ transitioning }) => (transitioning ? "0" : "1")};
  }

  .button-container {
    padding: 0 24px !important;
    display: flex;
    justify-content: flex-end;
  }
  .content {
    padding: 0px 24px;
    font-weight: bold;
  }
  .quote {
    padding: 0px 24px;
    span {
      font-weight: bold;
    }
  }
  > div {
    padding: 24px;
  }
  h3 {
    font-size: 5em;
    position: absolute;
    z-index: 5;
    margin-top: -70px;
    margin-left: -120px;
  }
  p {
    overflow-y: hidden;
  }
`;
const CubeFace = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${({ slide }) => `url(${slide.img})`};
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-delay: ${({ slide }) => (slide.opened ? "0s" : "0.5s")};
  transition: all ease ${({ slide }) => (!slide.opened ? "0s" : "0.5s")};
  ${({ slide }) =>
    slide.opened && `opacity: 0;     transform: translate3d(300px, 0px, -300px) rotateY(90deg);`}
`;
const SlideTitle = styled.h1`
  font-size: 200px;
  margin: 0 -100%;
  visibility: hidden;
`;
// const Slide = styled(SwiperSlide)`
// color: #000;
//   background: white;
// `
// let CubeWrapper = styled.div`
//   position: relative;
//   height: 65vmin;
//   perspective: 16vmin;
//   ${"" /* perspective: 85vmin; */}
//   font-size: 0.4vmin;

//   &::before {
//     content: "";
//     position: absolute;
//     top: 50%;
//     padding: 20vmin;
//     display: block;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     border-radius: 20%;
//   }
// `;

// let Cube = styled.div`
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform-style: preserve-3d;
//   transform: rotateY(${({ rotation }) => rotation}turn);
// `;

// export let CubeFace = styled.div`
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   margin: -${0.55 * edge}em;
//   width: ${edge}em;
//   height: ${edge}em;
//   background: #222;
//   backface-visibility: hidden;

//   ${({ length }) =>
//     times(length).map(
//       i => css`
//         &:nth-of-type(${i + 1}) {
//           transform: rotateY(${i * (360 / length)}deg)
//             translateZ(${edge / (2 * Math.tan(Math.PI / length))}em);
//         }
//       `
//     )}
// `;
