import React, { useEffect, useState } from "react";
import Flickity from "react-flickity-component";
import styled from "@emotion/styled/macro";
import { Carousel } from "../../components/CasesCarousel/carousel-components";
import Case from "./Case";

const NormalCasesCarousel = props => {
  const [cases, setCases] = useState(false);

  const options = {
    prevNextButtons: true,
    pageDots: false,
    setGallerySize: false,
    wrapAround: true,
    draggable: true,
    autoPlay: true,
    arrowShape: {
      x0: 10,
      x1: 45,
      y1: 50,
      x2: 50,
      y2: 50,
      x3: 15
    }
  };

  const mobileOptions = {
    freeScroll: true,
    contain: true,
    prevNextButtons: false,
    pageDots: false,
    draggable: true,
    autoPlay: true
  };
  useEffect(() => {
    if (props.cases) {
      setCases(props.cases);
    }
  }, []);

  return (
    cases && (
      <PressCases blackButton={props.blackButton}>
        <DesktopView>
          <Carousel>
            <Flickity options={options}>
              {cases.map((i, n) => {
                return <Case key={n} {...i} />;
              })}
            </Flickity>
          </Carousel>
        </DesktopView>
        <MobileView>
          <Flickity options={mobileOptions}>
            {cases.map((i, n) => {
              return <Case key={n} {...i} />;
            })}
          </Flickity>
        </MobileView>
      </PressCases>
    )
  );
};

let PressCases = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 3.75rem;
  position: relative;

  @media screen and (min-width: 800px) {
    width: 100%;
    height: 21rem;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 4.6875rem 0 3.75rem 0;

    .flickity-button {
      height: 22rem !important;
      background: white !important;
      background: ${props => (!props.blackButton ? `white !important;` : `black !important;`)};
      width: 4% !important;
    }

    .flickity-viewport {
      height: 46vmin !important;
    }
  }
`;

let DesktopView = styled.div`
  height: 100%;
  width: 100%;
  display: none;

  @media screen and (min-width: 800px) {
    display: block;
  }
`;

let MobileView = styled.div`
  height: 100%;
  width: 100%;
  display: block;
  @media screen and (min-width: 800px) {
    display: none;
  }
`;

export default NormalCasesCarousel;
