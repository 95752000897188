/**
 * scales a svg path element
 *
 * @param {SVGPathElement} path
 * @param {number} scale
 */
export function getPathPosition(path, scale) {
  let bbox = path.getBBox();
  let x = (1 - scale) * (bbox.width / 2 + bbox.x);
  let y = (1 - scale) * (bbox.height / 2 + bbox.y);

  return { x, y };
}

/**
 * returns an array with length n
 *
 * @param {number} n
 * @returns {Array<number>}
 */
export function times(n) {
  return Array.from(Array(n), (_, i) => i);
}

/**
 *  chunks an array
 *
 * @param {Array<any>} arr
 * @param {number} size
 * @returns {Array<Array<any>>}
 */
export function chunks(arr, size) {
  let output = [];

  for (let i = 0; i < arr.length; i += size) {
    output.push(arr.slice(i, i + size));
  }

  return output;
}

/**
 * compare if two objects are shallow equal
 *
 * @param {object} a
 * @param {object} b
 */
export function shallowEqual(a, b) {
  for (let i in a) if (a[i] !== b[i]) return false;
  for (let i in b) if (!(i in a)) return false;
  return true;
}

/**
 * stops an event propagation
 *
 * @param {Event} e
 */
export let stop = e => e.stopPropagation();
