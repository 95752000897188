import React, { createContext, useReducer, useContext } from "react";

let ctx = createContext({});

export function Provider({ children, initialState, reducer }) {
  let [state, dispatch] = useReducer(reducer, initialState);

  return <ctx.Provider value={{ state, dispatch }}>{children}</ctx.Provider>;
}

export function useStore(mapState, mapDispatch) {
  let { state, dispatch } = useContext(ctx);

  return {
    ...(mapState ? mapState(state) : state),
    ...(mapDispatch ? mapDispatch(dispatch) : {})
  };
}
