import styled from "@emotion/styled/macro";

export const LogoContainer = styled.div`
  width: 100%;
  padding: 1.5rem 32px;
  display: flex;
  @media screen and (max-height: 800px) {
    padding: 16px;
  }
`;

export const HeaderFirstColumn = styled.div`
  height: 100%;
  width: 100%;

  @media screen and (min-width: 800px) {
    width: 40%;
    display: flex;
    justify-content: flex-end;

    a {
      ${"" /* padding-left: 16px; */}
    }
  }
`;

export const LogoCapsule = styled.div`
  width: 100%;
  max-width: 510px;
  padding-left: 32px;
  @media screen and (max-width: 800px) {
    padding-left: 0px;
  }
`;

export const HeaderSecondColumn = styled.div`
  width: 60%;
  display: flex;
`;

export const CaseBody = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;

  @media screen and (min-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const CaseHeader = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  margin-bottom: 2rem;

  .mainImg {
    height: 100%;
    max-height: 15.25rem;

    @media screen and (min-width: 800px) {
      max-height: 100%;
      height: 33.125rem;
      width: 60%;
      min-width: 60%;
      ${"" /* max-width: 1196px; */}
    }
  }

  @media screen and (min-width: 800px) {
    flex-direction: row-reverse;
  }
`;

export const CaseMainInfo = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.color};

  .logoTitle {
    width: 100%;
    max-width: 10.8125rem;

    @media screen and (min-width: 800px) {
      width: 38%;
      max-width: 12.0625rem;
    }
  }

  @media screen and (min-width: 800px) {
    height: 33.125rem;
    width: 100%;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

export const CaseMainInfoContainer = styled.div`
  width: 100%;
  height: 100%;

  @media screen and (min-width: 800px) {
    display: flex;
    justify-content: flex-end;
  }
`;

export const Book = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  * {
    box-sizing: border-box;
  }

  .inner-book {
    width: 45vh;
  }

  .book {
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateZ(0);
    cursor: pointer;
  }

  .inner-book {
    display: flex;
    align-items: center;
    transform-style: preserve-3d;
    perspective: 2000px;
  }

  .inner-book {
    display: flex;
    align-items: center;
    transform-style: preserve-3d;
    perspective: 2000px;
  }

  .inner-book:before {
    content: "";
    width: 100%;
    position: absolute;
    height: 0;
    bottom: 14px;
    transform: rotateY(-20deg) translateZ(-20px);
    z-index: 0;
    box-shadow: 10px 12px 20px 13px rgba(0, 0, 0, 0.3);
  }

  .inner-book .img {
    transform: rotateY(-20deg);
    width: 100%;
    z-index: 5;
    clear: both;
    height: 100%;
    background: #ddd;

    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }

  .inner-book img {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
  }

  .inner-book .img:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-left: 4px solid rgba(0, 0, 0, 0.3);
    z-index: 2;
  }

  .page {
    width: calc(100% - 20px);
    height: calc(100% - 2px);
    position: absolute;
    box-shadow: inset 0px -1px 2px rgba(50, 50, 50, 0.2),
      inset -1px 0px 1px rgba(150, 150, 150, 0.1);
    border-radius: 0px 3px 3px 0px;
    transform: rotateY(-20deg) translateZ(-5px);
    right: -3px;
    z-index: 4;
  }

  .page-1 {
    background: #d1d1d1;
  }

  .page-2 {
    height: calc(100% - 4px);
    right: -6px;
    z-index: 3;
    background: #d1d1d1;
  }

  .page-3 {
    height: calc(100% - 6px);
    right: -9px;
    z-index: 2;
    background: #d1d1d1;
  }

  .page-4 {
    height: calc(100% - 8px);
    right: -12px;
    z-index: 1;
    background: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px solid #d1d1d1;

    span {
      font-size: 0.9rem;
      font-weight: 500;
      color: #000;
    }

    span:last-of-type {
      font-size: 1rem;
      opacity: 0.8;
    }
  }

  .page-5 {
    height: calc(100% - 10px);
    right: -15px;
    z-index: 0;
    background: #d1d1d1;
  }

  .img.final-page {
    position: absolute;
    ${"" /* z-index: -1; */}
    right: -17px;
    ${"" /* transform: rotateY(-19deg) translateZ(-10px) scale(0.984); */}
  }

  .front,
  .page-1,
  .page-2,
  .page-3,
  .page-4,
  .page-5,
  .final-page {
    transform-origin: left center;
    transition: transform 0.5s ease-in-out, box-shadow 0.35s ease-in-out;
  }

  .book:hover .front {
    transform: rotateY(-160deg) scale(1.1);
    box-shadow: 0 1em 3em 0 rgba(0, 0, 0, 0.2);
  }

  .book:hover .page-1 {
    transform: rotateY(-150deg) scale(1.1);
    box-shadow: 0 1em 3em 0 rgba(0, 0, 0, 0.2);
  }

  .book:hover .page-2 {
    transform: rotateY(-30deg) scale(1.1);
    box-shadow: 0 1em 3em 0 rgba(0, 0, 0, 0.2);
  }

  .book:hover .page-3 {
    transform: rotateY(-140deg) scale(1.1);
    box-shadow: 0 1em 3em 0 rgba(0, 0, 0, 0.2);
  }

  .book:hover .page-4 {
    transform: rotateY(-40deg) scale(1.1);
    box-shadow: 0 1em 3em 0 rgba(0, 0, 0, 0.2);
  }

  .book:hover .page-5 {
    transform: rotateY(-130deg) scale(1.1);
    box-shadow: 0 1em 3em 0 rgba(0, 0, 0, 0.2);
  }

  .book:hover .final-page {
    transform: rotateY(-20deg) scale(1.1);
  }

  ${"" /* .book {
    transform-style: preserve-3d;
    position: relative;
    height: 350px;
    cursor: pointer;
    backface-visibility: visible;
  }
  
  .front, .back, .page1, .page2 {
    transform-style: preserve-3d;
    position: absolute;
    width: 200px;
    height: 100%;
    top: 0; left: 0;
    transform-origin: left center;
    transition: transform .5s ease-in-out, box-shadow .35s ease-in-out;
  }

  .front {
    background-position: center !important;
    background-repeat: no-repeat !important; 
    background-size: cover !important;
  }

  .back {
    background: #2a79d2;
  }

  .front, .page1, .page3, .page5 {
    border-bottom-right-radius: .5em;
    border-top-right-radius: .5em;
  }

  .back, .page2 {
    border-bottom-right-radius: .5em;
    border-top-right-radius: .5em;
  }

  .page1 { 
    background: #efefef;
  }

  .page2 {
    background: #efefef;
  }


  .book:hover .front {
    transform: rotateY(-160deg) scale(1.1);
    box-shadow: 0 1em 3em 0 rgba(0, 0, 0, .2);
  }

  .book:hover .page1 {
    transform: rotateY(-150deg) scale(1.1);
    box-shadow: 0 1em 3em 0 rgba(0, 0, 0, .2);
  }

  .book:hover .page2 {
    transform: rotateY(-30deg) scale(1.1);
    box-shadow: 0 1em 3em 0 rgba(0, 0, 0, .2);
  }

  .book:hover .page3 {
    transform: rotateY(-140deg) scale(1.1);
    box-shadow: 0 1em 3em 0 rgba(0, 0, 0, .2);
  }

  .book:hover .page4 {
    transform: rotateY(-40deg) scale(1.1);
    box-shadow: 0 1em 3em 0 rgba(0, 0, 0, .2);
  }

  .book:hover .page5 {
    transform: rotateY(-130deg) scale(1.1);
    box-shadow: 0 1em 3em 0 rgba(0, 0, 0, .2);
  }

  .book:hover .page6 {
    transform: rotateY(-50deg) scale(1.1);
    box-shadow: 0 1em 3em 0 rgba(0, 0, 0, .2);
  }

  .book:hover .back {
    transform: rotateY(-20deg) scale(1.1);
  } */}
`;

export const InfoCase = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
  max-width: 510px;
`;

export const SpecificTitles = styled.div`
  height: 100%;
  width: 100%;
  max-height: 60px;
  display: flex;

  img {
    height: 30px;
    width: 80px;
    bottom: 0;
    opacity: 1;
    margin-right: 20px;

    ${"" /* opacity: 0.9; */}

    &:last-of-type {
      height: 35px;
      width: 30px;
      position: relative;
      bottom: 5px;
      ${"" /* opacity: 0.3; */}
    }

    &:first-of-type {
      width: 100%;
      max-width: 127px;
      height: 80px;

      position: relative;
      bottom: 28px;

      ${"" /* opacity: 0.6; */}
    }
  }
`;

export const InfoCaseContainer = styled.div`
  width: 100%;
  height: fit-content;
`;

export const ArrowContainer = styled.div`
  margin: 30px 0 20px 0;
  position: relative;
  z-index: 10;

  cursor: pointer;

  @media screen and (min-width: 800px) {
    margin: ${props => (props.special ? "0 0 20px" : "0px 0 50px")};
  }
`;

export const CaseSloganTitle = styled.div`
  max-width: 18.75rem;
  margin: 1rem 0;

  display: flex;
  flex-direction: column;

  span {
    font-size: 1.4375rem;
    line-height: 1.625rem;
    font-weight: 600;
    color: #fff;
    width: 100%;
    margin-top: 1rem;

    @media screen and (min-width: 800px) {
      font-size: 2rem;
      line-height: 3.5rem;
    }
  }

  @media screen and (min-width: 800px) {
    max-width: 30.5rem;
    margin: 2rem 0 0 0;
  }
`;

export const CaseSlogan = styled.div`
  max-width: 18.75rem;
  margin: 1rem 0;

  span {
    font-size: 1.4375rem;
    line-height: 1.625rem;
    font-weight: 600;
    color: #fff;
    width: 100%;

    @media screen and (min-width: 800px) {
      font-size: 2.5rem;
      line-height: 2.8rem;
    }
  }

  @media screen and (min-width: 800px) {
    max-width: 30.5rem;
    margin: ${props => (props.special ? "3rem 0 2rem 0" : "3rem 0")};
  }
`;

export const ButtonSlogan = styled.a`
  width: 15.5rem;
  height: 3.375rem;
  background-color: transparent;
  color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  border: 2px solid #fff;

  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 600;
  text-transform: uppercase;

  margin-bottom: 2.375rem;

  cursor: pointer;

  @media screen and (min-width: 800px) {
    height: 3.5rem;
    width: 20.8125rem;
    margin-bottom: 0;
  }
`;

export const CaseContent = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 32px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 800px) {
    padding: 0;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 1rem 0;

  @media screen and (min-width: 800px) {
    display: flex;
    flex-direction: ${props => (props.direction ? props.direction : "row")};
  }
`;

export const ContentTitle = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 1.25rem;

  span {
    font-size: 1rem;
    font-weight: 700;
    color: #7e91b5;
    text-transform: uppercase;
  }

  @media screen and (min-width: 800px) {
    width: 40%;
    padding-right: 40px;
    text-align: right;
  }
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    line-height: 1.375rem;
    font-weight: 300;
    color: #000000;
  }

  ul {
    list-style-type: square;
    color: #000;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 1rem;

    li {
      margin: 1rem 0;
    }

    @media screen and (min-width: 800px) {
      li:first-of-type {
        margin: 0 0 1rem 0;
      }
    }
  }

  @media screen and (min-width: 800px) {
    justify-content: flex-start;
    flex-direction: row;
    width: 60%;
    padding-right: 228px;
  }
`;

export const DefaultBox = styled.div`
  height: 100%;
  width: 100%;

  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;

  padding: 0.75rem;
  margin-bottom: 1.25rem;

  background-color: ${props => props.color};
  color: #fff;

  @media screen and (min-width: 800px) {
    width: auto;
    margin-right: 20px;
  }
`;

export const ContentProtocol = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 800px) {
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 40%;
  }
`;
export const ProtocolContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  background-color: #ebebeb;
  border-radius: 57px;
  margin-right: 1.25rem;

  img {
    padding: 10px;
    width: auto;
    height: auto;
  }
`;

export const ContentImageMid = styled.div`
  width: 100%;
  height: 100%;
  min-height: 20.25rem;
  display: flex;
  position: relative;
  margin: 60px 0 0;

  @media screen and (min-width: 800px) {
    margin: 60px 0 60px;
  }

  img {
    width: 100%;
    height: 100%;
    max-width: 260px;
    max-height: 550px;
    position: relative;

    @media screen and (min-width: 800px) {
      max-width: 260px;
      max-height: 550px;
    }
  }
`;

export const ImageAlone = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;

  img {
    max-height: 100%;
    max-width: 100%;

    @media screen and (min-width: 800px) {
      max-width: ${props => (props.path === "ghgprotocol" ? "350px" : "900px")};
      max-height: 900px;
      cursor: pointer;
    }
  }
`;

export const ContentImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 5;
  padding: 0 48px;
  margin: 0 auto;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    margin: ${props => (props.special ? "0 0 15px 0" : "0")};
  }

  @media screen and (min-width: 800px) {
    img {
      margin: ${props => (props.special ? "0 15px 15px 0" : "0")};
    }
  }
`;

export const RowImage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 800px) {
    flex-direction: row;
  }
`;

export const RowColoured = styled.div`
  width: 100%;
  height: 100%;
  min-height: 14.4875rem;
  background-color: ${props => props.color};
  position: absolute;
  z-index: 2;

  border-top: 60px solid white;
  border-bottom: 60px solid white;

  ${"" /* @media screen and (min-width: 800px) {
    height: 100%;
  } */}
`;

export const Label = styled.div`
  width: 100%;
  height: 100%;
`;

export const LabelContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  img {
    width: auto;
    max-width: 100px;
    max-height: 100px;
    height: auto;

    margin: 10px 10px;
  }

  @media screen and (min-width: 800px) {
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    padding-left: 40%;
  }
`;
export const LineGradient = styled.div`
  height: 2px;
  width: 100%;
  background: rgb(102, 51, 204);
  background: linear-gradient(90deg, rgba(102, 51, 204, 1) 0%, rgba(0, 204, 153, 1) 100%);
  background: -moz-linear-gradient(90deg, rgba(102, 51, 204, 1) 0%, rgba(0, 204, 153, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(102, 51, 204, 1) 0%, rgba(0, 204, 153, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6633cc",endColorstr="#00cc99",GradientType=1);

  box-shadow: inset 100px 0px 100px -50px white, inset -100px 0px 100px -50px white;
`;

export const Review = styled.div`
  width: 100%;
  height: 100%;

  margin: 1.5rem 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  span {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.325rem;
    color: #000;

    @media screen and (min-width: 800px) {
      font-size: 1.5rem;
      line-height: 37px;
      font-weight: 500;
    }
  }

  span:first-of-type {
    margin-bottom: 20px;
  }

  span:last-of-type {
    font-size: 22px;
    font-weight: 600;
    line-height: 29px;
    color: #111111d9;

    @media screen and (min-width: 800px) {
      white-space: nowrap;
    }
  }

  @media screen and (min-width: 800px) {
    margin: 4rem 0;
    padding: 0 157px;

    &:last-of-type {
      margin: 1rem 0 4rem 0;
    }
  }
`;
