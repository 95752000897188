export default function createScrollBehaviour(history) {
  window.history.scrollRestoration = "manual";

  let currentLocation = history.location;
  let scrollPositionsHistory = {};

  return {
    store({ location, action }) {
      scrollPositionsHistory[currentLocation.key] = {
        top: window.pageYOffset
      };

      if (action === "PUSH") {
        delete scrollPositionsHistory[location.key];
      }

      currentLocation = location;
    },
    restore(location, behavior = "auto", delay = 0) {
      let { top = 0 } = scrollPositionsHistory[location.key] || {};
      let targetHash = location.hash.substr(1);

      if (targetHash) {
        let target = document.getElementById(targetHash);

        if (target) {
          top = window.pageYOffset + target.getBoundingClientRect().top - 64;
        }
      }

      setTimeout(() => {
        window.scrollTo({ top, behavior });
      }, delay);
    }
  };
}
