import React from "react";
import styled from "@emotion/styled/macro";
const LanguageToggle = ({ onClickLanguage, lang, light }) => {
  return (
    <LanguageToggleContainer>
      <LanguageButton
        light={light}
        active={lang && lang.includes("pt")}
        onClick={() => onClickLanguage("pt")}
      >
        PT
      </LanguageButton>
      <LanguageButton
        light={light}
        active={lang && lang.includes("en")}
        onClick={() => onClickLanguage("en")}
      >
        EN
      </LanguageButton>
    </LanguageToggleContainer>
  );
};

const LanguageToggleContainer = styled.div`
  display: flex;
`;

const LanguageButton = styled.a`
  margin-right: 24px;
  color: #707070;
  cursor: pointer;
  @media screen and (max-width: 800px) {
    margin-right: 16px;
  }
  ${props =>
    props.active &&
    `
    color: ${props.light ? "#000000" : "#FFFFFF"};
    font-weight: 600;
  
  `}
`;
export default LanguageToggle;
