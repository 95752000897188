import React from "react";
import css from "@emotion/css/macro";
import styled from "@emotion/styled/macro";
import { Wrapper, InnerWrapper, Reveal } from "../../../components";

import bca from "../../../assets/partners/blockchainAcademy.png";
import coppead from "../../../assets/partners/coppeadUFRJ.png";
import alliance from "../../../assets/partners/alliance.png";
import hyperledger from "../../../assets/partners/hyperledger.png";
import linux from "../../../assets/partners/linux.png";
import amani from "../../../assets/partners/amani.png";
import fgv1 from "../../../assets/partners/fgv1.png";
import fgv2 from "../../../assets/partners/fgv2.png";
import dinamo from "../../../assets/partners/dinamo.png";
import abs from "../../../assets/partners/ABStartups.png";
import abcb from "../../../assets/partners/ABCB.png";
import laudesFoundation from "../../../assets/partners/LF_logo_big.png";
import cea from "../../../assets/partners/CEA.png";
import { useTranslation, Trans } from "react-i18next";

export default () => {
  const { t } = useTranslation("home");
  return (
    <Reveal>
      {inView => (
        <Wrapper id="partners">
          <Title>
            <Trans t={t} i18nKey="partners">
              Partners
            </Trans>
          </Title>
          <PartnerWrapper>
            <PartnerList>
              {partners.map((partner, i) => (
                <Partner
                  key={partner.alt}
                  delay={i * 100}
                  inView={inView}
                  dimensions={partner.dimensions}
                >
                  <img
                    style={{ opacity: partner.opacity || 1 }}
                    alt={partner.alt}
                    src={partner.src}
                  />
                </Partner>
              ))}
            </PartnerList>
          </PartnerWrapper>
        </Wrapper>
      )}
    </Reveal>
  );
};
let partners = [
  {
    src: bca,
    alt: "Blockchain Academy",
    dimensions: {
      mobile: {
        width: "84px",
        height: "83px"
      },
      desktop: {
        width: "168px",
        height: "165px"
      }
    }
  },
  {
    src: coppead,
    alt: "COPPEAD - UFRJ",
    dimensions: {
      mobile: {
        width: "98px",
        height: "31px"
      },
      desktop: {
        width: "174px",
        height: "53px"
      }
    }
  },
  {
    src: hyperledger,
    alt: "Hyperledger",
    dimensions: {
      mobile: {
        width: "130px",
        height: "39px"
      },
      desktop: {
        width: "286px",
        height: "85px"
      }
    }
  },
  {
    src: linux,
    alt: "Linux Foundation",
    dimensions: {
      mobile: {
        width: "98px",
        height: "40px"
      },
      desktop: {
        width: "172px",
        height: "70px"
      }
    }
  },
  {
    src: amani,
    alt: "Amani Institute",
    dimensions: {
      mobile: {
        width: "108px",
        height: "34px"
      },
      desktop: {
        width: "235px",
        height: "74px"
      }
    }
  },
  {
    src: fgv2,
    alt: "FGV - centro de estudos em sustentabilidade",
    width: "100%",
    dimensions: {
      mobile: {
        width: "122px",
        height: "23px"
      },
      desktop: {
        width: "294px",
        height: "57px"
      }
    }
  },
  {
    src: fgv1,
    alt: "FGV - centro de estudos de microfinanças e inclusão financeira",
    width: "100%",
    dimensions: {
      mobile: {
        width: "115px",
        height: "29px"
      },
      desktop: {
        width: "292px",
        height: "73px"
      }
    }
  },
  {
    src: dinamo,
    alt: "Dinamo - Inovações de Impacto",
    width: "100%",
    dimensions: {
      mobile: {
        width: "104px",
        height: "35px"
      },
      desktop: {
        width: "197px",
        height: "67px"
      }
    }
  },
  {
    src: abs,
    alt: "ABStartups - Associação Brasileira de Startups",
    width: "100%",
    dimensions: {
      mobile: {
        width: "106px",
        height: "60px"
      },
      desktop: {
        width: "210px",
        height: "57px"
      }
    }
  },
  {
    src: abcb,
    alt: "ABCB - Associação Brasileira de Criptomoedas e Blockchain",
    width: "100%",
    dimensions: {
      mobile: {
        width: "84px",
        height: "83px"
      },
      desktop: {
        width: "198px",
        height: "112px"
      }
    }
  },
  {
    src: alliance,
    alt: "Alliance for prosperity",
    dimensions: {
      mobile: {
        width: "106px",
        height: "32.5px"
      },
      desktop: {
        width: "212.5px",
        height: "65px"
      }
    }
  },
  {
    src: laudesFoundation,
    alt: "Laudes Foundation",
    opacity: 0.6,
    dimensions: {
      mobile: {
        width: "84px",
        height: "83px"
      },
      desktop: {
        width: "198px",
        height: "112px"
      }
    }
  },
  {
    src: cea,
    alt: "CEA",
    opacity: 0.48,
    dimensions: {
      mobile: {
        width: "104px",
        height: "86px"
      },
      desktop: {
        width: "197px",
        height: "114px"
      }
    }
  }
];

let PartnerList = styled(InnerWrapper)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 1398px;

  @media screen and (min-width: 800px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 64px;
  }
`;

const PartnerWrapper = styled.div``;

let Title = styled.div`
  font-size: 1.375rem;
  color: #7e91b5;
  line-height: 1.625rem;
  margin-bottom: 2.9375rem;
`;

let Partner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 6.0625rem 0;

  opacity: ${props => props.opacity} @media screen and (min-width: 800px) {
    margin-right: 30px;
  }

  > img {
    max-height: ${props => props.dimensions.mobile.height && props.dimensions.mobile.height};
    max-width: ${props => props.dimensions.mobile.width && props.dimensions.mobile.width};

    @media screen and (min-width: 800px) {
      max-height: ${props => props.dimensions.desktop.height && props.dimensions.desktop.height};
      max-width: ${props => props.dimensions.desktop.width && props.dimensions.desktop.width};
    }
  }

  @media screen and (min-width: 800px) {
    width: auto;
  }

  ${({ inView, delay }) =>
    inView
      ? css`
          animation: serviceAppear 300ms ${delay || 0}ms ease-in forwards;

          @keyframes serviceAppear {
            to {
              opacity: 1;
            }
          }
        `
      : ""}
`;
