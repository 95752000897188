import React, { useEffect, useState } from "react";
import { history } from "../../lib";
import cases from "../../cases";
import ArrowBack from "../../assets/cases/arrowBack.png";

import CarouselCase from "./CarouselCase";
import Contact from "../Home/sections/Contact";
import { Wrapper, Logo, Footer, Nav, Reveal } from "../../components";
import * as Styled from "./case-components";
import { useTranslation } from "react-i18next";

export default ({ scroll, location }) => {
  const [currentCase, setCurrentCase] = useState(false);
  const [slides, setSlides] = useState(false);
  const { t, i18n } = useTranslation("cases");
  useEffect(() => {
    scroll.restore(location, "auto");
  }, []);

  useEffect(() => {
    if (cases) {
      setCurrentCase(cases[location.pathname.replace("/case/", "")]);
      setSlides(Object.keys(cases).map(key => cases[key]));
    }
  }, [cases]);

  const grey = "#565a63";

  return (
    currentCase &&
    slides && (
      <Reveal delay={200}>
        <Styled.CaseBody light={true}>
          <Styled.LogoContainer>
            <Styled.HeaderFirstColumn>
              <Styled.LogoCapsule>
                <Logo light={true} />
              </Styled.LogoCapsule>
            </Styled.HeaderFirstColumn>
            <Styled.HeaderSecondColumn>
              <Nav light={true} />
            </Styled.HeaderSecondColumn>
          </Styled.LogoContainer>
          <Styled.CaseHeader>
            <img
              loading="eager"
              importance="high"
              src={currentCase.mainImg}
              className="mainImg"
              alt="mainImg"
            />
            <Styled.CaseMainInfo color={currentCase.mainColor}>
              <Styled.CaseMainInfoContainer>
                <Styled.InfoCase sizeInTitle={currentCase.sizeInTitle}>
                  <Styled.InfoCaseContainer>
                    <Styled.ArrowContainer special={currentCase.titleImgSpecial}>
                      <img src={ArrowBack} onClick={history.back} alt="arrowBack" />
                    </Styled.ArrowContainer>
                    {currentCase.titleText ? (
                      <Styled.CaseSloganTitle>
                        <img src={currentCase.titleImg} className="logoTitle" alt="logo-title" />
                        <span dangerouslySetInnerHTML={{ __html: t(currentCase.titleText) }}></span>
                      </Styled.CaseSloganTitle>
                    ) : (
                      <img src={currentCase.titleImg} className="logoTitle" alt="logo-title" />
                    )}
                    <Styled.CaseSlogan special={currentCase.titleImgSpecial}>
                      <span dangerouslySetInnerHTML={{ __html: t(currentCase.slogan) }}></span>
                    </Styled.CaseSlogan>
                    <Styled.ButtonSlogan href={`https://${currentCase.website}`} target="_blank">
                      {currentCase.website}
                    </Styled.ButtonSlogan>
                  </Styled.InfoCaseContainer>
                </Styled.InfoCase>
                {/* </Wrapper> */}
              </Styled.CaseMainInfoContainer>
            </Styled.CaseMainInfo>
          </Styled.CaseHeader>
          <Styled.CaseContent>
            {/* <Wrapper> */}
            <Styled.ContentContainer>
              <Styled.ContentTitle>
                <span>{t("common.services")}</span>
              </Styled.ContentTitle>
              <Styled.Content>
                {t(currentCase.services, { returnObjects: true, defaultValue: [] }).map(i => {
                  return (
                    <Styled.DefaultBox key={i} color={grey}>
                      {i}
                    </Styled.DefaultBox>
                  );
                })}
              </Styled.Content>
            </Styled.ContentContainer>
            <Styled.ContentContainer>
              <Styled.ContentTitle>
                <span>{t("common.territories")}</span>
              </Styled.ContentTitle>
              <Styled.Content>
                {currentCase.territories.map(i => {
                  return (
                    <Styled.DefaultBox color={i.color} key={i.name}>
                      {t(i.name)}
                    </Styled.DefaultBox>
                  );
                })}
              </Styled.Content>
            </Styled.ContentContainer>
            <Styled.ContentContainer>
              <Styled.ContentTitle>
                <span>{t("common.overview")}</span>
              </Styled.ContentTitle>
              <Styled.Content>
                <span>{t(currentCase.overview)}</span>
              </Styled.Content>
            </Styled.ContentContainer>
            {currentCase.upperTitleImg && (
              <Styled.ContentContainer>
                <Styled.ContentTitle></Styled.ContentTitle>
                <Styled.Content>
                  {currentCase.upperTitleImg && (
                    <Styled.SpecificTitles>
                      {currentCase.upperTitleImg.map((v, i) => {
                        return <img src={v} key={`sepc${i}`} alt={`specif${i}`} />;
                      })}
                    </Styled.SpecificTitles>
                  )}
                </Styled.Content>
              </Styled.ContentContainer>
            )}
            <Styled.ContentContainer>
              <Styled.ContentTitle>
                <span>{t("common.challenge")}</span>
              </Styled.ContentTitle>
              <Styled.Content>
                <span>{t(currentCase.challenge)}</span>
              </Styled.Content>
            </Styled.ContentContainer>
            <Styled.ContentContainer>
              <Styled.ContentTitle>
                <span>{t("common.solution")}</span>
              </Styled.ContentTitle>
              <Styled.Content>
                <ul>
                  {t(currentCase.solution, { returnObjects: true, defaultValue: [] }).map(i => (
                    <li key={i}>
                      <span>{i}</span>
                    </li>
                  ))}
                </ul>
              </Styled.Content>
            </Styled.ContentContainer>
            <Styled.ContentContainer>
              <Styled.ContentProtocol>
                {currentCase.protocol.map((v, i) => (
                  <Styled.ProtocolContainer key={i}>
                    <img src={v} alt="protocol" />
                  </Styled.ProtocolContainer>
                ))}
              </Styled.ContentProtocol>
            </Styled.ContentContainer>
          </Styled.CaseContent>
          {currentCase.imageMid && (
            <Styled.ContentContainer>
              <Styled.ContentImageMid>
                <Styled.ContentImageContainer special={currentCase.imageMidCss ? true : false}>
                  {Array.isArray(currentCase.imageMid) ? (
                    <React.Fragment>
                      <Styled.RowImage>
                        <img src={currentCase.imageMid[0]} alt="middle-1" />
                        <img src={currentCase.imageMid[1]} alt="middle-2" />
                        <img src={currentCase.imageMid[2]} alt="middle-3" />
                      </Styled.RowImage>
                      <Styled.RowImage>
                        {currentCase.imageMid[3] && (
                          <img src={currentCase.imageMid[3]} alt="middle-4" />
                        )}
                        {currentCase.imageMid[4] && (
                          <img src={currentCase.imageMid[4]} alt="middle-5" />
                        )}
                        {currentCase.imageMid[5] && (
                          <img src={currentCase.imageMid[5]} alt="middle-6" />
                        )}
                      </Styled.RowImage>
                    </React.Fragment>
                  ) : currentCase.bookStyle ? (
                    <Styled.Book>
                      <div
                        className="book"
                        onClick={
                          currentCase.imageMidPath
                            ? () => {
                                window.open(currentCase.imageMidPath);
                              }
                            : undefined
                        }
                      >
                        <div className="inner-book">
                          <div
                            className="img front"
                            style={{
                              paddingTop: "calc(1.07 * 100%)",
                              backgroundImage: `url(${currentCase.imageMid})`
                            }}
                          ></div>
                          <div className="page page-1"></div>
                          <div className="page page-2"></div>
                          <div className="page page-3"></div>
                          <div className="page page-4">
                            <span>
                              Projeto Blockchain no GHG Protocol
                              <br />
                              <br />
                            </span>
                            <span>FGV EAESP</span>
                          </div>
                          <div className="page page-5"></div>
                          <div
                            className="final-page"
                            style={{ paddingTop: "calc(1.07 * 100%)" }}
                          ></div>
                        </div>
                      </div>
                    </Styled.Book>
                  ) : (
                    <Styled.ImageAlone path={currentCase.path}>
                      <img
                        src={currentCase.imageMid}
                        alt="image-middle"
                        onClick={
                          currentCase.imageMidPath
                            ? () => {
                                window.open(currentCase.imageMidPath);
                              }
                            : undefined
                        }
                      />
                    </Styled.ImageAlone>
                  )}
                </Styled.ContentImageContainer>
                <Styled.RowColoured color={currentCase.mainColor}></Styled.RowColoured>
              </Styled.ContentImageMid>
            </Styled.ContentContainer>
          )}
          <Styled.CaseContent>
            <Wrapper>
              <Styled.ContentContainer>
                <Styled.ContentTitle>
                  <span>{t("common.results")}</span>
                </Styled.ContentTitle>
                <Styled.Content>
                  <ul>
                    {t(currentCase.results, { returnObjects: true, defaultValue: [] }).map(i => (
                      <li key={i}>
                        <span>{i}</span>
                      </li>
                    ))}
                  </ul>
                </Styled.Content>
              </Styled.ContentContainer>
              <Styled.ContentContainer>
                <Styled.Label>
                  <Styled.LabelContainer>
                    {currentCase.labels.map((v, i) => (
                      <img src={v} alt="label" key={i + 3} />
                    ))}
                  </Styled.LabelContainer>
                </Styled.Label>
              </Styled.ContentContainer>
              {currentCase.quote ? (
                <Styled.ContentContainer direction="column">
                  <Styled.LineGradient></Styled.LineGradient>
                  <Styled.Review>
                    <span>{t(currentCase.quote)}</span>
                    <span>{t(currentCase.quoter)}</span>
                  </Styled.Review>
                  {currentCase.quote2 && (
                    <Styled.Review>
                      <span>{t(currentCase.quote2)}</span>
                      <span>{t(currentCase.quoter2)}</span>
                    </Styled.Review>
                  )}
                  <Styled.LineGradient></Styled.LineGradient>
                </Styled.ContentContainer>
              ) : (
                <Styled.LineGradient></Styled.LineGradient>
              )}
            </Wrapper>
            <CarouselCase cases={slides} />
          </Styled.CaseContent>
          <Contact dark={true} lightToast={true} />
          <Footer dark={true} />
        </Styled.CaseBody>
      </Reveal>
    )
  );
};
