export default function loadPolyfills() {
  let polyfills = [];

  if (
    !(
      "IntersectionObserver" in global &&
      "IntersectionObserverEntry" in global &&
      "intersectionRatio" in IntersectionObserverEntry.prototype
    )
  ) {
    polyfills.push(import("intersection-observer"));
  }

  if (!("CSS" in window && CSS.supports && CSS.supports("scroll-behavior: smooth"))) {
    polyfills.push(
      import("smoothscroll-polyfill").then(mod => {
        mod.default.polyfill();
      })
    );
  }

  return Promise.all(polyfills);
}
