import React, { useState } from "react";
import styled from "@emotion/styled/macro";
import { Reveal } from "../components";

let BannerWrapper = styled.div`
  position: fixed;
  top: 32px;
  right: 40px;
  background: #615f5f69;
  color: #ff6501;
  font-weight: bold;
  padding: 6px 32px 6px 32px;
  z-index: 1000;
  border-radius: 8px;

  z-index: 1050;

  .fadeIn {
    animation: fadeInCube 5s ease-in;
    animation-iteration-count: 1;
    animation-duration: 5s;
  }

  @keyframes fadeInCube {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media (max-width: 500px) {
    top: 32px;
    right: unset;
    margin-left: 16px;
    margin-right: 16px;
  }

  p:first-of-type {
    margin-bottom: 6px;
    font-weight: 500;
    text-transform: uppercase;
  }

  opacity: 0;
  animation: reveal 0.5s ease-in forwards;
  animation-delay: 1200ms;
  @keyframes reveal {
    to {
      opacity: 1;
    }
  }
`;

let Link = styled.a`
  text-decoration: underline;
`;

let CloseButton = styled.button`
  background: transparent;
  outline: none;
  border: none;
  color: #fff;
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 14px;
  font-size: 16px;
  opacity: 0.87;

  &:hover {
    opacity: 1;
  }
`;

const BannerText = styled.p`
  margin-top: 0px;
  white-space: nowrap;
`;

export default ({ children, delay, props }) => {
  let [visible, setVisible] = useState(true);

  return (
    visible && (
      <Reveal delay={delay}>
        <BannerWrapper>
          <CloseButton onClick={() => setVisible(false)}>&#10006;</CloseButton>
          <p>Check out how we're trying to bring light to COVID-19</p>
          <BannerText>
            Access <Link href="https://desviralize.org/">desviralize.org</Link> and participate!
          </BannerText>
        </BannerWrapper>
      </Reveal>
    )
  );
};
