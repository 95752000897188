import React, { useEffect } from "react";
import {
  Reveal,
  BodyText1,
  Subheader3,
  SubHeader2,
  HeaderText5,
  HeaderText3
} from "../../../components";
import styled from "@emotion/styled/macro";
import { SectionText, LandingButton, ButtonContainer } from "../ui-kit";
import { Section } from "../../../components";
import { ReactComponent as ProductsMain } from "../../../assets/solutions/blockchain_consulting.svg";
import { useTranslation, Trans } from "react-i18next";

export default function Solution({ scroll, location }) {
  const { t } = useTranslation("consulting");
  const solutions = [
    {
      title: t("consulting.ideationAndDesign.title"),
      description: t("consulting.ideationAndDesign.description"),
      items: [
        t("consulting.ideationAndDesign.productDiscovery"),
        t("consulting.ideationAndDesign.blockchainValue"),
        t("consulting.ideationAndDesign.tokenEconomy"),
        t("consulting.ideationAndDesign.productVision"),
        t("consulting.ideationAndDesign.pocOrMVP")
      ]
    },
    {
      title: t("consulting.poc.title"),
      description: t("consulting.poc.description"),
      items: [
        t("consulting.poc.modelTests"),
        t("consulting.poc.iterance"),
        t("consulting.poc.feedbackTests"),
        t("consulting.poc.validation"),
        t("consulting.poc.MVP")
      ]
    },
    {
      title: t("consulting.productDevelopment.title"),
      description: t("consulting.productDevelopment.description"),
      items: [
        t("consulting.productDevelopment.item1"),
        t("consulting.productDevelopment.item2"),
        t("consulting.productDevelopment.item3"),
        t("consulting.productDevelopment.item4"),
        t("consulting.productDevelopment.item5")
      ]
    },
    {
      title: t("consulting.supportAndScale.title"),
      description: t("consulting.supportAndScale.description"),
      items: [
        t("consulting.supportAndScale.item1"),
        t("consulting.supportAndScale.item2"),
        t("consulting.supportAndScale.item3"),
        t("consulting.supportAndScale.item4"),
        t("consulting.supportAndScale.item5")
      ]
    }
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Consulting>
      <CurrencyHeader>
        <Section style={{ fontSize: 22.5 }} title={t("consulting.title")} id="services">
          <ShowOnLG>
            <SectionText id="section-text" style={{ fontSize: 18, marginTop: 0 }}>
              <HeaderText3 style={{ fontWeight: 600, marginTop: 0 }}>
                <Trans t={t} i18nKey="consulting.headerTitle">
                  Blockchain Consulting for Social Innovation
                </Trans>
              </HeaderText3>
              <HeaderText5 style={{ fontWeight: 400, fontSize: 27, marginTop: 0 }}>
                <Trans t={t} i18nKey="consulting.headerDescription">
                  From discovery to implementation, we provide end-to-end development
                  <br /> services support to transform ideas into scalable digital products and
                  <br /> systems. Through a combination of an experienced multi talent team
                  <br /> and integrated services of development,we sign a tailored and
                  client-centered approach to deliver innovation at any stage of your
                  <br /> project timeline.
                </Trans>
              </HeaderText5>
            </SectionText>
          </ShowOnLG>
          <ShowOnMD>
            <SectionText id="section-text" style={{ fontSize: 18, marginTop: 0 }}>
              <HeaderText3
                style={{ fontWeight: 600, marginTop: 0, fontSize: 33, lineHeight: "42px" }}
              >
                <Trans t={t} i18nKey="consulting.headerTitle">
                  Blockchain Consulting
                </Trans>
              </HeaderText3>
              <HeaderText5 style={{ fontWeight: 300, fontSize: 16, marginTop: 0 }}>
                <Trans t={t} i18nKey="consulting.headerDescriptionMobile">
                  From discovery to implementation, we provide end-to-end development services and
                  support to transform ideas into scalable digital products and systems. Through a
                  combination of an experienced multi talent team and integrated services of
                  development, we sign a tailored and client-centered approach to deliver innovation
                  at any stage of your project timeline.
                </Trans>
              </HeaderText5>
            </SectionText>
            <SvgMain style={{ paddingTop: 0, paddingLeft: 18, paddingRight: 18 }}>
              <ProductsMain
                alt="consultoria blockchain"
                style={{ paddingTop: 24, maxWidth: 320 }}
              />
            </SvgMain>
          </ShowOnMD>
        </Section>
        <ShowOnLG>
          <SvgMain style={{ paddingTop: 0 }}>
            <ProductsMain />
          </SvgMain>
        </ShowOnLG>
      </CurrencyHeader>
      <ButtonContainer style={{ justifyContent: "flex-start" }}>
        <LandingButton href={t("consulting.landingLink")} target="_blank">
          {t("consulting.contact")}
        </LandingButton>
      </ButtonContainer>
      <CardRow>
        <SolutionCard>
          <div>
            <CardTitle>
              <Trans t={t} i18nKey="consulting.designScienceResearchTitle">
                Blockchain Design Science Research
              </Trans>
            </CardTitle>
            <CardDescription>
              <Trans t={t} i18nKey="consulting.designScienceResearchDescription">
                At this stage, we research, design and iterate to define the ideal shape and path
                for your project. From agile perspective, this step consists in two compounds of
                Design Science, which is a science of artifact for solving problems through creative
                innovations, and Research Methodology, applied as an action plan, strategy and
                process for a <br />
                definition of an informational system, turning ideas into tested pilots.
              </Trans>
            </CardDescription>
          </div>
          <MDOnly>
            <CardRow style={{ background: "#000", marginRight: -2 }}>
              {solutions.map((solution, index) => {
                if (index === 2 || index === 3) {
                  return false;
                }
                return (
                  <ItemsCard key={solution.title}>
                    <ItemsCardTitle>{solution.title}</ItemsCardTitle>
                    <ItemsCardDescription>{solution.description}</ItemsCardDescription>
                    <BorderedList>
                      <div>
                        {solution.items.map((item, index) => {
                          let applyBorder = index !== solution.items.length - 1;
                          let offsetLeft = applyBorder ? -7 : -5;
                          return (
                            <div
                              style={{
                                borderLeft: applyBorder && "2px solid #333",
                                display: "flex",
                                flexDirection: "row",
                                height: 48
                              }}
                            >
                              <VerticalCircleElement style={{ left: offsetLeft }} />
                              <BorderedListItem style={{ marginTop: -8, marginLeft: 12 }}>
                                {item}
                              </BorderedListItem>
                            </div>
                          );
                        })}
                      </div>
                    </BorderedList>
                  </ItemsCard>
                );
              })}
            </CardRow>
          </MDOnly>
        </SolutionCard>
        <SolutionCard>
          <div>
            <CardTitle>
              <Trans t={t} i18nKey="consulting.customTechDevelopmentTitle">
                Custom Tech Development
              </Trans>
            </CardTitle>
            <CardDescription>
              <Trans t={t} i18nKey="consulting.customTechDevelopmentDescription">
                We form a dedicated team for each project, in accordance to the project-specific
                technological needs. The team stays in constant communication with the project{" "}
                <br />
                founders and product owner while working in two-week development sprints. By <br />
                using an agile development methodology, we ensure flexibility and timely delivery.
              </Trans>
            </CardDescription>
          </div>
          <MDOnly>
            <CardRow style={{ background: "#000", marginRight: -2 }}>
              {solutions.map((solution, index) => {
                if (index === 0 || index === 1) {
                  return false;
                }
                return (
                  <ItemsCard key={solution.title}>
                    <ItemsCardTitle>{solution.title}</ItemsCardTitle>
                    <ItemsCardDescription>{solution.description}</ItemsCardDescription>
                    <BorderedList>
                      <div>
                        {solution.items.map((item, index) => {
                          let applyBorder = index !== solution.items.length - 1;
                          let offsetLeft = applyBorder ? -7 : -5;
                          return (
                            <div
                              style={{
                                borderLeft: applyBorder && "2px solid #333",
                                display: "flex",
                                flexDirection: "row",
                                height: 48
                              }}
                            >
                              <VerticalCircleElement style={{ left: offsetLeft }} />
                              <BorderedListItem style={{ marginTop: -8, marginLeft: 12 }}>
                                {item}
                              </BorderedListItem>
                            </div>
                          );
                        })}
                      </div>
                    </BorderedList>
                  </ItemsCard>
                );
              })}
            </CardRow>
          </MDOnly>
        </SolutionCard>
      </CardRow>
      <LGOnly>
        <TopGradientBorderWrapper>
          <GradientBorderInnerWrapper>
            <SpacedCirclesBorderWrapper>
              <CircleElement />
              <CircleElement />
              <CircleElement />
              <CircleElement />
            </SpacedCirclesBorderWrapper>
            <CardRow style={{ background: "#000", marginRight: -2 }}>
              {solutions.map(solution => (
                <ItemsCard key={solution.title}>
                  <ItemsCardTitle>{solution.title}</ItemsCardTitle>
                  <ItemsCardDescription>{solution.description}</ItemsCardDescription>
                  <BorderedList>
                    <div>
                      {solution.items.map((item, index) => {
                        let applyBorder = index !== solution.items.length - 1;
                        let offsetLeft = applyBorder ? -7 : -5;
                        return (
                          <div
                            style={{
                              borderLeft: applyBorder && "2px solid #333",
                              display: "flex",
                              flexDirection: "row",
                              height: 48
                            }}
                          >
                            <VerticalCircleElement style={{ left: offsetLeft }} />
                            <BorderedListItem style={{ marginTop: -8, marginLeft: 12 }}>
                              {item}
                            </BorderedListItem>
                          </div>
                        );
                      })}
                    </div>
                  </BorderedList>
                </ItemsCard>
              ))}
            </CardRow>
          </GradientBorderInnerWrapper>
        </TopGradientBorderWrapper>
      </LGOnly>
      <Section title={t("consulting.relatedCases")} id="related-cases"></Section>
    </Consulting>
  );
}

const ShowOnLG = styled.div`
  @media screen and (max-width: 883px) {
    display: none;
  }
`;

const ShowOnMD = styled.div`
  @media screen and (min-width: 883px) {
    display: none;
  }
`;

const CurrencyHeader = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  margin-bottom: 70px;

  @media screen and (min-width: 800px) {
    margin-bottom: 20px;
    flex-direction: row;
  }

  #section-text {
    color: #fff !important;
    max-width: 842px;

    #section-bellow {
      font-size: 28px;
      line-height: 37px;
    }
  }
`;

const LGOnly = styled.div`
  @media screen and (max-width: 883px) {
    display: none;
  }
`;

const MDOnly = styled.div`
  @media screen and (min-width: 883px) {
    display: none;
  }
`;

const SvgMain = styled.div`
  height: 100%;

  svg {
    height: 100%;
    width: 100%;
    padding-top: 45px;

    @media screen and (min-width: 800px) {
      margin: 0 0 0 85px;
      max-height: 404px;
      max-width: 400px;
    }
  }
`;

const BorderedList = styled.ul`
  padding-left: 0px;
  list-style-type: none;
  position: relative;
  padding-top: -12px;

  @media screen and (max-width: 883px) {
    margin-top: 18px;
  }
`;

const BorderedListItem = styled(BodyText1)`
  font-weight: 400;
  margin-top: 24px;
`;

const TopGradientBorderWrapper = styled.div`
  position: relative;
  background: linear-gradient(90deg, #6633cc, #00cc99);
  padding-top: 3px;
`;

const GradientBorderInnerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const SpacedCirclesBorderWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  height: 1px;
  overflow: visible;
`;
const CircleElement = styled.span`
  grid-row: 1;
  position: relative;
  color: #fff;
  top: -10px;
  background: #000;
  width: 20px;
  height: 20px;
  border-radius: 12px;
  border: 4px solid white;
`;

const VerticalCircleElement = styled.div`
  grid-column: 1;
  position: relative;
  color: #fff;
  background: #000;
  min-width: 12px;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background: #7e91b5;
`;

const ItemsCard = styled.div`
  flex: 1;
  margin-right: 18px;
  min-width: 180px;

  display: flex;
  flex-direction: column;
  @media screen and (max-width: 883px) {
    margin-right: 0px;
  }
`;

const ItemsCardTitle = styled(Subheader3)`
  color: #7e91b5;
  font-weight: 600;
  margin-bottom: 0;
`;

const ItemsCardDescription = styled(BodyText1)`
  font-size: 17px;
  font-weight: 400;
  flex: 1;
`;

const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-bottom: 48px;

  @media screen and (max-width: 883px) {
    flex-direction: column;
  }
`;
const Consulting = styled.div`
  height: 100%;
  width: 100%;
`;
const SolutionCard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;


  }

  &:first-of-type {
    margin-right: 20px;
  }
  @media screen and (max-width: 883px) {
    &:first-of-type {
    margin-right: 0px;
  }
`;

const CardTitle = styled(SubHeader2)`
  color: inherit;
  font-weight: 600;
  margin-top: 18px;
`;

const CardDescription = styled(BodyText1)`
  font-weight: 400;
`;
