import React, { useState } from "react";
import styled from "@emotion/styled/macro";
import { Section } from "../../../components";
import { SectionText, SectionDescription, SectionBelow, SectionBelowCustom } from "../ui-kit";
import { ReactComponent as TrustProviderMain } from "../../../assets/product/trustProvider/trustProviderMain.svg";
import { ReactComponent as Hyperledger } from "../../../assets/product/trustProvider/hyperledger.svg";
import { ReactComponent as Eth } from "../../../assets/product/trustProvider/ethereum.svg";
import { ReactComponent as Stellar } from "../../../assets/product/trustProvider/stellar.svg";
import { ReactComponent as Ripple } from "../../../assets/product/trustProvider/ripple.svg";
import { ReactComponent as BA1 } from "../../../assets/product/trustProvider/ba1.svg";
import { ReactComponent as BA2 } from "../../../assets/product/trustProvider/ba2.svg";
import { ReactComponent as BA3 } from "../../../assets/product/trustProvider/ba3.svg";
import { ReactComponent as Block } from "../../../assets/product/trustProvider/block.svg";
import Modal from "../../../components/Modal/Modal";
import { useTranslation, Trans } from "react-i18next";

const TrustProvider = () => {
  const { t } = useTranslation("trustProvider");

  const [modalStatus, setModalStatus] = useState(false);
  return (
    <React.Fragment>
      <Modal modalStatus={modalStatus} setModalStatus={setModalStatus} />
      <TrustProv>
        <CurrencyHeader>
          <Section title={t("title")} id="product">
            <SectionText>
              <SectionDescription>
                <Trans t={t} i18nKey="sectionDescription">
                  ChainGrid
                </Trans>
              </SectionDescription>
              <SectionBelow>
                <Trans t={t} i18nKey="below">
                  Traceability Framework designed to provide transparency, efficiency and security
                  to any kind of transaction record.
                </Trans>
              </SectionBelow>
            </SectionText>
          </Section>
          <PcSvg>
            <TrustProviderMain />
          </PcSvg>
        </CurrencyHeader>
        <WeCanHelp>
          <span>
            <Trans t={t} i18nKey="weCanHelp">
              We can help you implement.
            </Trans>
          </span>
          <a href={t("landingLink")} target="_blank">
            <Trans t={t} i18nKey="contactUs">
              Contact Us
            </Trans>
          </a>
        </WeCanHelp>
        <Title>
          <Trans t={t} i18nKey="about">
            About
          </Trans>
        </Title>
        <About>
          <SectionAbout>
            <SectionBelowCustom>
              <Trans t={t} i18nKey="proprietaryTechnology">
                Proprietary technology – a development suite used to build <br />
                Data Governance and Traceability solutions to optimize the implementation of custom
                Blockchain Networks – built under the permissioned Hyperledger protocol, with record
                integrations also in public Blockchains, such as Ethereum, Stellar and Ripple.
              </Trans>
            </SectionBelowCustom>
          </SectionAbout>
          {/* <Protocols>
            <Hyperledger />
            <Eth />
            <Stellar />
            <Ripple />
          </Protocols> */}
        </About>
        <Title>
          <Trans t={t} i18nKey="businessAdvantages">
            Business Advantages
          </Trans>
        </Title>
        <BA>
          <BASection>
            <BA1 />
            <BATitle>
              <Trans t={t} i18nKey="integratedView">
                Integrated view of the operation
              </Trans>
            </BATitle>
            <BADesc>
              <Trans t={t} i18nKey="efficiencyGain">
                Efficiency gain and cost reduction
              </Trans>
            </BADesc>
          </BASection>
          <BASection>
            <BA2 />
            <BATitle>
              <Trans t={t} i18nKey="allSortOfAsset">
                All sort of asset origin’s verification
              </Trans>
            </BATitle>
            <BADesc>
              <Trans t={t} i18nKey="increaseInPerceivedValue">
                Increase in perceived value for the asset
              </Trans>
            </BADesc>
          </BASection>
          <BASection>
            <BA3 />
            <BATitle>
              <Trans t={t} i18nKey="auditFacilitator">
                Audit facilitator
              </Trans>
            </BATitle>
            <BADesc>
              <Trans t={t} i18nKey="auditEfficiency">
                Audit efficiency and choice to provide internal and external transparency
              </Trans>
            </BADesc>
          </BASection>
        </BA>
        <Title>
          <Trans t={t} i18nKey="whyBlockchain">
            Why blockchain?
          </Trans>
        </Title>
        <Why>
          <WhyDesc>
            <span>
              <Trans t={t} i18nKey="promoteMoreAgile">
                Promotes more agile interactions between stakeholders.
              </Trans>
            </span>
            <span>
              <Trans t={t} i18nKey="enableTransparentTraceability">
                Enables transparent traceability of the entire chain, from its origin to the final
                consumer
              </Trans>
            </span>
          </WhyDesc>
          <Block />
          <WhyDesc>
            <span>
              <Trans t={t} i18nKey="reduceCosts">
                Reduces costs via process optimization and increases revenue via market expansion
              </Trans>
            </span>
            <span>
              <Trans t={t} i18nKey="improvesSafety">
                Improves safety and legal compliance of records
              </Trans>
            </span>
          </WhyDesc>
        </Why>
        <Title>
          <Trans t={t} i18nKey="whyBlockforce">
            Why blockforce?
          </Trans>
        </Title>
        <WhyBF>
          <WhyBFSection>
            <BATitle>
              <Trans t={t} i18nKey="experience">
                Experience
              </Trans>
            </BATitle>
            <BADesc>
              <Trans t={t} i18nKey="expertTeam">
                We are an expert team in Blockchain modeling and we already have several case
                studies in this niche.
              </Trans>
            </BADesc>
          </WhyBFSection>
          <WhyBFSection>
            <BATitle>
              <Trans t={t} i18nKey="credibility">
                Credibility
              </Trans>
            </BATitle>
            <BADesc>
              <Trans t={t} i18nKey="hyperledgerPartners">
                We are Hyperledger partners, having all the support and technical foundation of the
                largest collaborative Blockchain network in the world.
              </Trans>
            </BADesc>
          </WhyBFSection>
          <WhyBFSection>
            <BATitle>
              <Trans t={t} i18nKey="capacityOfApplications">
                Capacity of applications
              </Trans>
            </BATitle>
            <BADesc>
              <Trans t={t} i18nKey="meetMainDemands">
                We meet the main demands for Blockchain in the areas that are more mature in the
                market.
              </Trans>
            </BADesc>
          </WhyBFSection>
        </WhyBF>
      </TrustProv>
    </React.Fragment>
  );
};

const WeCanHelp = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;

  @media screen and (min-width: 800px) {
    margin-bottom: 50px;
  }

  > span {
    font-size: 29px;
    font-weight: 600;
    color: #000;
    line-height: 57px;
    margin-bottom: 44px;

    @media screen and (min-width: 800px) {
      font-size: 43px;
    }
  }

  > a {
    width: 100%;
    height: 64px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    text-transform: uppercase;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    border-radius: 5px;

    background: rgb(0, 205, 153);
    background: -moz-linear-gradient(90deg, rgba(0, 205, 153, 1) 0%, rgba(103, 46, 204, 1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(0, 205, 153, 1) 0%, rgba(103, 46, 204, 1) 100%);
    background: linear-gradient(90deg, rgba(0, 205, 153, 1) 0%, rgba(103, 46, 204, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00cd99",endColorstr="#672ecc",GradientType=1);

    @media screen and (min-width: 800px) {
      max-width: 452px;
    }
  }
`;

const WhyBF = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  @media screen and (min-width: 800px) {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 160px;
    padding-top: 65px;
  }
`;

const WhyBFSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 50px;

  @media screen and (min-width: 800px) {
    margin-right: 50px;
    margin: 0;
    width: 31%;
  }
`;

const Why = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  > svg {
    width: 100%;
    padding: 0 24px;
  }

  @media screen and (min-width: 800px) {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 180px;

    > svg {
      margin: 0;
      width: auto;
    }
  }
`;

const WhyDesc = styled.div`
  width: 100%;
  height: auto;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;

  @media screen and (min-width: 800px) {
    justify-content: center;
    margin-top: 0px;
    height: 350px;

    > span:first-of-type {
      margin-bottom: 95px;
    }
  }

  > span {
    font-size: 22px;
    line-height: 29px;
    color: #000;
  }
  > span:first-of-type {
    margin-bottom: 24px;
  }
`;

const BA = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  margin-bottom: 50px;

  @media screen and (min-width: 800px) {
    padding-top: 70px;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 160px;
  }
`;

const BASection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 50px;

  > svg {
    max-height: 155px;
    width: 100%;
    max-width: 350px;
    margin-bottom: 30px;
  }

  @media screen and (min-width: 800px) {
    margin-right: 50px;
    margin: 0;
    width: 31%;
  }
`;

const BATitle = styled.div`
  font-size: 28px;
  font-weight: 600;
  color: #00cd99;
  line-height: 37px;
`;

const BADesc = styled.div`
  font-size: 22px;
  font-weight: 500;
  line-height: 29px;
  margin-top: 16px;
  color: #000;
`;

const TrustProv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const About = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  @media screen and (min-width: 800px) {
    flex-direction: row;
    margin-bottom: 220px;
  }
`;

const SectionAbout = styled.div`
  width: 100%;
  height: 100%;
  max-width: 714px;
  display: flex;
  margin-bottom: 50px;

  @media screen and (min-width: 800px) {
    margin-bottom: 0;
  }
`;

const Protocols = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  > svg {
    margin-bottom: 30px;
  }

  @media screen and (min-width: 800px) {
    margin-top: 80px;
    flex-direction: row;

    > svg {
      width: 50%;
      margin-bottom: 30px;
    }
  }
`;

const Title = styled.span`
  font-size: 16px;
  line-height: 1.625px;
  letter-spacing: 1.5;
  text-transform: uppercase;
  color: #7e90b4;
  font-weight: 600;
  margin: 0;
  margin-top: 0;
  margin-bottom: 16px;
`;

const CurrencyHeader = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  margin-bottom: 70px;

  @media screen and (min-width: 800px) {
    margin-bottom: 85px;
    flex-direction: row;
  }
`;

const PcSvg = styled.div`
  height: 100%;

  svg {
    height: 100%;
    width: 100%;

    @media screen and (min-width: 800px) {
      margin: 0 0 0 85px;
      height: 316px;
      width: 600px;
    }
  }
`;

export default TrustProvider;
