import React, { useState } from "react";
import styled from "@emotion/styled/macro";
import { Section, CasesCarousel, Reveal, lateralPadding, Wrapper } from "../../../components";

import cases from "../../../cases";
import { useTranslation, Trans } from "react-i18next";

let slides = Object.keys(cases).map(key => cases[key]);

export default () => {
  const { t, i18n } = useTranslation("home");

  let [navigatingToCase, setNavigatingToCase] = useState(false);

  return (
    <Reveal>
      <Wrapper>
        {!navigatingToCase && (
          <Title>
            <Trans t={t} i18nKey="cases">
              Cases & Partnerships
            </Trans>
          </Title>
        )}
      </Wrapper>
      <Section id={"cases"}>
        <CasesWrapper>
          <CasesCarousel onNavigateToCase={() => setNavigatingToCase(true)} slides={slides} />
        </CasesWrapper>
      </Section>
    </Reveal>
  );
};

let Title = styled.span`
  font-size: 1.375rem;
  color: #7e91b5;
`;

let CasesWrapper = styled.div`
  position: relative;
  padding: 100px 0 48px;

  @media (max-width: ${1000 + 2 * lateralPadding}px) {
    margin-top: 32px;
  }

  @media (min-width: 501px) {
    transform: scale(0.78);
  }
`;
