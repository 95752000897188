import React, { useState } from "react";
import styled from "@emotion/styled/macro";
import { Section } from "../../../components";
import { SectionText, SectionDescription, SectionBelow, SectionBelowCustom } from "../ui-kit";
import FurtherPayMain from "../../../assets/product/furtherPay/furtherPayMain.png";
import FlowImg from "../../../assets/product/furtherPay/flow.png";
import CriptGroup from "../../../assets/product/furtherPay/criptoGroup.png";
import { ReactComponent as PhoneShop } from "../../../assets/product/furtherPay/phoneShop.svg";
import { ReactComponent as TruckShop } from "../../../assets/product/furtherPay/truckShop.svg";
import { ReactComponent as Notebook } from "../../../assets/product/furtherPay/notebook.svg";
import { ReactComponent as Card } from "../../../assets/product/furtherPay/card.svg";
import Modal from "../../../components/Modal/Modal";
import { useTranslation, Trans } from "react-i18next";

const FurtherPay = () => {
  const { t, i18n } = useTranslation("furtherPay");

  const [modalStatus, setModalStatus] = useState(false);
  return (
    <React.Fragment>
      <Modal modalStatus={modalStatus} setModalStatus={setModalStatus} />
      <TrustProv>
        <CurrencyHeader>
          <Section title="PRODUCTS" id="product">
            <SectionText>
              <SectionDescription>
                <Trans t={t} i18nKey="title">
                  Further Pay
                </Trans>
              </SectionDescription>
              <SectionBelow>
                <Trans t={t} i18nKey="below">
                  Quick and accessible tool to expand revenues lines in your business.
                </Trans>
              </SectionBelow>
            </SectionText>
          </Section>
          <ImageMain>
            <img src={FurtherPayMain} />
          </ImageMain>
        </CurrencyHeader>
        <Title>
          <Trans t={t} i18nKey="whatWeOffer">
            What we offer
          </Trans>
        </Title>
        <About>
          <SectionAbout>
            <SectionBelowCustom>
              <Trans t={t} i18nKey="about">
                Interface tool that enables and simplifies the receipt of digital currencies among
                customers, merchants and banks during online payments.
              </Trans>
            </SectionBelowCustom>
          </SectionAbout>
          <Flow>
            <img src={FlowImg} />
          </Flow>
        </About>
        <DescAlone>
          <span>
            <Trans t={t} i18nKey="sourceOfRevenue">
              Opening up a new source of revenue and connecting your business with the crypto world.
            </Trans>
          </span>
        </DescAlone>
        <Title>
          <Trans t={t} i18nKey="audiences">
            Audiences
          </Trans>
        </Title>
        <AudiencesType>
          <Aud>
            <PhoneShop />
            <span>
              <Trans t={t} i18nKey="ecommerces">
                E-commerces
              </Trans>
            </span>
          </Aud>
          <Aud>
            <TruckShop />
            <span>
              <Trans t={t} i18nKey="onlineMarketplaces">
                Online Marketplaces
              </Trans>
            </span>
          </Aud>
          <Aud>
            <Notebook />
            <span>
              <Trans t={t} i18nKey="projects">
                Projects / Applications wishing to receive in Digital Currency
              </Trans>
            </span>
          </Aud>
          <Aud>
            <Card />
            <span>
              <Trans t={t} i18nKey="traditionalPayment">
                Traditional Payment <br /> Facilitators / Gateways
              </Trans>
            </span>
          </Aud>
        </AudiencesType>
        <Title>
          <Trans t={t} i18nKey="why">
            Why it makes sense?
          </Trans>
        </Title>
        <FocusMarket>
          <Market>
            <span>
              <Trans t={t} i18nKey="newRevenueSources">
                New revenue sources
              </Trans>
            </span>
            <span>
              <Trans t={t} i18nKey="increaseRevenue">
                Increase your business’s revenue sources. More than 130 digital currencies accepted
              </Trans>
            </span>
          </Market>
          <Market>
            <span>
              <Trans t={t} i18nKey="global">
                Global
              </Trans>
            </span>
            <span>
              <Trans t={t} i18nKey="receiveAnywhere">
                Possibility to receive anywhere in the world.
              </Trans>
            </span>
          </Market>
          <Market>
            <span>
              <Trans t={t} i18nKey="simplicity">
                Simplicity
              </Trans>
            </span>
            <span>
              <Trans t={t} i18nKey="simpleIntegration">
                Simple integration with your current payment system checkout
              </Trans>
            </span>
          </Market>
          <Market>
            <span>
              <Trans t={t} i18nKey="Affordability">
                Affordability
              </Trans>
            </span>
            <span>
              <Trans t={t} i18nKey="lowerFees">
                Lower Fees than credit card
              </Trans>
            </span>
          </Market>
          <Market>
            <span>
              <Trans t={t} i18nKey="privacy">
                100% Privacy
              </Trans>
            </span>
            <span>
              <Trans t={t} i18nKey="customerPrivacy">
                Customer Privacy - no need for personal data for transactions
              </Trans>
            </span>
          </Market>
          <Market>
            <span>
              <Trans t={t} i18nKey="easyImplementation">
                Easy implementation
              </Trans>
            </span>
            <span>
              <Trans t={t} i18nKey="focusAttention">
                Focus your attention on the recipe, we take care of the technical part for you
              </Trans>
            </span>
          </Market>
        </FocusMarket>
        <WorkWith>
          <SectionAbout>
            <SectionWorkWith>
              <span>
                <Trans t={t} i18nKey="workWithTop10">
                  Work with the top 10 cripto currencies and more than a hundred others.
                </Trans>
              </span>
            </SectionWorkWith>
          </SectionAbout>
          <CriptoGroup>
            <img src={CriptGroup} />
          </CriptoGroup>
        </WorkWith>
        <WeCanHelp>
          <span>
            <Trans t={t} i18nKey="requestDemo">
              Request a demo
            </Trans>
          </span>
          <button
            onClick={() => {
              setModalStatus(true);
            }}
          >
            <Trans t={t} i18nKey="contactUs">
              Contact Us
            </Trans>
          </button>
        </WeCanHelp>
      </TrustProv>
    </React.Fragment>
  );
};

const WorkWith = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  @media screen and (min-width: 800px) {
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 220px;
  }
`;

const ImageMain = styled.div`
  height: 100%;
  width: 100%;

  img {
    height: 100%;
    width: 100%;

    @media screen and (min-width: 800px) {
      margin: 0 0 0 55px;
      position: relative;
      bottom: 40px;
    }
  }
`;

const SectionWorkWith = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  @media screen and (min-width: 800px) {
    justify-content: space-between;
    flex-direction: row;
  }

  > span {
    font-size: 35px;
    font-weight: 600;
    line-height: 46px;
    color: #000;
  }
`;

const CriptoGroup = styled.div`
  height: 100%;
  width: 100%;
  max-width: 590px;
  max-height: 225px;

  > img {
    width: 100%;
  }
`;

const FocusMarket = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  @media screen and (min-width: 800px) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 160px;
  }
`;

const Market = styled.div`
  width: 100%;
  height: 100%;
  max-width: 424px;

  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  @media screen and (min-width: 800px) {
    flex-wrap: wrap;
    padding-top: 65px;
  }

  > span {
    font-size: 28px;
    font-weight: 600;
    color: #00cd99;
  }

  > span:last-of-type {
    font-size: 22px;
    font-weight: 500;
    margin-top: 8px;
    color: #000;
  }
`;

const AudiencesType = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  @media screen and (min-width: 800px) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 120px;
    padding-top: 65px;
  }
`;

const Aud = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  @media screen and (min-width: 800px) {
    max-width: 320px;
    margin-top: 30px;
  }

  > span {
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
    color: #000;
    margin-top: 44px;
  }
`;

const Flow = styled.div`
  width: 100%;
  height: 100%;

  > img {
    width: 100%;
  }
`;

const DescAlone = styled.div`
  height: 100%;
  width: 100%;
  max-width: 842px;
  margin-bottom: 50px;

  > span {
    font-size: 35px;
    font-weight: 600;
    line-height: 46px;
    color: #000;
  }
`;

const WeCanHelp = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;

  @media screen and (min-width: 800px) {
    margin-bottom: 180px;
  }

  > span {
    font-size: 29px;
    font-weight: 600;
    color: #000;
    line-height: 57px;
    margin-bottom: 44px;

    @media screen and (min-width: 800px) {
      font-size: 43px;
    }
  }

  > button {
    width: 100%;
    height: 64px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    text-transform: uppercase;
    color: #fff;

    border: 0;
    border-radius: 5px;

    background: rgb(0, 205, 153);
    background: -moz-linear-gradient(90deg, rgba(0, 205, 153, 1) 0%, rgba(103, 46, 204, 1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(0, 205, 153, 1) 0%, rgba(103, 46, 204, 1) 100%);
    background: linear-gradient(90deg, rgba(0, 205, 153, 1) 0%, rgba(103, 46, 204, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00cd99",endColorstr="#672ecc",GradientType=1);

    @media screen and (min-width: 800px) {
      max-width: 452px;
    }
  }
`;

const TrustProv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const About = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  @media screen and (min-width: 800px) {
    flex-direction: row;
    margin-bottom: 220px;
  }
`;

const SectionAbout = styled.div`
  width: 100%;
  height: 100%;
  max-width: 588px;
  display: flex;
  margin-bottom: 50px;

  @media screen and (min-width: 800px) {
    margin-bottom: 0;
  }
`;

const Title = styled.span`
  font-size: 16px;
  line-height: 1.625px;
  letter-spacing: 1.5;
  text-transform: uppercase;
  color: #7e90b4;
  font-weight: 600;
  margin: 0;
  margin-top: 0;
  margin-bottom: 16px;
`;

const CurrencyHeader = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  @media screen and (min-width: 800px) {
    flex-direction: row;
  }
`;

export default FurtherPay;
