import React from "react";
import styled from "@emotion/styled/macro";
import { InView } from "react-intersection-observer";

let RevealWrapper = styled.div`
  position: relative;
  opacity: 0;
  animation: ${({ inView }) => (inView ? `reveal .5s ease-in forwards` : "none")};
  animation-delay: ${({ delay }) => delay || 0}ms;
  z-index: 12;
  @keyframes reveal {
    to {
      opacity: 1;
    }
  }
`;

export default ({ children, delay }) => (
  <InView triggerOnce rootMargin="0px">
    {({ inView, ref }) => (
      <RevealWrapper ref={ref} inView={inView} delay={delay}>
        {children.call != null ? children(inView) : children}
      </RevealWrapper>
    )}
  </InView>
);
