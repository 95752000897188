import React, { useEffect, memo } from "react";

import Splash from "./sections/Splash";
import Cases from "./sections/Cases";
import Team from "./sections/Team";
import Partners from "./sections/Partners";
import Contact from "./sections/Contact";
import ScopeAndWhatSections from "./sections/ScopeAndWhatSections";
import { Nav, Footer } from "../../components";
import Press from "./sections/Press";

export default memo(({ scroll, location }) => {
  useEffect(() => {
    scroll.restore(location, "smooth", 1000);
  }, []);

  return (
    <div style={{ overflow: "hidden" }}>
      <Nav />
      <Splash />
      <ScopeAndWhatSections />
      <Cases />
      {/* <Team getToKnown={true} /> */}
      <Partners />
      <Press />
      <Contact lightToast={false} />
      <Footer />
    </div>
  );
});
