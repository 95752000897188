import React, { useState } from "react";
import styled from "@emotion/styled/macro";
import { Section, TeamList, Reveal, Wrapper } from "../../../components";
import { ReactComponent as ArrowBack } from "../../../assets/team/arrow-back.svg";
import { history } from "../../../lib";

import andre from "../../../assets/team/andre_salem.png";
import elisa from "../../../assets/team/elisa.png";
import henriqueLeite from "../../../assets/team/henrique_leite.jpg";
import percival from "../../../assets/team/percival_lucena.png";
import marcelo from "../../../assets/team/marcelo_martins.png";
import taynaah from "../../../assets/team/taynaah_reis.png";
import guilherme from "../../../assets/team/guilherme_maricato.png";
import victor from "../../../assets/team/victor_dario.png";
import andreBenedetti from "../../../assets/team/andre_benedetti.png";
import joseBologna from "../../../assets/team/jose_bologna.png";
import tales from "../../../assets/team/tales_gomes.png";
import lucasMoraes from "../../../assets/team/lucas_moraes.jpg";
import ricardo from "../../../assets/team/ricardo_russo.png";
import deon from "../../../assets/team/deon.png";
import Modal from "../../../components/Modal/Modal";
import wavesBG from "../../../assets/vision_bg.png";
import vitorDuarte from "../../../assets/team/vitor_duarte.jpg";

import { useTranslation, Trans } from "react-i18next";

export default props => {
  const { t } = useTranslation("home");
  const [modalStatus, setModalStatus] = useState(false);
  const navigateToTeam = uri => {
    history.navigate(`/team`);
  };
  return !props.getToKnown ? (
    <React.Fragment>
      <Modal dark="true" modalStatus={modalStatus} setModalStatus={setModalStatus} />
      <Reveal>
        <div
          style={{
            position: "absolute",
            height: "100%",
            backgroundImage: `url(${wavesBG})`,
            marginBottom: 0,
            width: "100vw",
            zIndex: 0,
            marginTop: -80,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain"
          }}
        />
        <Wrapper>
          <ArrowContainer>
            <ArrowBack onClick={history.back} />
          </ArrowContainer>
          <Section title={t("team.title")} id="who">
            <SectionText>
              <SectionDescription>
                <Trans t={t} i18nKey="team.head">
                  We organize ourselves as a blockchain network.
                </Trans>
              </SectionDescription>
              <SectionBelow>
                <Trans t={t} i18nKey="team.description">
                  We're a distributed remote first team, based on collaboration, commitment,
                  autonomy, trust and self-responsability.
                </Trans>
              </SectionBelow>
            </SectionText>
            <TeamWrapper>
              <TeamList teamMembers={teamMembers} inView={true} />
            </TeamWrapper>
            <MainInfo insideTeam={true}>
              <Title>
                <Trans t={t} i18nKey="team.interested">
                  Interested in working with us?
                </Trans>
              </Title>
              <Button
                insideTeam={true}
                onClick={() => {
                  setModalStatus(true);
                }}
              >
                <Trans t={t} i18nKey="team.contactUs">
                  Contact us
                </Trans>
              </Button>
            </MainInfo>
          </Section>
        </Wrapper>
      </Reveal>
    </React.Fragment>
  ) : (
    <Wrapper>
      <MainInfo>
        <Title>
          <Trans t={t} i18nKey="team.getToKnow">
            Get to know us better
          </Trans>
        </Title>
        <Button onClick={navigateToTeam}>
          <Trans t={t} i18nKey="team.meetOurTeam">
            MEET OUR TEAM
          </Trans>
        </Button>
      </MainInfo>
    </Wrapper>
  );
};

const TeamWrapper = styled.div`
  padding: 100px 0 48px;

  @media (max-width: 500px) {
    padding: 0 0 10px 0;
  }
`;

const ArrowContainer = styled.div`
  margin: 22px 0;

  @media screen and (min-width: 800px) {
    margin: 105px 0;
  }
`;

let SectionText = styled.div`
  padding: 0px 0 10px;
  font-weight: 600;
  font-size: 44px;
  width: 100%;
  max-width: 967px;

  display: flex;
  flex-direction: column;

  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translateX(-500px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  -webkit-animation: fadeInLeft;
  animation: fadeInLeft;
  opacity: 1;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
`;

let SectionDescription = styled.span`
  padding: 0px 0 10px;
  font-weight: 600;
  font-size: 26px;

  @media screen and (min-width: 800px) {
    font-size: 44px;
    line-height: 57px;
    margin-bottom: 0;
  }
`;

let SectionBelow = styled.span`
  font-size: 19px;
  margin-top: 20px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 56px;

  @media screen and (min-width: 800px) {
    font-size: 28px;
    line-height: 37px;
    margin-bottom: 0;
  }
`;

let MainInfo = styled.div`
  width: 100%;
  height: 100%;

  margin: 64px 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (min-width: 800px) {
    margin: 140px 0;
    justify-content: center;
    align-items: center;

    ${props =>
      props.insideTeam &&
      `
          margin: 100px 0 220px;
          align-items: flex-start;
        `}
  }
`;

let Title = styled.span`
  width: auto;
  height: 100%;

  font-size: 20px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 41px;

  @media screen and (min-width: 800px) {
    font-size: 44px;
  }
`;

let Button = styled.button`
  width: 100%;
  height: 100%;

  cursor: pointer;

  height: 53px;
  width: 60%;
  border: 0;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  font-family: "Titillium Web", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  @media screen and (min-width: 800px) {
    height: 64px;
  }

  ${props =>
    props.insideTeam
      ? `
        text-transform: uppercase;
        background: rgb(0,204,153);
        background: -moz-linear-gradient(90deg, rgba(0,204,153,1) 0%, rgba(102,51,204,1) 100%);
        background: -webkit-linear-gradient(90deg, rgba(0,204,153,1) 0%, rgba(102,51,204,1) 100%);
        background: linear-gradient(90deg, rgba(0,204,153,1) 0%, rgba(102,51,204,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00cc99",endColorstr="#6633cc",GradientType=1);
      `
      : `
        background: rgb(0, 205, 153);
          background: -moz-linear-gradient(
            90deg,
            rgba(0, 205, 153, 1) 0%,
            rgba(17, 120, 178, 1) 100%,
            rgba(103, 46, 204, 1) 100%
          );
          background: -webkit-linear-gradient(
            90deg,
            rgba(0, 205, 153, 1) 0%,
            rgba(17, 120, 178, 1) 100%,
            rgba(103, 46, 204, 1) 100%
          );
          background: linear-gradient(
            90deg,
            rgba(0, 205, 153, 1) 0%,
            rgba(17, 120, 178, 1) 100%,
            rgba(103, 46, 204, 1) 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00cd99",endColorstr="#672ecc",GradientType=1);
      `}

  @media screen and (min-width: 800px) {
    width: 332px;
  }
`;

let teamMembers = [
  {
    img: andre,
    name: "André Salem",
    title: "Co-Founder and CEO",
    desc:
      "The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz"
  },
  {
    img: marcelo,
    name: "Marcelo Martins",
    title: "Co-Founder",
    desc:
      "The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz"
  },
  {
    img: percival,
    name: "Percival Lucena",
    title: "Co-Founder and Advisor",
    desc:
      "The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz"
  },
  {
    img: elisa,
    name: "Elisa Cardamone",
    title: "Communication Strategist",
    desc:
      "The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz"
  },
  {
    img: andreBenedetti,
    name: "André Benedetti",
    title: "Full Stack Developer",
    desc:
      "The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz"
  },
  {
    img: guilherme,
    name: "Guilherme Maricato",
    title: "Full Stack Developer",
    desc:
      "The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz"
  },
  {
    img: victor,
    name: "Victor Dário",
    title: "Full Stack Developer",
    desc:
      "The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz"
  },
  {
    img: deon,
    name: "Deon Hullet",
    title: "Software Engineer",
    desc:
      "The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz"
  },
  {
    img: tales,
    name: "Tales Gomes",
    title: "UIX and Full Stack Developer",
    desc:
      "The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz"
  },
  // {
  //   img:
  //   name: "Catarina Gonçalves",
  //   title: "Product Manager"
  // },
  {
    img: lucasMoraes,
    name: "Lucas Moraes",
    title: "Full Stack Developer"
  },
  {
    img: vitorDuarte,
    name: "Vitor Duarte",
    title: "Tech leader ChainGrid"
  },
  {
    img: henriqueLeite,
    name: "Henrique Leite",
    title: "Tech leader Cryptum"
  },
  {
    img: joseBologna,
    name: "José Ernesto Bologna",
    title: "Advisor"
  },
  {
    img: ricardo,
    name: "Ricardo Russo",
    title: "Legal",
    desc:
      "The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz"
  },
  // {
  //   img: eduardo,
  //   name: "Eduardo Diniz",
  //   title: "Advisor",
  //   desc:
  //     "The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz"
  // },
  {
    img: taynaah,
    name: "Taynaah Reis",
    title: "Advisor",
    desc:
      "The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz"
  }
  // {
  //   img: henrique,
  //   name: "Henrique Alves",
  //   title: "Marketing Assistant"
  // }
].sort((a, b) => {
  if (a.name > b.name) {
    return 1;
  }
  if (b.name > a.name) {
    return -1;
  }
  return 0;
});
