import React from "react";
import styled from "@emotion/styled/macro";
import css from "@emotion/css/macro";

export let width = 1400;
export let lateralPadding = 48;
export let mobileLateralPadding = 32;

export let Wrapper = styled.div`
  position: relative;
  max-width: ${width + 2 * lateralPadding}px;
  width: 100%;
  height: 100%;
  padding: 0 ${lateralPadding}px;
  margin: 0 auto;

  @media (max-width: 500px) {
    padding: 0 ${mobileLateralPadding}px;
  }
`;

export let InnerWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export let SectionTitle = styled.h2`
  font-size: 16px;
  line-height: 1.625;
  letter-spacing: 1.5;
  text-transform: uppercase;
  color: #7e90b4;
  font-weight: 600;
  margin: 0;
  margin-top: 0;
  margin-bottom: 16px;

  position: relative;
  z-index: 5;

  @media screen and (min-width: 800px) {
    margin-top: 48px;
    margin-bottom: 8px;
  }
`;

export let SectionInner = styled.section`
  position: relative;
  margin: 0;

  @media screen and (min-width: 800px) {
    margin: 0 0 65px;
  }

  ${({ hasTitle }) =>
    hasTitle
      ? css`
          @media (max-width: ${1000 + 2 * lateralPadding}px) {
            padding-top: 0px;
          }
        `
      : ""}

  ${SectionTitle} {
    top: 0;
    max-width: ${width + 2 * lateralPadding}px;
    width: 100%;

    span {
      max-width: 160px;
      display: inline-block;
    }
  }
`;

export let Section = ({ title, children, id }) => (
  <SectionInner hasTitle={!!title} id={id}>
    {!!title && (
      <SectionTitle>
        <span>{title}</span>
      </SectionTitle>
    )}
    {children}
  </SectionInner>
);

let SliderWrapper = styled.div`
  height: 20px;
  margin: 64px auto 32px;

  input {
    appearance: none;
    width: calc(100% - 12px);
    display: block;
    margin: 0 auto;
    height: 2px;
    ${"" /* background: linear-gradient(to right, #b81e7c, #1b5997, #58bf9b); */}
    background: #333333;
    outline: none;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }

  input::-webkit-slider-thumb {
    appearance: none;
    width: 64px;
    height: 20px;
    ${"" /* background: url('${thumb}') no-repeat; */}

    background: rgba(0,205,153,1);
    background: -moz-linear-gradient(left, rgba(0, 205, 153, 1) 0%, rgba(103, 46, 204, 1) 100%);
    background: -webkit-gradient(
      left top,
      right top,
      color-stop(0%, rgba(0, 205, 153, 1)),
      color-stop(100%, rgba(103, 46, 204, 1))
    );
    background: -webkit-linear-gradient(left, rgba(0, 205, 153, 1) 0%, rgba(103, 46, 204, 1) 100%);
    background: -o-linear-gradient(left, rgba(0, 205, 153, 1) 0%, rgba(103, 46, 204, 1) 100%);
    background: -ms-linear-gradient(left, rgba(0, 205, 153, 1) 0%, rgba(103, 46, 204, 1) 100%);
    background: linear-gradient(to right, rgba(0, 205, 153, 1) 0%, rgba(103, 46, 204, 1) 100%);
    border-radius: 5px;

    border: 0;
    cursor: pointer;
  }

  input::-moz-range-thumb {
    width: 64px;
    height: 20px;
    ${"" /* background: url('${thumb}') no-repeat; */}

    background: rgba(0,205,153,1);
    background: -moz-linear-gradient(left, rgba(0, 205, 153, 1) 0%, rgba(103, 46, 204, 1) 100%);
    background: -webkit-gradient(
      left top,
      right top,
      color-stop(0%, rgba(0, 205, 153, 1)),
      color-stop(100%, rgba(103, 46, 204, 1))
    );
    background: -webkit-linear-gradient(left, rgba(0, 205, 153, 1) 0%, rgba(103, 46, 204, 1) 100%);
    background: -o-linear-gradient(left, rgba(0, 205, 153, 1) 0%, rgba(103, 46, 204, 1) 100%);
    background: -ms-linear-gradient(left, rgba(0, 205, 153, 1) 0%, rgba(103, 46, 204, 1) 100%);
    background: linear-gradient(to right, rgba(0, 205, 153, 1) 0%, rgba(103, 46, 204, 1) 100%);
    border-radius: 5px;

    border: 0;
    outline: none;
    cursor: pointer;
  }
`;

export let Slider = ({ value, onChange, ...props }) => (
  <SliderWrapper>
    <input type="range" value={value} onChange={onChange} {...props} />
  </SliderWrapper>
);

export let Button = styled.button`
  appearance: none;
  background: ${props => (props.light ? "#fff" : "#111")};
  border: ${props => (props.noBorder ? "0px" : "1px solid")};
  border-radius: 7px;
  cursor: pointer;
  font: inherit;
  color: ${props => (props.light ? "#111" : "#fff")};
  border-color: ${props => (props.light ? "#111" : "#fff")};
  font-size: 1em;
  padding: 1em 2.5em;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.5s ease-in;
  outline: 0;

  &:hover,
  &:focus {
    color: ${props => (props.light ? "#fff" : "#111")};
    background: ${props => (props.light ? "#111" : "#fff")};
  }
`;

export let HeaderText1 = styled.h1`
  font-size: 3.815em;
`;

export let HeaderText2 = styled.h1`
  font-size: 3.052em;
`;

export let HeaderText3 = styled.h1`
  font-size: 2.441em;
`;

export let HeaderText4 = styled.h1`
  font-size: 1.953em;
`;

export let HeaderText5 = styled.h1`
  font-size: 1.563em;
`;

export let HeaderText6 = styled.h1`
  font-size: 1.25em;
`;

export let SubHeader1 = styled.h2`
  font-size: 1.953em;
`;

export let SubHeader2 = styled.h2`
  font-size: 1.563em;
`;

export let Subheader3 = styled.h2`
  font-size: 1.25em;
`;

export let BodyText1 = styled.p`
  font-size: 1em;
`;

export let BodyText2 = styled.p`
  font-size: 0.8em;
`;
