import React, { useState, useRef, useEffect } from "react";
import css from "@emotion/css/macro";
import styled from "@emotion/styled/macro";

import { history } from "../lib";
import { stop } from "../util";
import { Button } from "./ui-kit";
import CaseRevealer from "./CaseRevealer";
import { useTranslation } from "react-i18next";

export default props => {
  let ref = useRef();
  let [{ reveal, width, top, left }, setState] = useState({ reveal: false });
  const { t, i18n } = useTranslation("cases");
  useEffect(() => {
    let timeout;
    if (window.matchMedia("(max-width: 500px)").matches) {
      timeout = setTimeout(() => {
        goToCase();
      }, 500);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  let goToCase = e => {
    props.onNavigateToCase();
    if (e) {
      e.preventDefault();
    }

    let { width, top, left } = ref.current.getBoundingClientRect();

    setState({ top, left, width, reveal: true });

    setTimeout(() => {
      history.navigate(`/case/${props.path}`);
    }, 800);
  };

  return (
    <OverviewWrapper
      color={props.mainColor}
      fadeOut={props.fadeOut}
      reveal={reveal}
      onClick={stop}
      ref={ref}
    >
      <h2>{t(props.title)}</h2>
      <Button light as="a" noBorder onClick={goToCase}>
        {t("common.see-project")}
      </Button>
      <div className="desc">
        <h3>{t("common.overview")} </h3>
        <p style={{ paddingTop: 1 }}>{t(props.overview)}</p>
      </div>
      {props.quote && (
        <>
          {/* <hr /> */}
          <div className="quote">
            <p>{t(props.quote)}</p>
            <span>{t(props.quoter)}</span>
          </div>
        </>
      )}
      {reveal && <CaseRevealer size={width} top={top} left={left} />}
    </OverviewWrapper>
  );
};

let OverviewWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 3.7em;
  background: #fff;
  color: #3d3d3d;
  font-size: 0.5em;
  line-height: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-rendering: geometricPrecision;

  ${({ reveal }) =>
    reveal
      ? css`
          > * {
            animation: fadeout 0.3s ease forwards !important;
          }

          @media (max-width: 500px) {
            > * {
              opacity: 0 !important;
              animation: none !important;
            }
          }
        `
      : ""}

  @keyframes fadeout {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  p,
  h2 {
    margin: 0;
  }

  h2 {
    color: ${({ color }) => color};
    font-size: 5em;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-50%);
    animation: ${({ fadeOut }) =>
      fadeOut ? "leave 0.5s ease-in forwards" : "appear 0.5s 0.5s ease-in forwards"};
    opacity: ${({ fadeOut }) => (fadeOut ? 1 : 0)};
  }

  @keyframes appear {
    to {
      opacity: 1;
    }
  }

  @keyframes leave {
    to {
      opacity: 0;
    }
  }

  ${Button} {
    margin-left: auto;
    font-size: 1em;
  }

  .desc {
    h3 {
      margin-bottom: 1em;
    }

    p {
      height: 9.2em;
      overflow-y: hidden;
    }
  }

  hr {
    width: 100%;
    font-size: 2px;
  }

  .quote {
    p {
      margin-bottom: 1em;
      font-style: italic;
    }

    span {
      font-weight: 700;
    }
  }

  @media (max-width: 500px) {
    > * {
      opacity: 0 !important;
    }

    h2 {
      animation: none !important;
    }
  }
`;
