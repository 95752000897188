import React from "react";
import styled from "@emotion/styled/macro";
import { history } from "../../../lib";
import { Section } from "../../../components";
import { SectionText, SectionDescription, SectionBelow } from "../ui-kit";
import { ReactComponent as BA1 } from "../../../assets/product/digitalCurrency/pc.svg";
import { ReactComponent as BA2 } from "../../../assets/product/trustProvider/trustProviderMain.svg";
import { ReactComponent as BA3 } from "../../../assets/product/furtherPay.svg";
import { ReactComponent as ProductsMain } from "../../../assets/product/productMain.svg";
import { useTranslation, Trans } from "react-i18next";

const Products = () => {
  const { t, i18n } = useTranslation("products");

  const handleNavigate = path => {
    history.navigate(`/products/${path}`);
  };

  return (
    <Prods>
      <CurrencyHeader>
        <Section title={t("title")} id="product">
          <SectionText id="section-text">
            <SectionDescription>
              <Trans t={t} i18nKey="description">
                Blockchain Development Suites & Digital Products
              </Trans>
            </SectionDescription>
          </SectionText>
        </Section>
        <SvgMain>
          <ProductsMain />
        </SvgMain>
      </CurrencyHeader>
      <Title>
        <Trans t={t} i18nKey="checkOurPortfolio">
          Check our portfolio
        </Trans>
      </Title>
      <BA>
        <BASection>
          <BA1 />
          <BATitle>
            <Trans t={t} i18nKey="digitalCurrencyConnect">
              Cryptum
            </Trans>
          </BATitle>
          <BADesc>
            <Trans t={t} i18nKey="dccDescription">
              Digital transformation of your business in a simple and cost-efficient way.
            </Trans>
          </BADesc>
          <BAButton onClick={() => handleNavigate(t("dccLink"))}>
            <Trans t={t} i18nKey="learnMore">
              Learn More
            </Trans>
          </BAButton>
        </BASection>
        <BASection>
          <BA2 />
          <BATitle>
            <Trans t={t} i18nKey="trustProvider">
              ChainGrid
            </Trans>
          </BATitle>
          <BADesc>
            <Trans t={t} i18nKey="trustProviderDescription">
              Traceability Framework designed to provide transparency, efficiency and security to
              any kind of transaction record.
            </Trans>
          </BADesc>
          <BAButton onClick={() => handleNavigate(t("traceabilityLink"))}>
            <Trans t={t} i18nKey="learnMore">
              Learn More
            </Trans>
          </BAButton>
        </BASection>
        {/* <BASection>
          <BA3 />
          <BATitle>
            <Trans t={t} i18nKey="furtherPay">
              Further Pay
            </Trans>
          </BATitle>
          <BADesc>
            <Trans t={t} i18nKey="furtherPayDescription">
              Quick and accessible tools to expand revenue lines in your business.
            </Trans>
          </BADesc>
          <BAButton onClick={() => handleNavigate(`further-pay`)}>
            <Trans t={t} i18nKey="learnMore">
              Learn More
            </Trans>
          </BAButton>
        </BASection> */}
      </BA>
    </Prods>
  );
};

const BA = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  margin-bottom: 50px;

  @media screen and (min-width: 800px) {
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-bottom: 160px;
  }
`;

const BAButton = styled.button`
  width: 100%;
  height: 55px;
  margin-top: 30px;

  background-color: #000;
  border: 2px solid #7e91b5;
  color: #7e91b5;

  border-radius: 5px;

  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 800px) {
    max-width: 226px;
  }
`;

const BASection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 50px;

  > svg {
    max-height: 155px;
    width: 100%;
    max-width: 350px;
    margin-bottom: 30px;
  }

  @media screen and (min-width: 800px) {
    margin-right: 50px;
    margin: 0;
    width: 31%;
    svg {
      height: 155px;
    }
  }
`;

const BATitle = styled.div`
  font-size: 28px;
  font-weight: 600;
  color: #fff;
  line-height: 37px;
`;

const BADesc = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  margin-top: 16px;
  height: 100%;
  color: #fff;

  @media screen and (min-width: 800px) {
    height: 88px;
  }
`;

const Prods = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-size: 16px;
  line-height: 1.625px;
  letter-spacing: 1.5;
  text-transform: uppercase;
  color: #7e90b4;
  font-weight: 600;
  margin: 0;
  margin-top: 0;
  margin-bottom: 16px;
`;

const CurrencyHeader = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  margin-bottom: 70px;

  @media screen and (min-width: 800px) {
    margin-bottom: 20px;
    flex-direction: row;
  }

  #section-text {
    color: #fff !important;
    max-width: 842px;

    #section-bellow {
      font-size: 19px;
      line-height: 37px;
    }
  }
`;

const SvgMain = styled.div`
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
    padding-top: 45px;
    padding: 0 32px;
    @media screen and (min-width: 800px) {
      margin: 0 0 0 85px;
      max-height: 404px;
      max-width: 400px;
    }
  }
`;

export default Products;
