import styled from "@emotion/styled";
import React from "react";
import { useTranslation } from "react-i18next";

const CMS_URL = "https://cms.blockforce.in";

export function PostGrid({ posts, lang }) {
  const strippedLang = lang.slice(0, 2);
  return (
    <GridContainer>
      {posts.map(post => (
        <PostItem post={post} lang={strippedLang} />
      ))}
    </GridContainer>
  );
}

export function PostItem({ post, lang }) {
  const url = post["imagem"][0] ? post["imagem"][0].url : "";
  const date = new Date(post["created_at"]);

  const { t } = useTranslation("blog");
  return (
    <PostContainer>
      <ImageContainer>
        <img src={`${CMS_URL}${url}`} width="404" />
      </ImageContainer>
      <PostDate>{`${post["categorias"].map(categoria => categoria["Nome"]).join(", ")}`}</PostDate>
      <PostTitle>{post[`titulo_${lang}`]}</PostTitle>
      <PostDescription>{post[`descricao_${lang}`]}</PostDescription>
      <ReadMore href={`/blog/${lang}/${post[`seo_url_${lang}`]}`}>{t("read-more")}</ReadMore>
    </PostContainer>
  );
}

const ImageContainer = styled.figure`
  width: 464px;
  height: 250px;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 24px;
  @media screen and (max-width: 883px) {
    width: 100%;
    height: 200px;
  }
`;
const PostContainer = styled.article`
  display: flex;
  flex-direction: column;
  width: 464px;
  padding: 15px;
  @media screen and (max-width: 883px) {
    padding: 0 0 12px;
    width: 100%;
  }
`;
const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 80px;
`;
const PostDate = styled.span`
  color: #00cc99;
  font-weight: bold;
  font-size: 14px;
`;
const PostTitle = styled.h4`
  margin: 0;
  font-weight: 600;
  padding-right: 24px;
`;
const PostDescription = styled.p`
  font-size: 14px;
  margin-top: 8px;
`;
const ReadMore = styled.a`
  font-size: 14px;
  font-weight: 600;
  color: #00cc99;
  text-decoration: underline;
  cursor: pointer;
`;
