import styled from "@emotion/styled";
import React, { useState, useEffect } from "react";
import { Footer, Logo, Nav, Wrapper } from "../../components";
import { PostGrid } from "../Blog/components/post";
import Contact from "../Home/sections/Contact";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";
import { navigate } from "@reach/router";
import { Authors } from "../Blog/components/authors";
import share_icon from "../../assets/share-icon.png";
import { FacebookShareButton, LinkedinShareButton, LinkedinIcon, FacebookIcon } from "react-share";

const CMS_URL = "https://cms.blockforce.in";
export default function BlogPost({ seo_url, lang }) {
  const { t, i18n } = useTranslation("blog");
  const [posts, setPosts] = useState([]);
  const [post, setPost] = useState(null);
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    fetchPost().then(([post]) => {
      setPost(post);
      const [category] = post["categorias"];
      const categoryName = category["Nome"];

      fetchPosts(categoryName).then(posts => {
        if (posts) setPosts(posts);
      });
    });

    // fetchPosts().then(posts => {
    //   setPost(posts.find(post => post[`seo_url_${lang}`] === seo_url));
    //   setPosts(posts.filter(post => post[`seo_url_${lang}`] !== seo_url).slice(0, 3));
    // });
  }, []);
  useEffect(() => {
    if (i18n.isInitialized) {
      const sLang = i18n.language.slice(0, 2);

      if (lang !== sLang) {
        i18n.changeLanguage(lang);
        if (post) {
          navigate(`../${sLang}/${post[`seo_url_${sLang}`]}`, {});
          window.location.reload();
        }
      }
    }
  }, [i18n.isInitialized, i18n.language]);

  const fetchPost = async () => {
    const res = await fetch(`${CMS_URL}/posts?seo_url_${lang}=${seo_url}`);
    return await res.json();
  };
  const fetchPosts = async categoryName => {
    const qs = categoryName
      ? `&_where[categorias.Nome]=${encodeURIComponent(
          categoryName
        )}&_where[seo_url_${lang}_ne]=${seo_url}`
      : "";
    const res = await fetch(`${CMS_URL}/posts?_limit=3&_start=${0}${qs}`);

    return await res.json();
  };

  const handleCategory = category => {
    navigate(`../../blog?category=${encodeURIComponent(category)}`, {});
    window.location.reload();
  };
  const handleBack = () => {
    navigate("/blog");
    window.location.reload();
  };

  const handleShare = () => {
    var dummy = document.createElement("input"),
      text = window.location.href;

    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    setCopied(true);
  };
  return (
    <BlogPostContainer>
      <Wrapper>
        <LogoMargin>
          <Logo light={true} />
        </LogoMargin>
      </Wrapper>
      <Nav light={true} />
      <PostContainer>
        <BackButton onClick={handleBack}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="85.323"
            height="22.639"
            viewBox="0 0 85.323 22.639"
          >
            <g
              id="Grupo_361"
              data-name="Grupo 361"
              transform="translate(-311.382 1783.657) rotate(-90)"
            >
              <line
                id="Linha_67"
                data-name="Linha 67"
                y1="83.908"
                transform="translate(1772.337 312.797)"
                fill="none"
                stroke="#7e91b5"
                strokeMiterlimit="10"
                strokeWidth="2"
              />
              <path
                id="Caminho_730"
                data-name="Caminho 730"
                d="M1782.95,323.409,1772.337,312.8l-10.612,10.612"
                fill="none"
                stroke="#7e91b5"
                strokeMiterlimit="10"
                strokeWidth="2"
              />
            </g>
          </svg>
        </BackButton>
        <ArticleContainer>
          {post && i18n.language && (
            <>
              <figure>
                <img src={`${CMS_URL}${post["imagem"][0].url}`} width="500" />
              </figure>
              <Post>
                <PostTitle>{post[`titulo_${lang}`]}</PostTitle>
                <ReactMarkdown
                  transformImageUri={uri => (uri.includes("http") ? uri : `${CMS_URL}${uri}`)}
                  children={post[`conteudo_${lang}`]}
                />
                <BlogCategoryContainer>
                  {post["categorias"].map(category => (
                    <Category onClick={() => handleCategory(category["Nome"])} key={category.id}>
                      {category["Nome"]}
                    </Category>
                  ))}
                </BlogCategoryContainer>
                <PostFooter>
                  <Authors post={post} />
                  <ShareContainer>
                    {copied && <p>{t("copied")}</p>}

                    <LinkedinShareButton title={post[`titulo_${lang}`]} url={window.location.href}>
                      <LinkedinIcon
                        size="32"
                        bgStyle={{ fill: "#ffffff", height: 32 }}
                        iconFillColor="#000000"
                      />
                    </LinkedinShareButton>
                    <ShareIcon onClick={handleShare} src={share_icon} width="32" height="32" />
                  </ShareContainer>
                </PostFooter>
              </Post>
            </>
          )}
        </ArticleContainer>
        <PostGrid lang={lang} posts={posts} />
      </PostContainer>
      <Contact dark={true} lightToast={true} />
      <Footer dark={true} />
    </BlogPostContainer>
  );
}

const ShareContainer = styled.div`
  display: flex;
  font-size: 16px;
  align-items: center;
  button {
    height: 32px;
  }
`;
const ShareIcon = styled.img`
  cursor: pointer;
`;
const PostFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 24px;
  @media screen and (max-width: 800px) {
    justify-content: flex-start;
    flex-direction: column;
    align-items: start;
  }
`;
const BlogPostContainer = styled.div`
  background: #fff;
  color: #000;
`;
const Post = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  width: 900px;
  text-align: justify;
  align-items: flex-start;

  > h1 {
    width: 100%;
    text-align: center;
  }
  > h2 {
    color: #00cc99;
  }
  > img {
    max-width: 900px;
    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;
const PostContainer = styled.div`
  margin: 80px auto;
  padding: 0 48px;
  max-width: 1496px;
  *::selection {
    color: black;
    background: white;
  }

  @media screen and (max-width: 800px) {
    padding: 0 24px;
  }
`;
const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  align-items: center;
`;
const LogoMargin = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem 0 0 0;
`;
const PostTitle = styled.h1`
  font-weight: 600;
  font-size: 2.5em;
  margin-bottom: 0;
  @media screen and (max-width: 800px) {
    font-size: 1.5em;
  }
`;

const BlogCategoryContainer = styled.div`
  display: flex;
  align-self: start;
`;
const Category = styled.div`
  color: #7e91b5;
  text-transform: uppercase;
  margin-right: 24px;
  padding: 16px;
  border: 1px solid #7e91b5;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: #000;
    border-color: #000;
  }
`;

const BackButton = styled.div`
  margin-left: 24px;
  cursor: pointer;
  margin-bottom: 40px;
  @media screen and (max-width: 800px) {
    margin-left: 0px;
  }
`;
