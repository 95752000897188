import React, { useState, useEffect } from "react";
import styled from "@emotion/styled/macro";
import NormalCasesCarousel from "../../components/NormalCasesCarousel/NormalCasesCarousel";

const CarouselCase = props => {
  const [cases, setCases] = useState(false);
  useEffect(() => {
    if (props.cases) {
      setCases(props.cases);
    }
  }, []);

  return (
    cases && (
      <PressInit>
        <CarouselWrapper>
          <NormalCasesCarousel blackButton={props.blackButton} cases={cases} />
        </CarouselWrapper>
      </PressInit>
    )
  );
};

let PressInit = styled.div`
  width: 100%;
  height: 100%;
`;

let CarouselWrapper = styled.div`
  position: relative;
  max-width: 1549px;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  margin: 0 auto;
  .flickity-enabled:focus {
    outline: none;
  }
  @media (max-width: 500px) {
    padding: 0px;
  }
`;

export default CarouselCase;
