import React, { useState, useEffect } from "react";
import styled from "@emotion/styled/macro";

import { useStore, history } from "../lib";

import { MenuIcon } from "./graphics";
import { Wrapper } from "./ui-kit";
import { Reveal } from "../components";
import ResponsiveMenu from "./ResponsiveMenu";
import { useTranslation, Trans } from "react-i18next";
import LanguageToggle from "./LanguageToggle";
import { navigate } from "@reach/router";

let mapState = s => ({ menuOpened: s.menuOpened, firstView: s.firstView });
let mapDispatch = d => ({ toggleMenu: () => d({ type: "TOGGLE_MENU" }) });

export default ({ color, revealDelay, absolute, light }) => {
  let { menuOpened, firstView, toggleMenu } = useStore(mapState, mapDispatch);
  let [visible, setVisible] = useState(true);

  const { t, i18n } = useTranslation("common");
  const handleLanguage = lang => {
    i18n.changeLanguage(lang);
  };
  useEffect(() => {
    if (i18n.isInitialized) {
      const { pathname } = history.location;
      if (pathname === "/" || pathname === "/en" || pathname === "/pt") {
        navigate(`${i18n.language.slice(0, 2)}`, {});
      }
    }
  }, [i18n.isInitialized, i18n.language]);

  useEffect(() => {
    const currentTime = new Date();
    if (localStorage.getItem("accessedOn")) {
      const lastAccess = new Date(localStorage.getItem("accessedOn"));
      const timeDelta = currentTime - lastAccess;
      const showBannerAfterMiliseconds = 20 * 60 * 1000;
      if (timeDelta < showBannerAfterMiliseconds) {
        setVisible(false);
      }
    }

    localStorage.setItem("accessedOn", currentTime);
  }, []);

  return (
    <NavWrapper
      opened={menuOpened}
      color={color}
      light={light}
      delay={revealDelay != null ? revealDelay : firstView ? 3000 : 0}
      absolute={absolute}
    >
      {visible && (
        <Reveal delay={4000}>
          <BannerWrapper>
            <CloseButton onClick={() => setVisible(false)}>&#10006;</CloseButton>
            <p>{t("desviralize-modal.title")}</p>
            <BannerText>
              <Trans t={t} i18nKey="desviralize-modal.access">
                Access
              </Trans>
              <Link href="https://desviralize.org/">desviralize.org </Link>
              <Trans t={t} i18nKey="desviralize-modal.participate">
                and participate!
              </Trans>
            </BannerText>
          </BannerWrapper>
        </Reveal>
      )}
      <LanguageToggle light={light} lang={i18n.language} onClickLanguage={handleLanguage} />
      <MenuIcon onClick={toggleMenu} active={menuOpened} />
      <ResponsiveMenu opened={menuOpened} toggle={toggleMenu} />
    </NavWrapper>
  );
};

let NavWrapper = styled(Wrapper)`
  position: ${({ absolute }) => (absolute ? "absolute" : "fixed")};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 0;
  top: -5px;
  left: 50%;
  transform: translate(-50%, 64px);
  opacity: 0;
  animation: revealButton 0.5s ease-in forwards;
  animation-delay: ${({ delay = 3000 }) => delay}ms;
  z-index: 1000;

  @keyframes revealButton {
    to {
      opacity: 1;
    }
  }

  svg {
    width: 24px;

    line {
      stroke: ${({ opened, light }) => (opened ? "#707070" : light ? "#000000" : "#fff")};
      transition: stroke 0.2s linear;
    }
  }
`;

let BannerWrapper = styled.div`
  position: fixed;
  top: 32px;
  right: 40px;
  background: #615f5f69;
  color: #ff6501;
  font-weight: bold;
  padding: 6px 32px 6px 32px;
  z-index: 1000;
  border-radius: 8px;

  z-index: 1050;

  .fadeIn {
    animation: fadeInCube 5s ease-in;
    animation-iteration-count: 1;
    animation-duration: 5s;
  }

  @keyframes fadeInCube {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media (max-width: 500px) {
    top: 32px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
  }

  p:first-of-type {
    margin-bottom: 6px;
    font-weight: 500;
    text-transform: uppercase;
  }

  opacity: 0;
  animation: reveal 0.5s ease-in forwards;
  animation-delay: 1200ms;
  @keyframes reveal {
    to {
      opacity: 1;
    }
  }
`;

let Link = styled.a`
  text-decoration: underline;
`;

let CloseButton = styled.button`
  background: transparent;
  outline: none;
  border: none;
  color: #fff;
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 14px;
  font-size: 16px;
  opacity: 0.87;

  &:hover {
    opacity: 1;
  }
`;

const BannerText = styled.p`
  margin-top: 0px;
`;
