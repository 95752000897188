import React from "react";
import styled from "@emotion/styled/macro";
import { Wrapper } from "./ui-kit";
import { SocialLinks } from "./SocialLinks";

export default props => (
  <React.Fragment>
    <FooterWrapper as="footer" dark={props.dark}>
      <div className="right">
        <SocialLinks dark={props.dark} />
      </div>
      <div className="left">
        {/* <a href="tel:+552121432739">Phone: +55 21 2143 2739</a> */}
        <a href="mailto:hello@blockforce.in">hello@blockforce.in</a>
      </div>
    </FooterWrapper>
    <ColouredLine></ColouredLine>
  </React.Fragment>
);

export let ColouredLine = styled.div`
  width: 100%;
  height: 5px;

  background: rgba(102, 51, 204, 1);
  background: -moz-linear-gradient(left, rgba(102, 51, 204, 1) 0%, rgba(0, 204, 153, 1) 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(102, 51, 204, 1)),
    color-stop(100%, rgba(0, 204, 153, 1))
  );
  background: -webkit-linear-gradient(left, rgba(102, 51, 204, 1) 0%, rgba(0, 204, 153, 1) 100%);
  background: -o-linear-gradient(left, rgba(102, 51, 204, 1) 0%, rgba(0, 204, 153, 1) 100%);
  background: -ms-linear-gradient(left, rgba(102, 51, 204, 1) 0%, rgba(0, 204, 153, 1) 100%);
  background: linear-gradient(to right, rgba(102, 51, 204, 1) 0%, rgba(0, 204, 153, 1) 100%);

  ${
    "" /* background: rgb(48,255,144);
  background: -moz-linear-gradient(left,  rgba(48,255,144,1) 0%, rgba(237,45,237,1) 25%, rgba(201,152,38,1) 50%, rgba(48,255,230,1) 75%, rgba(48,255,144,1) 100%); 
  background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(48,255,144,1)), color-stop(25%,rgba(237,45,237,1)), color-stop(50%,rgba(201,152,38,1)), color-stop(75%,rgba(48,255,230,1)), color-stop(100%,rgba(48,255,144,1)));
  background: -webkit-linear-gradient(left,  rgba(48,255,144,1) 0%,rgba(237,45,237,1) 25%,rgba(201,152,38,1) 50%,rgba(48,255,230,1) 75%,rgba(48,255,144,1) 100%); 
  background: -o-linear-gradient(left,  rgba(48,255,144,1) 0%,rgba(237,45,237,1) 25%,rgba(201,152,38,1) 50%,rgba(48,255,230,1) 75%,rgba(48,255,144,1) 100%); 
  background: -ms-linear-gradient(left,  rgba(48,255,144,1) 0%,rgba(237,45,237,1) 25%,rgba(201,152,38,1) 50%,rgba(48,255,230,1) 75%,rgba(48,255,144,1) 100%); 
  background: linear-gradient(to right,  rgba(48,255,144,1) 0%,rgba(237,45,237,1) 25%,rgba(201,152,38,1) 50%,rgba(48,255,230,1) 75%,rgba(48,255,144,1) 100%); 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#30ff90', endColorstr='#30ff90',GradientType=1 ); */
  }

  ${"" /* animation: rotate 3s infinite linear; */}

  ${
    "" /* @keyframes rotate {
    from {
      background-position: -3000px;
    }
    to {
      background-position: 0px;
    }
  } */
  }
`;

export let FooterWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 200px;

  a {
    transition: color 0.3s ease-in-out;
    align-items: center;
    display: flex;

    font-size: 1rem;
    font-weight: 700;
    color: ${props => (props.dark ? "#000" : "#7e91b5")};
    text-transform: uppercase;

    &:hover {
      color: #5cadff;
    }
  }

  svg {
    width: 16px;
  }

  .left,
  .right {
    display: flex;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 46px;

    a {
      font-size: 1rem;
      font-weight: 700;
      color: ${props => (props.dark ? "#000" : "#7e91b5")};
      text-transform: uppercase;
    }

    a + a,
    svg {
      margin-left: 0;
    }

    .left,
    .right {
      margin: 16px 0;
    }

    .left {
      flex-direction: column;
      margin-top: 0;
    }

    .right {
      justify-content: space-between;
    }
  }
`;
