import React, { useEffect, useRef } from "react";
import anime from "animejs";
import { useInView } from "react-intersection-observer";

import Svg from "./svg";
import { getPathPosition } from "../../util";
import { usePointer } from "../../lib";

let isFF = /firefox/gi.test(navigator.userAgent);

let getRotation = (x, y) => {
  let xCenter = window.innerWidth / 2;
  let yCenter = window.innerHeight / 2;
  let xDistance = xCenter - x;
  let yDistance = yCenter - y;
  let ratio = yCenter / xCenter;
  let xAxis = x == null ? 0 : -(xDistance / xCenter / 100) / ratio;
  let yAxis = y == null ? 0 : yDistance / yCenter / 100;

  return `rotateX(${yAxis}turn) rotateY(${xAxis}turn)`;
};

export default function Hexagon({ firstView, setFirstView }) {
  let svg = useRef();
  let inView = useInView(svg);
  let { x, y } = usePointer(
    !firstView && inView && window.matchMedia("(min-width: 800px)").matches
  );

  useEffect(() => {
    if (firstView) {
      setTimeout(runTimeline, 100);
    }
  }, [firstView]);

  useEffect(() => {
    if (!firstView) {
      let { current } = svg;
      let shadow = current.querySelector(".shadow");
      let svgEl = current.querySelector(".main-svg");
      let gradient = current.querySelector(".gradient-svg");
      let rotation = getRotation(x, y);

      if (!isFF) {
        svgEl.style.transition = shadow.style.transition = gradient.style.transition =
          "transform 0.2s linear";
      }
      svgEl.style.transformStyle = shadow.style.transformStyle = gradient.style.transitionStyle =
        "preserve-3d";
      svgEl.style.transform = rotation;
      shadow.style.transform = `${rotation} translateZ(-32px) scale(1.2)`;
      gradient.style.transform = `${rotation} translateZ(32px) scale(0.9)`;
    }
  }, [x, y, firstView]);

  let runTimeline = () => {
    let { current } = svg;
    let inner = current.querySelectorAll(".inner");
    let outer = current.querySelectorAll(".outer");
    let shadow = current.querySelector(".shadow");
    let central = current.querySelector(".central");
    let rainbow = current.querySelector(".rainbow");

    let timeline = anime.timeline({
      complete: () => {
        setFirstView();
      }
    });

    let rainbowPos = getPathPosition(rainbow, 0);

    timeline
      .add({
        targets: central,
        opacity: [0, 1, 0, 1, 0, 1, 0, 1],
        duration: 500
      })
      .add({
        targets: current,
        translateY: 0,
        duration: 200,
        easing: "easeInQuad"
      })
      .add({
        targets: rainbow,
        translateX: [rainbowPos.x, 0],
        translateY: [rainbowPos.y, 0],
        scale: [0, 1],
        opacity: 1,
        duration: 300,
        easing: "easeInOutSine"
      });

    [...inner].forEach(path => {
      let initialPos = getPathPosition(path, 0);

      timeline.add({
        targets: path,
        translateX: [initialPos.x, 0],
        translateY: [initialPos.y, 0],
        scale: [0, 1],
        opacity: 1,
        delay: -150,
        duration: 300,
        easing: "easeInSine"
      });
    });

    [...outer].forEach((path, i) => {
      let initialPos = getPathPosition(path, 0);

      timeline.add({
        targets: path,
        translateX: [initialPos.x, 0],
        translateY: [initialPos.y, 0],
        scale: [0, 1],
        opacity: 1,
        duration: 300,
        offset: 1650,
        easing: "easeInSine"
      });
    });

    timeline.add({
      targets: shadow,
      opacity: 0.5,
      duration: 1000,
      easing: "easeInQuad"
    });
  };

  return (
    <Svg
      animate={firstView}
      hostRef={c => {
        svg.current = c;
      }}
    />
  );
}
