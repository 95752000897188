import React from "react";
import {
  Reveal,
  Wrapper,
  Logo,
  Footer,
  SectionTitle,
  HeaderText3,
  HeaderText5,
  HeaderText6,
  SubHeader2
} from "../../components";
import styled from "@emotion/styled/macro";
import { history } from "../../lib";
import wavesBG from "../../assets/vision_bg.png";
import cultureImg from "../../assets/culture_element.png";
import impactImg from "../../assets/impact_image.png";
import sistemaB from "../../assets/sistema-b.png";
import gptw from "../../assets/gptw.png";
import Contact from "../Home/sections/Contact";
import { ReactComponent as ArrowBack } from "../../assets/team/arrow-back.svg";
import { useTranslation, Trans } from "react-i18next";

export default function Vision() {
  const { t } = useTranslation("vision");

  const culturalValues = [
    {
      name: t("ourVision.item1.name"),
      text: t("ourVision.item1.text")
    },
    {
      name: t("ourVision.item2.name"),
      text: t("ourVision.item2.text")
    },
    {
      name: t("ourVision.item3.name"),
      text: t("ourVision.item3.text")
    },
    {
      name: t("ourVision.item4.name"),
      text: t("ourVision.item4.text")
    },
    {
      name: t("ourVision.item5.name"),
      text: t("ourVision.item5.text")
    },
    {
      name: t("ourVision.item6.name"),
      text: t("ourVision.item6.text")
    }
  ];

  const getCultureCards = (initialIndex, numberOfCards) => {
    let cards = [];
    for (let i = 0; i < numberOfCards; i++) {
      cards.push(
        <CultureCard key={`CultureCard${i}${culturalValues[initialIndex + i].title}`}>
          <CultureCardTitle key={`CultureCardTitle${i}${culturalValues[initialIndex + i].title}`}>
            {culturalValues[initialIndex + i].name}
          </CultureCardTitle>
          <CultureCardText key={`CultureCardText${i}${culturalValues[initialIndex + i].title}`}>
            {culturalValues[initialIndex + i].text}
          </CultureCardText>
        </CultureCard>
      );
    }
    return cards;
  };

  return (
    <div>
      <Reveal>
        <WavesBG
          style={{
            position: "absolute",
            height: "100%",
            backgroundImage: `url(${wavesBG})`,
            marginBottom: 0,
            width: "100vw",
            zIndex: 0,
            marginTop: 160
          }}
        />
        <Wrapper>
          <LogoMargin>
            <Logo light={false} />
          </LogoMargin>
          <ArrowContainer>
            <ArrowBack onClick={history.back} />
          </ArrowContainer>
          <ServiceHeading>
            <HeadingContent>
              <SectionTitle style={{ fontSize: "18px" }}>
                <Trans t={t} i18nKey="ourVision.title">
                  Our Vision
                </Trans>
              </SectionTitle>
              <ShowOnLG>
                <HeadingTitle>
                  <Trans t={t} i18nKey="ourVision.head">
                    We believe in the blockchain power to create new <br />
                    collaborative realities of trust as a key to <br />
                    accelerate the exponential positive
                    <br /> transformations.
                  </Trans>
                </HeadingTitle>
                <TabDescription>
                  <Trans t={t} i18nKey="ourVision.description">
                    We understand blockchain as a new market transversal infrastructure of
                    <br /> transaction, boosting trust, transparency and integration in the
                    relationship
                    <br />
                    between different social business stakeholders.
                  </Trans>
                </TabDescription>
              </ShowOnLG>
              <ShowOnMD>
                <HeadingTitle style={{ fontSize: 18, fontWeight: 600 }}>
                  <Trans t={t} i18nKey="ourVision.mobileHead">
                    We believe in the blockchain power to create new collaborative realities of
                    trust as a key to accelerate the exponential positive transformations.
                  </Trans>
                </HeadingTitle>
                <TabDescription style={{ fontSize: 16, fontWeight: 300 }}>
                  <Trans t={t} i18nKey="ourVision.mobileDescription">
                    We understand blockchain as a new market transversal infrastructure of
                    transaction, boosting trust, transparency and integration in the relationship
                    between different social business stakeholders.
                  </Trans>
                </TabDescription>
              </ShowOnMD>
            </HeadingContent>
          </ServiceHeading>
          <HeadingContent>
            <SectionTitle style={{ fontSize: "18px" }}>
              <Trans t={t} i18nKey="ourVision.ourCulture">
                Our Culture
              </Trans>
            </SectionTitle>
            <ShowOnLG>
              <CultureText>
                <Trans t={t} i18nKey="ourVision.ourCultureDescription">
                  We pulse and organize ourselves as a blockchain network, based on <br />
                  collaboration, distribution, autonomy, trust and self-responsibility.
                </Trans>
              </CultureText>
            </ShowOnLG>
            <ShowOnMD>
              <CultureText style={{ fontSize: "19px", fontWeight: 600, marginBottom: 54 }}>
                <Trans t={t} i18nKey="ourVision.ourCultureMobileDescription">
                  We pulse and organize ourselves as a blockchain network, based on collaboration,
                  distribution, autonomy, trust and self-responsibility.
                </Trans>
              </CultureText>
            </ShowOnMD>
            <CultureWrapper>
              <CultureCardColumn>{getCultureCards(0, 3)}</CultureCardColumn>
              <CultureElement src={cultureImg} />
              <CultureCardColumn>{getCultureCards(3, 3)}</CultureCardColumn>
            </CultureWrapper>
          </HeadingContent>
          <HeadingContent>
            <SectionTitle style={{ fontSize: "18px" }}>
              <Trans t={t} i18nKey="ourVision.impact">
                IMPACT
              </Trans>
            </SectionTitle>
            <ShowOnLG>
              <ImpactWrapper>
                <ImpactContent>
                  <div>
                    <ImpactHeadText>
                      <Trans t={t} i18nKey="ourVision.impactHeadText">
                        Work in accordance with the SDGs in order to encourage
                        <br /> companies, governments and other civil society organizations to
                        <br /> operate in a distributed, collaborative and conscious manner.
                      </Trans>
                    </ImpactHeadText>
                    <BadgesContainer>
                      <SistemaB src={sistemaB} />
                      <GPTW src={gptw} />
                    </BadgesContainer>
                  </div>
                  {/* <ImpactSubText>
                    <Trans t={t} i18nKey="ourVision.impactSubText">
                      By the technical characteristic of the business, perceiving the blockchain as
                      a new infrastructure of the transaction, we also signed the 9 among our
                      principals. And to conclude the triad, as a major objective
                      <br /> and focal field for Blockforce, we have the SDG 11.
                    </Trans>
                  </ImpactSubText>
                  <ImpactSubText>
                    <Trans t={t} i18nKey="ourVision.impactSecondSubText">
                      All others are reflections of the activities that we empower and <br />
                      propel from the creation of projects and the use of products.
                    </Trans>
                  </ImpactSubText> */}
                </ImpactContent>
                <ImpactImageWrapper>
                  <ImpactImage src={impactImg} />
                </ImpactImageWrapper>
              </ImpactWrapper>
            </ShowOnLG>
            <ShowOnMD>
              <ImpactWrapper>
                <ImpactContent>
                  <div>
                    <ImpactHeadText style={{ fontSize: 19 }}>
                      <Trans t={t} i18nKey="ourVision.impactHeadTextMobile">
                        Work in accordance with the SDGs
                        <br /> in order to encourage companies,
                        <br /> governments and other civil society
                        <br /> organizations to operate in a
                        <br /> distributed, collaborative and
                        <br /> conscious manner.
                      </Trans>
                    </ImpactHeadText>
                    <BadgesContainer>
                      <SistemaB src={sistemaB} />
                      <GPTW src={gptw} />
                    </BadgesContainer>
                  </div>
                  <ImpactImageWrapper
                    style={{
                      display: "flex",
                      marginTop: 28,
                      marginBottom: 24,
                      justifyContent: "center"
                    }}
                  >
                    <ImpactImage style={{ padding: 0 }} src={impactImg} />
                  </ImpactImageWrapper>
                  <ImpactSubText style={{ fontSize: 16, fontWeight: 300 }}>
                    <Trans t={t} i18nKey="ourVision.impactSubTextMobile">
                      By the technical characteristic of the
                      <br /> business, perceiving the blockchain as a new
                      <br /> infrastructure of the transaction, we also
                      <br /> signed the 9 among our principals. And to
                      <br /> conclude the triad, as a major objective and
                      <br /> focal field for Blockforce, we have the SDG
                      <br /> 11.
                    </Trans>
                  </ImpactSubText>
                  <ImpactSubText style={{ fontSize: 16, fontWeight: 300 }}>
                    All others are reflections of the activities that
                    <br />
                    we empower and propel from the creation
                    <br />
                    of projects and the use of products.
                  </ImpactSubText>
                </ImpactContent>
              </ImpactWrapper>
            </ShowOnMD>
          </HeadingContent>
        </Wrapper>
      </Reveal>
      <Contact dark={false} />
      <Footer dark={false} />
    </div>
  );
}
const SistemaB = styled.img`
  width: 80px;
  height: auto;
`;
const BadgesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  justify-content: start;
  align-items: center;
  @media screen and (max-width: 883px) {
    flex-direction: column-reverse;
  }
`;
const GPTW = styled.img``;

const ShowOnLG = styled.div`
  @media screen and (max-width: 883px) {
    display: none;
  }
`;

const ShowOnMD = styled.div`
  @media screen and (min-width: 883px) {
    display: none;
  }
`;

const ImpactHeadText = styled(HeaderText5)`
  font-weight: 700;
  margin-top: 0;
`;

const ImpactSubText = styled.p`
  font-size: 18px;
  font-weight: 400;
`;

const ImpactWrapper = styled.div`
  display: flex;
  flex-direction: row;

  margin-bottom: 50px;
  margin-top: 0;
  @media screen and (max-width: 883px) {
    margin-bottom: 30px;
  }
`;
const ImpactContent = styled.div`
  display: flex;
  flex-direction: column;

  flex: 5;
`;

const ImpactImageWrapper = styled.div`
  flex: 3;
  text-align: center;
`;

const ImpactImage = styled.img`
  width: 100%;
  max-width: 380px;
  max-height: 380px;

  @media screen and (max-width: 800px) {
    width: 280px;
    height: 280px;
  }
`;

const CultureElement = styled.img`
  flex: 1;
  padding: 24px 0px;
  margin-left: 120px;
  margin-right: 120px;

  width: fit-content;
  height: fit-content;
  margin-top: auto;
  margin-bottom: auto;

  max-width: 380px;
  height: auto;

  @media screen and (max-width: 883px) {
    flex-direction: column;
    order: 1;
    margin-left: 56px;
    margin-right: 56px;
    margin-bottom: 38px;
    padding: 0;
    width: 180px;
    height: 180px;

    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const CultureCardColumn = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;

  @media screen and (max-width: 883px) {
    order: 2;
  }
`;

const CultureCardTitle = styled(HeaderText6)`
  font-weight: 600;
  color: #7e91b5;
`;

const CultureCardText = styled.div`
  font-weight: 300;
  font-size: 16px;
`;

const CultureWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 883px) {
    flex-direction: column;
  }
`;

const CultureCard = styled.div`
  border-top: 1px solid #7e91b5;
  padding-bottom: 32px;

  min-height: 180px;
`;

const ServiceHeading = styled.div`
  display: flex;
  flex-direction: columm;

  margin-bottom: 80px;
`;

const LogoMargin = styled.div`
  svg {
    margin: 2rem 0 0 0;
  }
`;

const ArrowContainer = styled.div`
  margin: 30px 0;
  position: relative;
  z-index: 5;

  @media screen and (min-width: 800px) {
    margin: 50px 0 50px;
  }
`;

const HeadingContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  margin-right: 54px;

  @media (max-width: 950px) {
    margin-right: 0;
  }
`;

const HeadingTitle = styled(HeaderText3)`
  font-weight: 600;
  margin-top: 10px;
`;

const TabDescription = styled(SubHeader2)`
  margin-top: 0;
  font-weight: 400;
`;

const CultureText = styled(HeaderText5)`
  margin-top: 0;
  margin-bottom: 92px;
`;

const WavesBG = styled.div`
  background-repeat: no-repeat;
  background-size: contain;
`;
