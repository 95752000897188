import styled from "@emotion/styled/macro";
import React from "react";
import { ReactComponent as ReactLogo } from "../assets/BF_Logo_Hor_Neg.svg";
import { ReactComponent as LightLogo } from "../assets/BF_Logo_Hor_Pos.svg";

export default props => (
  <LogoWrapper viewBox="0 0 280 55" href="/">
    {props.light ? <LightLogo /> : <ReactLogo />}
  </LogoWrapper>
);

export let LogoWrapper = styled.a`
  height: 64px;

  svg {
    width: 300px;
  }

  @media screen and (max-width: 500px) {
    svg {
      width: 175px;
    }
  }
`;
