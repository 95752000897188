import React, { useState, useEffect } from "react";
import styled from "@emotion/styled/macro";
import { ReactComponent as MainSvg } from "../../../assets/splash/mainSvg.svg";
import { Logo, Wrapper } from "../../../components";
import Tilt from "react-tilt";
import { history } from "../../../lib";
import { useTranslation, Trans } from "react-i18next";

export default () => {
  const [fadeInContent, setFadeInContent] = useState(false);
  const { t, i18n } = useTranslation("home");
  const navigateToVision = uri => {
    history.navigate(`/vision`);
  };

  useEffect(() => {
    // setTimeout(function() {
    //   setFadeInContent(true);
    // }, 2000);
  }, []);

  return (
    <Splash>
      <Wrapper>
        <LogoMargin>
          <Logo />
        </LogoMargin>
      </Wrapper>
      <MainContent>
        <SvgContainer style={{ zIndex: 0 }}>
          <Tilt
            className="Tilt"
            options={{ max: 5, scale: 1 }}
            style={{ height: "100%", width: "100%" }}
          >
            <MainSvg alt="blockforce blockchain social innovation inovação social" />
          </Tilt>
        </SvgContainer>
        <Content>
          <Wrapper>
            <ContentInfo fade={fadeInContent}>
              <ContentContainer>
                <MainInfo>
                  <Trans t={t} i18nKey="splash.title">
                    Blockchain for <br /> Social Innovation
                  </Trans>
                </MainInfo>
                <DetailedInfo>
                  <Trans t={t} i18nKey="splash.info">
                    Blockforce is a Blockchain Research & Builder, offering consulting,
                    blockchain-based digital products, and{" "}
                    <MDOnly>
                      <br />
                    </MDOnly>{" "}
                    integrated services of development to empower social, environmental and systemic
                    impact projects at scale.
                  </Trans>
                </DetailedInfo>
                <Button onClick={() => navigateToVision()}>{t("splash.button")}</Button>
                {/* <WebinarButton
                  href="mailto:hello@blockforce.in?subject=webinar_blockforce_dcc"
                  target="_blank"
                >
                  Webinar - Descomplicando #ProdutoBlockforce
                  <br />
                  27/08 - 17h
                </WebinarButton> */}
              </ContentContainer>
            </ContentInfo>
          </Wrapper>
        </Content>
      </MainContent>
    </Splash>
  );
};
const MDOnly = styled.div`
  @media screen and (max-width: 883px) {
    display: none;
  }
`;
let Splash = styled.div`
  width: 100%;
  height: 100%;

  animation: fadeIn 3.5s ease-in-out;
  animation-iteration-count: 1;
  animation-duration: 3s;
`;

let LogoMargin = styled.div`
  width: 100%;
  height: 100%;
  margin: 2rem 0 0 0;
`;

let MainContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (min-width: 800px) {
    height: 40.4375rem;
  }

  @media screen and (min-width: 1440px) {
    height: 46.4375rem;
  }

  @media screen and (min-width: 1600px) {
    height: 60.4375rem;
  }

  @media screen and (min-width: 1800px) {
    height: 62.4375rem;
  }

  @media screen and (min-width: 2400px) {
    height: 100.4375rem;
  }
`;

const SvgContainer = styled.div`
  width: 100%;
  position: absolute;
  z-index: 2;

  @keyframes opacityPulse {
    0% {
      opacity: 0.8;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }

  .pulsating {
    animation: opacityPulse 3s ease-out;
    animation-iteration-count: 2;
    opacity: 0.8;
  }

  @keyframes fadeInBottom {
    from {
      opacity: 0;
      transform: translateY(100%);
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeInCube {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .fadeIn {
    animation: fadeInCube 5s ease-in;
    animation-iteration-count: 1;
    animation-duration: 5s;
  }

  .GWlIhemX_83,
  .GWlIhemX_84,
  .GWlIhemX_85m,
  .fadeInCube {
    animation: fadeInCube 5s ease-in;
    animation-iteration-count: 1;
    animation-duration: 5s;
  }

  .fadeInBottom {
    animation-name: fadeInBottom;
    animation-iteration-count: 1;
    animation-duration: 4.5s;
  }

  .moveOnMouse {
    transform: translateZ(50px);
  }

  @media screen and (min-width: 800px) {
    position: absolute;
  }

  svg {
    height: 21rem;
    position: relative;
    top: 30px;
  }

  @media screen and (min-width: 800px) {
    svg {
      display: block;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }
  }
`;

let Content = styled.div`
  width: 100%;
  height: 100%;

  ${Wrapper} {
    padding: 0 0 0 24px;

    @media screen and (max-width: 800px) {
      padding: 0 24px;
    }
  }
`;

let ContentInfo = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media screen and (min-width: 800px) {
    opacity: 0;
    text-align: left;
    justify-content: center;
    align-items: flex-end;

    position: relative;
    top: 1%;

    svg {
      display: block;
      height: 53.4375rem;
      width: 100%;
      position: absolute;
    }

    @media screen and (min-width: 800px) {
    }

    @keyframes fadeIn {
      from {
        ${"" /* transform: translateY(100%); */}
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    -webkit-animation: fadeIn;
    animation: fadeIn;
    opacity: 1;
    animation-duration: 3.5s;
  }
`;

let ContentContainer = styled.div`
  width: auto;

  display: flex;
  flex-direction: column;

  position: relative;
  z-index: 4;
  @media screen and (max-width: 800px) {
    margin-top: 230px;
  }
`;

let MainInfo = styled.h1`
  margin: 0;
  font-size: 1.75rem;
  font-weight: 600;
  color: #f9f9f9;
  line-height: 2.125rem;

  @media screen and (min-width: 800px) {
    font-size: 3rem;
    line-height: 3.5rem;
  }
`;

let DetailedInfo = styled.h1`
  font-size: 1rem;
  color: #fff;
  font-weight: 300;
  max-width: 18.875rem;
  margin: 0.5rem 0 1.5rem 0;
  line-height: 1.375rem;

  @media screen and (min-width: 800px) {
    font-size: 1.75rem;
    max-width: 44.75rem;
    line-height: 2.3125rem;
    margin: 1.25rem 0 2.3125rem 0;
  }
`;

const WebinarButton = styled.a`
  height: 3.25rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  color: #f9f9f9;
  font-size: 15px;
  font-family: "Titillium Web", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  border: 0;
  border-radius: 5px;
  margin: 0 0 16px 0;

  background: rgb(0, 205, 144);
  background: -moz-linear-gradient(
    90deg,
    rgba(0, 205, 144, 1) 0%,
    rgba(103, 46, 204, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(0, 205, 144, 1) 0%,
    rgba(103, 46, 204, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(0, 205, 144, 1) 0%,
    rgba(103, 46, 204, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00cd90",endColorstr="#00d4ff",GradientType=1);

  background-repeat: no-repeat;
  background-size: 0 100%;
  transition: background-size 1s 0s;
  background-size: 100% 100%;
  border: 0;

  font-size: 12px;

  @media screen and (min-width: 800px) {
    max-width: 20.75rem;
  }
`;
let Button = styled.button`
  height: 3.25rem;
  width: 100%;

  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  color: #f9f9f9;
  font-size: 15px;
  font-family: "Titillium Web", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  border: 0;
  border-radius: 5px;
  margin: 0 0 16px 0;

  background: rgb(0, 205, 144);
  background: -moz-linear-gradient(
    90deg,
    rgba(0, 205, 144, 1) 0%,
    rgba(103, 46, 204, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(0, 205, 144, 1) 0%,
    rgba(103, 46, 204, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(0, 205, 144, 1) 0%,
    rgba(103, 46, 204, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00cd90",endColorstr="#00d4ff",GradientType=1);

  background-repeat: no-repeat;
  background-size: 0 100%;
  transition: background-size 1s 0s;
  border: 1px solid white;

  &:hover {
    background-size: 100% 100%;
    border: 0;
  }

  @media screen and (min-width: 800px) {
    max-width: 20.75rem;
  }
`;
