import loadPolyfills from "./polyfills";
import bootstrap from "./bootstrap";
import createStore from "./app-store";
import cases from "./cases";

let { pathname } = window.location;

if (/\/case\//.test(pathname) && !Object.keys(cases).includes(pathname.replace("/case/", ""))) {
  window.location = "/";
}

let store = createStore();

loadPolyfills().then(() => {
  bootstrap(store);
});

if (module.hot) {
  module.hot.accept("./bootstrap", () => {
    console.log("hot reloading...");

    let nextBootstrap = require("./bootstrap").default;

    nextBootstrap(store);
  });
}
