import styled from "@emotion/styled/macro";

export let SectionText = styled.div`
  padding: 0px 0 10px;
  font-weight: 600;
  font-size: 44px;
  width: 100%;
  max-width: 714px;

  color: #000;

  display: flex;
  flex-direction: column;
`;

export let SectionDescription = styled.span`
  padding: 0px 0 10px;
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;

  @media screen and (min-width: 800px) {
    font-size: 44px;
    line-height: 57px;
    margin-bottom: 0;
  }
`;

export let SectionBelow = styled.span`
  font-size: 19px;
  margin-top: 14px;
  font-weight: 400;
  line-height: 25px;

  @media screen and (min-width: 800px) {
    font-size: 35px;
    line-height: 46px;
    margin-bottom: 0;
  }
`;

export let SectionBelowCustom = styled.span`
  font-size: 19px;
  margin-top: 14px;
  font-weight: 400;
  line-height: 25px;
  color: #000;

  @media screen and (min-width: 800px) {
    font-size: ${props => (props.fontSize ? `${props.fontSize}px` : `25px`)};
    line-height: ${props => (props.fontSize ? `29px` : `46px`)};
    max-width: ${props => (props.fontSize ? `590px` : `100%`)};
    margin-bottom: 0;
  }
`;

export const LandingButton = styled.a`
  height: 3.25rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  color: #f9f9f9;
  font-size: 15px;
  font-family: "Titillium Web", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  margin-left: auto;
  margin-right: auto;
  border: 0;
  border-radius: 5px;
  margin: 0 0 16px 0;

  background: rgb(0, 205, 144);
  background: -moz-linear-gradient(
    90deg,
    rgba(0, 205, 144, 1) 0%,
    rgba(103, 46, 204, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(0, 205, 144, 1) 0%,
    rgba(103, 46, 204, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(0, 205, 144, 1) 0%,
    rgba(103, 46, 204, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00cd90",endColorstr="#00d4ff",GradientType=1);

  background-repeat: no-repeat;
  background-size: 0 100%;
  transition: background-size 1s 0s;
  background-size: 100% 100%;
  border: 0;

  font-size: 12px;

  @media screen and (min-width: 800px) {
    max-width: 20.75rem;
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
