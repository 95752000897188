import React, { useState } from "react";
import styled from "@emotion/styled/macro";
import { ReactComponent as Close } from "../../assets/closeModal.svg";
import { useTranslation, Trans } from "react-i18next";

const Modal = props => {
  const [formValues, setFormValues] = useState({});
  const { t } = useTranslation("common");
  const handleSubmit = () => {
    //just use formValues or install react-hook-form for a better exp :)
    return true;
  };

  const handleFormValue = e => {
    setFormValues(current => {
      const payload = current;
      payload[e.name] = e.value;

      return {
        ...current,
        ...payload
      };
    });
  };

  return (
    <ModalMain dark={props.dark} display={props.modalStatus ? "flex" : "none"}>
      <ModalContainer dark={props.dark}>
        <ModalHeader>
          <ModalClose>
            <Close
              onClick={() => {
                props.setModalStatus(false);
              }}
            />
          </ModalClose>
          <ModalTitle>
            <span>{t("contact-modal.hello")}</span>
          </ModalTitle>
          <ModalDesc>
            <span>
              <Trans t={t} i18nKey="contact-modal.info">
                Use the form below to drop us a line, comments or inquiries. <br />
                We’ll be pleased to hear from you!
              </Trans>
            </span>
          </ModalDesc>
        </ModalHeader>
        <ModalContent>
          <form onSubmit={handleSubmit}>
            <Row>
              <Label>
                <span>{t("contact-modal.name")}</span>
              </Label>
              <Input dark={props.dark}>
                <input
                  type="text"
                  name="name"
                  value={formValues.name}
                  onChange={e => {
                    handleFormValue(e.target);
                  }}
                />
              </Input>
            </Row>
            <Row>
              <Label>
                <span>{t("contact-modal.email")}</span>
              </Label>
              <Input dark={props.dark}>
                <input
                  type="text"
                  name="email"
                  value={formValues.email}
                  onChange={e => {
                    handleFormValue(e.target);
                  }}
                />
              </Input>
            </Row>
            <Row>
              <Label>
                <span>{t("contact-modal.subject")}</span>
              </Label>
              <Input dark={props.dark}>
                <input
                  type="text"
                  name="subject"
                  value={formValues.subject}
                  onChange={e => {
                    handleFormValue(e.target);
                  }}
                />
              </Input>
            </Row>
            <Row>
              <Label>
                <span>{t("contact-modal.message")}</span>
              </Label>
              <Input dark={props.dark}>
                <textarea
                  type="text"
                  name="message"
                  value={formValues.message}
                  onChange={e => {
                    handleFormValue(e.target);
                  }}
                ></textarea>
              </Input>
            </Row>
            <RowButton>
              <button type="submit">{t("contact-modal.submit")}</button>
            </RowButton>
          </form>
        </ModalContent>
      </ModalContainer>
    </ModalMain>
  );
};

export const ModalMain = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;

  color: ${props => (props.dark ? "#fff" : "#000")};

  display: ${props => (props.display ? props.display : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ModalContainer = styled.div`
  width: 85%;
  height: 85%;

  display: flex;
  flex-direction: column;
  background-color: ${props => (props.dark ? "#000" : "#fff")};
  border-radius: 5px;
  box-sizing: border-box;
  padding: 20px;
  overflow: auto;

  @keyframes pop-in {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  animation: pop-in 0.5s;

  @media only screen and (min-width: 768px) {
    max-width: 998px;
    max-height: 950px;
    padding: 40px 20px 72px 53px;
    height: 100%;
    width: 100%;
  }
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ModalClose = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 10px;

  svg {
    cursor: pointer;
    height: 30px;
    width: 30px;
  }
`;

export const ModalTitle = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;

  span {
    font-size: 54px;
    font-weight: 600;
    line-height: 71px;
  }
  @media screen and (max-width: 800px) {
    span {
      font-size: 30px;
    }
  }
`;

export const ModalDesc = styled.div`
  width: 100%;

  span {
    font-size: 22px;
    line-height: 29px;
  }
  @media screen and (max-width: 800px) {
    span {
      font-size: 16px;
    }
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  margin-top: 70px;

  form {
    width: 100%;
    height: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    margin-bottom: 55px;
  }
`;

export const Label = styled.div`
  width: 80px;
  margin-bottom: 30px;

  span {
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    text-transform: uppercase;
  }

  @media only screen and (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 50px;
  }
`;

export const Input = styled.div`
  width: 100%;

  input,
  textarea {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    color: ${props => (props.dark ? "#fff" : "#000")};
    border: 0;
    background: transparent;
    border-bottom: 1px solid ${props => (props.dark ? "#fff" : "#000")};
    position: relative;
    bottom: 5px;
    font-family: "Titillium Web", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
      "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

    &:focus {
      outline: none;
    }
  }

  textarea {
    height: 200px;
  }

  @media only screen and (min-width: 768px) {
    margin-right: 40px;
  }
`;

export const RowButton = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  button {
    width: 100%;
    height: 56px;

    border: 2px solid #7e91b5;
    color: #7e91b5;

    display: flex;
    justify-content: center;
    align-text: center;
    align-items: center;

    background-color: transparent;
    cursor: pointer;

    font-size: 16px;
    font-weight: 600;
    border-radius: 4px;

    @media only screen and (min-width: 768px) {
      max-width: 312px;
      margin-right: 40px;
    }
  }
`;

export default Modal;
