import css from "@emotion/css/macro";
import styled from "@emotion/styled/macro";
import React from "react";

let Svg = styled.svg`
  cursor: pointer;

  .l1,
  .l2 {
    stroke-dasharray: 24;
    transition: all 0.2s ease-in-out;
  }

  .l2 {
    stroke-dashoffset: 8;
  }

  ${({ active }) =>
    active
      ? css`
          .l1 {
            stroke-dashoffset: 8;
          }

          .l2 {
            stroke-dashoffset: 0;
          }
        `
      : ""}

  &:hover {
    .l1 {
      transform: translateY(-2px);
      stroke-dashoffset: 0;
    }

    .l2 {
      transform: translateY(2px);
      stroke-dashoffset: 0;
    }
  }
`;

export default props => (
  <Svg viewBox="0 0 24 24" tabindex="0" role="button" {...props}>
    <g fill="none" stroke="currentColor" strokeWidth="1.5">
      <line className="l1" x1="0" x2="24" y1="2.5" y2="2.5" />
      <line x1="24" x2="0" y1="11.5" y2="11.5" />
      <line className="l2" x1="24" x2="0" y1="20.5" y2="20.5" />
    </g>
  </Svg>
);
