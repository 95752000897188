import React from "react";
import css from "@emotion/css/macro";
import styled from "@emotion/styled/macro";
import { InnerWrapper, mobileLateralPadding } from "./ui-kit";

export default ({ teamMembers, inView }) => (
  <TeamListWrapper>
    {teamMembers.map((member, i) => (
      <TeamMember key={member.name} delay={i * 100} inView={inView}>
        <div>
          <img alt={member.name} src={member.img} />
        </div>
        <h3>{member.name}</h3>
        <h4>{member.title}</h4>
        {/* <span className="bottomDesc">{member.desc}</span> */}
      </TeamMember>
    ))}
  </TeamListWrapper>
);

let TeamListWrapper = styled(InnerWrapper)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 5px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 500px) {
    grid-gap: 32px;
    grid-template-columns: repeat(2, calc(50vw - ${mobileLateralPadding * 2}px));
  }
`;

let TeamMember = styled.div`
  opacity: 0;
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
  overflow: none;

  @keyframes fadeIn {
      from {
          ${"" /* transform: translateY(100%); */}
          opacity: 0;
      }
      to {
          opacity: 1;
      }
  }

  -webkit-animation: fadeIn;
  animation: fadeIn;
  opacity: 1;
  -webkit-animation-duration: 3s;
  animation-duration: 3s

  ${({ inView, delay }) =>
    inView
      ? css`
          animation: teamMemberAppear 300ms ${delay || 0}ms ease-in forwards;

          @keyframes teamMemberAppear {
            to {
              opacity: 1;
            }
          }
        `
      : ""}

  text-align: center;

  .bottomDesc {
    font-size: 14px;
    font-weight: 400px;
    max-width: 212px;
    line-height: 19px;
    height: 87px;
    margin-top: 8px;

    @media (min-width: 800px) {
      margin-top: 13px;
    }
  }

  > div {
    background: rgb(102, 51, 204);
    background: -moz-linear-gradient(90deg, rgba(102, 51, 204, 1) 0%, rgba(0, 204, 153, 1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(102, 51, 204, 1) 0%, rgba(0, 204, 153, 1) 100%);
    background: linear-gradient(90deg, rgba(102, 51, 204, 1) 0%, rgba(0, 204, 153, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6633cc",endColorstr="#00cc99",GradientType=1);
    border-radius: 50%;
    padding: 5px;
    position: relative;
    width: 120px;
   
    margin: 0 auto 20px;
    @media (min-width: 800px) {
      width: 164px;
    }
    img {
      display: block;
      border-radius: 50%;
      width: 100%;
    height: 110px;
      @media (min-width: 800px) {
        height: 154px;
    }
      filter: grayscale(1);
    }

    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      padding-top: 100%;
      pointer-events: none;
    }
  }

  > h3 {
    font-size: 16px;
    margin: 0;
    min-height: 33px;

    @media (min-width: 800px) {
      font-size: 22px;
    }
  }

  > h4 {
    font-size: 14px;
    font-style: italic;
    font-weight: 300;
    margin: 0;
    min-height: 40px;
    white-space: pre-line;

    @media (min-width: 800px) {
      height: 48px;
    }
  }
`;
