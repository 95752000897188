import React, { useEffect, useState } from "react";
import styled from "@emotion/styled/macro";
import { ReactComponent as ArrowBack } from "../../assets/team/arrow-back.svg";
import { ReactComponent as Lines } from "../../assets/product/lines.svg";
import { history } from "../../lib";
import { Logo, Wrapper, Footer } from "../../components";
import Contact from "../Home/sections/Contact";
import DigitalCurrencyConnect from "./section/DigitalCurrencyConnect";
import BlockchainConsulting from "./section/BlockchainConsulting";
import TrustProvider from "./section/TrustProvider";
import FurtherPay from "./section/FurtherPay";
import Products from "./section/Products";
import wavesBG from "../../assets/bg_waves.png";
import cases from "../../cases";
import CarouselCase from "../Case/CarouselCase";

export default function Product(props) {
  const allowedRoutes = [
    "cryptum",
    "cryptum",
    "consulting-social-impact-blockchain",
    "consultoria-impacto-social-blockchain",
    "traceability-blockchain",
    "rastreabilidade-blockchain",
    "further-pay",
    undefined
  ];
  const [themeType, setThemeType] = useState(false);

  useEffect(() => {
    if (!allowedRoutes.includes(props.product)) {
      history.navigate(`/`);
    }
    // setThemeType(props.product === undefined ? false : true);
    const isDarkTheme =
      props.product === undefined ||
      props.product === "consulting-social-impact-blockchain" ||
      props.product === "consultoria-impacto-social-blockchain";
    setThemeType(!isDarkTheme);
  }, [props.product]);

  let showWavesBG =
    props.product === "consulting-social-impact-blockchain" ||
    props.product === "consultoria-impacto-social-blockchain";
  let showCasesCarousel =
    props.product === "consulting-social-impact-blockchain" ||
    props.product === "consultoria-impacto-social-blockchain";

  const slides = Object.keys(cases).map(key => cases[key]);

  return (
    <ProductBody light={themeType}>
      {themeType && (
        <SvgContainer>
          <Lines />
        </SvgContainer>
      )}
      {showWavesBG && (
        <WavesBG
          style={{
            position: "absolute",
            height: "50%",
            backgroundImage: `url(${wavesBG})`,
            marginBottom: 0,
            width: "100vw",
            zIndex: 0,
            marginTop: 160
          }}
        />
      )}
      <Wrapper>
        <LogoMargin>
          <Logo light={themeType} />
        </LogoMargin>
        <ArrowContainer>
          <ArrowBack onClick={history.back} />
        </ArrowContainer>
        <ProdutType>
          {props.product === undefined && <Products />}
          {(props.product === "consulting-social-impact-blockchain" ||
            props.product === "consultoria-impacto-social-blockchain") && <BlockchainConsulting />}
          {(props.product === "cryptum" || props.product === "cryptum") && (
            <DigitalCurrencyConnect />
          )}
          {(props.product === "traceability-blockchain" ||
            props.product === "rastreabilidade-blockchain") && <TrustProvider />}
          {/* {props.product === "further-pay" && <FurtherPay />} */}
        </ProdutType>
      </Wrapper>
      {showCasesCarousel && (
        <CarouselContainer>
          <CarouselCase blackButton={true} cases={slides} />
        </CarouselContainer>
      )}
      <Contact dark={themeType} lightToast={themeType} />
      <Footer dark={themeType} />
    </ProductBody>
  );
}

const CarouselContainer = styled.div`
  padding: 0 32px;
`;
const WavesBG = styled.div`
  background-repeat: no-repeat;
`;

const ProductBody = styled.div`
  width: 100%;
  background-color: ${props => (props.light ? `#ffffff` : `#000`)};
  position: absolute;
`;

const LogoMargin = styled.div`
  svg {
    margin: 2rem 0 0 0;
  }
`;

const SvgContainer = styled.div`
  width: 100%;
  height: auto;
  position: absolute;
  overflow: hidden;

  @media screen and (min-width: 800px) {
    top: 160px;
    overflow: initial;
  }

  svg {
    width: 100rem;
    height: 55rem;

    top: 20px;

    @media screen and (min-width: 800px) {
      width: 100%;
      height: 100%;
    }
  }
`;

const ProdutType = styled.div`
  position: relative;
  z-index: 5;
`;

const ArrowContainer = styled.div`
  margin: 30px 0;
  position: relative;
  z-index: 5;

  @media screen and (min-width: 800px) {
    margin: 50px 0 50px;
  }
`;
