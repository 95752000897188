import React from "react";
import styled from "@emotion/styled/macro";

const Role = props => {
  return (
    <RoleTypes>
      {props.roles.map(i => {
        return (
          <RoleContainer key={i.title}>
            <RoleImg>{i.img}</RoleImg>
            <RoleContent>
              <RoleText>{i.title}</RoleText>
            </RoleContent>
          </RoleContainer>
        );
      })}
    </RoleTypes>
  );
};

const RoleTypes = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const RoleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 800px) {
    flex-direction: column;
    max-width: 320px;
    margin: 40px 0 40px 0;
  }
`;

const RoleContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (min-width: 800px) {
  }
`;

const RoleImg = styled.div`
  width: 30%;
  display: flex;
  justify-content: center;
  svg {
    width: 80px;
    height: 150px;
    margin-right: 15px;
  }

  @media screen and (min-width: 800px) {
    width: 100%;
    height: 100%;

    svg {
      width: 100%;
      height: 100%;
      max-width: 120px;
      max-height: 100px;
    }
  }
`;

const RoleText = styled.span`
  width: 100%;
  font-size: 24px;
  font-weight: 600;
  color: #000;

  @media screen and (min-width: 800px) {
    height: 55px;
    margin-top: 35px;
    white-space: nowrap;
    text-align: center;
  }
`;

export default Role;
