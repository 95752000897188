import React from "react";
import styled from "@emotion/styled/macro";
import { history } from "../../lib";
import { useTranslation } from "react-i18next";

const Case = props => {
  const handleNavigate = uri => {
    history.navigate(`/case/${props.path}`);
  };
  const { t, i18n } = useTranslation("cases");

  return (
    <CaseBox onClick={handleNavigate}>
      <CaseImage
        sizeInCarousel={props.sizeInCarousel}
        specialCarouselCss={props.specialCarouselCss}
      >
        {props.titleText ? (
          <CaseSpecial>
            <img src={props.titleImg} alt={t(props.title)} />
            <span>{t(props.titleText)}</span>
          </CaseSpecial>
        ) : (
          <img src={props.titleImg} alt={t(props.title)} />
        )}
      </CaseImage>
    </CaseBox>
  );
};

export default Case;

const CaseSpecial = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    margin-top: 1rem;
    font-size: 1.3rem;
  }
`;

let CaseBox = styled.div`
  width: 100%;
  height: auto;
  margin-top: 2.5rem;
  margin-right: 10px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 800px) {
    margin-top: 0;
    margin-right: 1rem;
    width: 21.125rem;
  }
`;

let CaseImage = styled.div`
  width: 100%;
  height: 17.125rem;

  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #b0b4be;

  img {
    width: 200px;
  }

  @media screen and (min-width: 800px) {
    img {
      width: 100%;
      height: ${props => (props.sizeInCarousel ? props.sizeInCarousel : "auto")};
      max-width: 12.8125rem;

      position: ${props =>
        props.specialCarouselCss && props.specialCarouselCss.position
          ? props.specialCarouselCss.position
          : "block"};
      bottom: ${props =>
        props.specialCarouselCss && props.specialCarouselCss.bottom
          ? props.specialCarouselCss.bottom
          : "0"};
    }
  }
`;
