import React, { useRef, useEffect } from "react";
import Flickity from "flickity";

let flkty;
export default function Flkty({ options, flickityRef, children }) {
  let ref = useRef();

  useEffect(() => {
    flkty = new Flickity(ref.current, options);
    flickityRef.current = flkty;

    return () => {
      flkty.destroy();
    };
  }, []);

  useEffect(
    () => {
      if (flkty.options.draggable !== options.draggable) {
        flkty.options.draggable = options.draggable;
        flkty.updateDraggable();
      }
    },
    [options.draggable]
  );

  return <div ref={ref}>{children}</div>;
}
