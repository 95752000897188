import React from "react";
import { ReactComponent as Instagram } from "../assets/footer/instagram.svg";
import { ReactComponent as LinkedIn } from "../assets/footer/linkedIn.svg";
import { ReactComponent as Medium } from "../assets/footer/medium.svg";
import styled from "@emotion/styled/macro";

export let SocialLinksWrapper = styled.nav`
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? "column" : "row")};

  a {
    font-size: 22px !important;
    text-decoration: none;
    transform: ${({ vertical }) => (vertical ? "rotate(270deg)" : "none")};
    transition: color 0.3s ease-in-out;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 20px;

    svg,
    path {
      width: 40px;
      height: 40px;
      fill: ${props => (props.dark ? "#000" : "#7e91b5")};
    }

    &:hover {
      fill: #5cadff;
    }
  }
`;

/**
 * change href attr
 */
export let SocialLinks = (props, { vertical }) => (
  <SocialLinksWrapper vertical={vertical} dark={props.dark}>
    <a
      href="https://www.linkedin.com/company/blockforce/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <LinkedIn />
    </a>
    <a
      href="https://instagram.com/blockforce.in?utm_source=ig_profile_share&igshid=1iwsz417p0e09"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Instagram />
    </a>
    <a href="https://blockforce-in.medium.com" target="_blank" rel="noopener noreferrer">
      <Medium />
    </a>
  </SocialLinksWrapper>
);
