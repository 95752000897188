import React from "react";
import bg from "../../../assets/scope_of_work_bg.png";
import WhatWeDo from "./WhatWeDo";
import Scope from "./Scope";

export default () => (
  <div style={{ marginTop: 130, position: "relative" }}>
    <div
      style={{
        position: "absolute",
        zIndex: 0,
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "100vh",
        width: "100%",
        top: -200
      }}
    ></div>
    <Scope />
    <WhatWeDo></WhatWeDo>
  </div>
);
