export default () => {
  let initialState = { menuOpened: false, firstView: true };

  function reducer(state, action) {
    let { type: t } = action;

    if (t === "TOGGLE_MENU") return { ...state, menuOpened: !state.menuOpened };
    if (t === "SET_FIRST_VIEW") return { ...state, firstView: false };

    return state;
  }

  return { initialState, reducer };
};
