import React from "react";
import css from "@emotion/css/macro";
import styled from "@emotion/styled/macro";
import shadow from "../../assets/hexagon-shadow.png";

let SvgWrapper = styled.div`
  position: relative;
  perspective: 320px;

  .main-svg {
    position: relative;
  }

  .shadow,
  .gradient-svg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .outer,
  .inner,
  .central {
    opacity: 1;
  }

  .shadow {
    transform: translateZ(-32px) scale(1.2);
    opacity: 0.5;
  }

  .gradient-svg {
    transform: translateZ(32px) scale(0.9);
  }

  ${({ animate }) =>
    animate
      ? css`
          .shadow,
          .outer,
          .inner,
          .central,
          .rainbow {
            opacity: 0;
          }
        `
      : ""};
`;

export default ({ hostRef, animate }) => (
  <SvgWrapper
    ref={hostRef}
    viewBox="0 0 481 533"
    fill="none"
    style={{ transform: animate ? "translateY(-50px)" : "" }}
    animate={animate}
  >
    <img alt="shadow" src={shadow} className="shadow" />
    <svg fill="none" className="main-svg" viewBox="0 0 481 533">
      <path
        fill="url(#paint5_linear)"
        d="M327.182 216.259L242.748 70L411.628 167.51L327.182 216.259Z"
        className="outer"
      />
      <path
        fill="url(#paint4_linear)"
        d="M327.754 217.254L412.199 363.513V168.493L327.754 217.254Z"
        className="outer"
      />
      <path
        fill="url(#paint3_linear)"
        d="M242.177 463V365.49H411.056L242.177 463Z"
        className="outer"
      />
      <path
        fill="url(#paint2_linear)"
        d="M241.034 365.49H72.1431L241.034 463V365.49Z"
        className="outer"
      />
      <path
        fill="url(#paint1_linear)"
        d="M155.445 217.254L71 168.493L71 363.513L155.445 217.254Z"
        className="outer"
      />
      <path
        fill="url(#paint0_linear)"
        d="M240.462 70L71.5715 167.51L156.017 216.259L240.462 70Z"
        className="outer"
      />
      <path
        fill="url(#paint8_linear)"
        d="M326.233 216.899L241.605 70.3086L156.966 216.899L326.233 216.899Z"
        className="inner"
      />
      <path
        fill="url(#paint7_linear)"
        d="M326.725 217.756L242.097 364.347H411.365L326.725 217.756Z"
        className="inner"
      />
      <path
        fill="url(#paint6_linear)"
        d="M241.102 364.347L156.474 217.756L71.8345 364.347H241.102Z"
        className="inner"
      />
      <path
        fill="url(#paint9_linear)"
        d="M241.605 362.918L157.96 218.042L325.239 218.042L241.605 362.918Z"
        className="central"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="90.859"
          x2="249.785"
          y1="6.64118"
          y2="334.843"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0D0D0D" />
          <stop offset="1" stopColor="#2A2A2A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="29.2197"
          x2="228.937"
          y1="290.467"
          y2="176.292"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0D0D0D" />
          <stop offset="1" stopColor="#2A2A2A" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="156.588"
          x2="189.961"
          y1="463"
          y2="321.844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0D0D0D" />
          <stop offset="1" stopColor="#2A2A2A" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="401.817"
          x2="331.567"
          y1="495.033"
          y2="303.661"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0D0D0D" />
          <stop offset="1" stopColor="#2A2A2A" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="454.028"
          x2="254.243"
          y1="290.405"
          y2="176"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0D0D0D" />
          <stop offset="1" stopColor="#2A2A2A" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="391.579"
          x2="231.99"
          y1="6.64118"
          y2="333.977"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0D0D0D" />
          <stop offset="1" stopColor="#2A2A2A" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="155.976"
          x2="156.462"
          y1="80.059"
          y2="512.892"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0D0D0D" />
          <stop offset="1" stopColor="#2A2A2A" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="326.239"
          x2="326.724"
          y1="80.059"
          y2="512.892"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0D0D0D" />
          <stop offset="1" stopColor="#2A2A2A" />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="382.291"
          x2="156.299"
          y1="-11.0633"
          y2="347.154"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0D0D0D" />
          <stop offset="1" stopColor="#2A2A2A" />
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1="450.6"
          x2="170.275"
          y1="586.281"
          y2="-20.7616"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0D0D0D" />
          <stop offset="1" stopColor="#2A2A2A" />
        </linearGradient>
      </defs>
    </svg>
    <svg fill="none" className="gradient-svg" viewBox="0 0 481 533">
      <path
        fill="url(#paint10_linear)"
        fillRule="evenodd"
        d="M368.049 193.33L241.61 412.33L115.17 193.33L368.049 193.33ZM365.798 194.63L365.734 194.74L365.67 194.63H365.798ZM242.3 408.534L365.202 195.662L242.3 266.826V408.534ZM241 408.674L118.043 195.707L241 266.923V408.674ZM118.778 194.63L241.566 265.749L364.39 194.63L118.778 194.63Z"
        className="rainbow"
      />
      <defs>
        <linearGradient
          id="paint10_linear"
          x1="115"
          x2="368"
          y1="193"
          y2="193"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B81E7C" />
          <stop offset="0.5" stopColor="#1B5997" />
          <stop offset="1" stopColor="#58BF9B" />
        </linearGradient>
      </defs>
    </svg>
  </SvgWrapper>
);
