import React from "react";
import ReactDOM from "react-dom";
import "./i18n";
import { Router, LocationProvider } from "@reach/router";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Home, Case, Team, Product, Vision, Blog, BlogPost } from "./routes";
import { history, createScrollBehaviour, Provider } from "./lib";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./toast_style.css";

let scroll = createScrollBehaviour(history);

history.listen(scroll.store);
history.listen(({ location }) => {
  if (window.ga) {
    window.ga("set", "page", location.pathname + location.search);
    window.ga("send", "pageview");
  }
});
let root = document.getElementById("root");

export default function bootstrap(store) {
  ReactDOM.render(
    <Provider {...store}>
      <ToastContainer limit={3} />
      <LocationProvider history={history}>
        {({ location }) => (
          <TransitionGroup>
            <CSSTransition key={location.key} classNames="fade" timeout={500}>
              <Router>
                <Home path="/" default scroll={scroll} />
                <Case path="/case/:case" scroll={scroll} />
                <Vision path="/vision" scroll={scroll} />
                <Product path="/products/:product" scroll={scroll} />
                <Product path="/products" scroll={scroll} />
                <Blog path="/blog" scroll={scroll} />
                <BlogPost path="/blog/:lang/:seo_url" scroll={scroll} />
                <Team path="/team" scroll={scroll} />
              </Router>
            </CSSTransition>
          </TransitionGroup>
        )}
      </LocationProvider>
    </Provider>,
    root
  );
}
