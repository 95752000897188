import styled from "@emotion/styled/macro";

export let SliderWrapper = styled.div`
  width: 100%;
  height: 0;
  margin: 0 auto;
`;

export let Carousel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "all")};

  .flickity-enabled {
    outline: none;
  }
  .flickity-enabled:focus {
    outline: none;
  }
  .flickity-viewport {
    width: 100%;
    height: ${props => (props.height ? props.height : "64vmin")};
  }

  .flickity-button {
    display: block;
    position: absolute;
    top: 0;
    width: 7%;
    background: black;
    border: 0;
    padding: 0;
    height: 21rem;
    cursor: pointer;

    svg,
    path {
      fill: #7e91b5;
      width: 38px;
      height: 100px;
    }

    @media (max-width: 500px) {
      display: none;
    }
  }

  .flickity-button.next {
    right: 0;
  }

  .flickity-button.previous {
    left: 0;
  }

  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 64vmin;
    width: 60%;
    cursor: pointer;

    @media (max-width: 500px) {
      height: 110vmin;
    }
  }

  .text h2 {
    margin: 0;
    font-size: 8vw;
    line-height: 1;
    color: transparent;
    white-space: nowrap;
    letter-spacing: 2px;
    -webkit-text-stroke: 2px #333333;
    -moz-text-stroke: 2px #333333;
    user-select: none;
    transition: color 0.5s ease, opacity 0.2s ease-in;

    @media (max-width: 500px) {
      -webkit-text-stroke: 1px #fff;
      -moz-text-stroke: 1px #fff;
    }
  }

  .is-selected h2 {
    font-size: 200px !important;
    color: #fff;
    -webkit-text-stroke: 2px #fff;
    -moz-text-stroke: 2px #fff;

    @media (max-width: 500px) {
      font-size: 55px !important;
    }
  }

  .text:hover h2 {
    color: #fff;
  }

  .text h2.selected {
    -webkit-text-stroke: 0;
    -moz-text-stroke: 0;
    color: #fff;
    opacity: 0;
  }

  @media (max-width: 768px) {
    .text {
      &.is-selected h2 {
        color: #fff !important;
      }

      &:not(.is-selected) h2 {
        color: transparent !important;
      }
    }
  }
`;

export let CarouselImg = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background: #000;
    opacity: 0.2;
  }
`;
