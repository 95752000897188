/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from "react";
import { createPortal } from "react-dom";
import styled from "@emotion/styled/macro";
import { Wrapper } from "./ui-kit";
import { history } from "../lib";
import { stop } from "../util";
import { SocialLinks, SocialLinksWrapper } from "./SocialLinks";
import { withTranslation } from "react-i18next";

let menuMnt = document.getElementById("menu");

class ResponsiveMenu extends Component {
  el = document.createElement("div");
  inScale = 0;
  outScale = 1;
  targetScale = this.inScale;
  maskScale = this.targetScale;
  contentScale = 1 / this.maskScale;

  componentDidMount() {
    menuMnt.appendChild(this.el);

    [...this.el.querySelectorAll("a.link")].forEach(a => {
      a.addEventListener("click", this.goTo);
    });
  }

  componentDidUpdate() {
    let { opened } = this.props;

    if (opened) {
      document.body.style.overflowY = "hidden";
      this.targetScale = this.outScale;
      requestAnimationFrame(this.update);
    }

    if (!opened) {
      document.body.style.overflowY = "";
      this.targetScale = this.inScale;
      requestAnimationFrame(this.update);
    }
  }

  componentWillUnmount() {
    menuMnt.removeChild(this.el);

    [...this.el.querySelectorAll("a")].forEach(a => {
      a.removeEventListener("click", this.goTo);
    });
  }

  update = () => {
    let { opened } = this.props;
    let { mask, content } = this;

    if (!mask || !content) return;

    this.maskScale += (this.targetScale - this.maskScale) * (opened ? 0.1 : 0.25);
    this.contentScale = 1 / this.maskScale;

    mask.style.transform = `scale(${this.maskScale})`;
    content.style.transform = `scale(${this.contentScale})`;

    if (opened) {
      if (this.maskScale < this.targetScale - 0.1) {
        requestAnimationFrame(this.update);
      } else {
        mask.style.transform = content.style.transform = `scale(1)`;
      }
    } else {
      if (this.maskScale >= this.targetScale + 0.005) {
        requestAnimationFrame(this.update);
      } else {
        mask.style.transform = content.style.transform = `scale(0)`;
      }
    }
  };

  goTo = e => {
    e.preventDefault();

    this.props.toggle();

    let hash = String(e.currentTarget.hash);
    let isSamePageRef = true;
    let link;
    if (e.currentTarget.href.indexOf("#") < 0) {
      isSamePageRef = false;
      link = e.currentTarget.href;
    }

    setTimeout(() => {
      if (!isSamePageRef) {
        window.history.replaceState(null, null, "/");
        history.navigate(link);
      } else {
        if (history.location.pathname.length > 1) {
          history.navigate("/").then(() => {
            setTimeout(() => {
              let target = document.querySelector(hash || "body");
              window.scrollTo({
                top: target.getBoundingClientRect().top + window.pageYOffset - 64,
                behavior: "smooth"
              });
            }, 1000);
          });
        } else {
          let target = document.querySelector(hash || "body");
          window.scrollTo({
            top: target.getBoundingClientRect().top + window.pageYOffset - 64,
            behavior: "smooth"
          });
        }
      }
    }, 500);
  };

  render() {
    let { toggle, t } = this.props;

    return createPortal(
      <MenuMask ref={c => (this.mask = c)} onClick={toggle}>
        <MenuContent ref={c => (this.content = c)} onClick={stop}>
          <Wrapper>
            <a className="link" href="#">
              <span>{t("menu.home")}</span>
            </a>

            <a className="link" href="/vision">
              <span>{t("menu.our-vision")}</span>
            </a>

            <a className="link" href={t("menu.consultingLink")}>
              <span>{t("menu.consulting")} </span>
            </a>

            <a className="link" href="/products">
              <span>{t("menu.products")} </span>
            </a>

            <a className="link" href="#cases">
              <span>{t("menu.cases")}</span>
            </a>

            {/* <a className="link" href="/team">
              <span>{t("menu.team")} </span>
            </a> */}

            <a className="link" href="#partners">
              <span>{t("menu.partners")}</span>
            </a>

            <a className="link" href="#press">
              <span>{t("menu.press")}</span>
            </a>
          </Wrapper>
          <MenuFooter>
            <Wrapper>
              <div className="left">
                <a href="mailto:hello@blockforce.in">Email: hello@blockforce.in</a>
              </div>
              <div className="right">
                <SocialLinks />
              </div>
            </Wrapper>
          </MenuFooter>
        </MenuContent>
      </MenuMask>,
      this.el
    );
  }
}
export default withTranslation("common")(ResponsiveMenu);
let MenuMask = styled.div`
  position: fixed;
  top: -200vh;
  right: -200vw;
  width: 400vw;
  height: 400vh;
  border-radius: 50%;
  transform-origin: center;
  transform: translate3d(0, 0, 0) scale(0);
  overflow: hidden;

  /* safari hack (don't move) */
  @media not all and (min-resolution: 0.001dpcm) {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
`;

let MenuContent = styled.div`
  position: fixed;
  top: 200vh;
  right: 200vw;
  width: 100vw;
  transform-origin: top right;
  transform: scale(0);
  background: #000;
  color: #fff;
  padding: 128px 0 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;

  .link {
    display: block;
    color: currentColor;
    text-decoration: none;
    font-size: 32px;
    letter-spacing: 0.1ren;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 100px;
  }

  span {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: #00ce9b;
      transform-origin: left;
      transform: scaleX(0);
      transition: transform 0.3s ease-in-out;
    }
  }

  a:hover span::after {
    transform: scaleX(1);
  }

  @media (max-width: 500px) {
    padding: 96px 0 0;

    a.link {
      padding: 0;
    }
  }
`;

let MenuFooter = styled.div`
  background: linear-gradient(to right, #111, #333);
  color: #fff;
  padding: 32px 0;
  padding-left: 100px;
  margin-top: 32px;

  ${Wrapper} {
    display: flex;
    justify-content: space-between;
  }

  a {
    font-size: 14px;
    line-height: 2;
    margin: 0;
    transition: color 0.3s ease-in-out;
    align-items: center;
    display: flex;

    &:hover {
      color: #5cadff;
    }
  }

  a + a {
    margin-left: 48px;
  }

  .left,
  .right {
    display: flex;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: stretch;

    padding-left: 0;

    a + a {
      margin-left: 0;
    }

    .left,
    .right {
      margin: 16px 0;
    }

    .left {
      flex-direction: column;
      margin: 0;
    }

    ${SocialLinksWrapper} {
      display: none;
    }
  }
`;
