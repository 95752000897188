import React, { useReducer, useEffect, useRef, useState } from "react";

import { stop } from "../../util";
import Flickity from "../Flickity";
import Cube from "../Cube";
import { Slider } from "../ui-kit";
import Overview from "../Overview";
import { Wrapper } from "../../components";
import { CarouselImg, Carousel, SliderWrapper } from "./carousel-components";
import { useTranslation } from "react-i18next";

let initialState = { index: 0, selectedIndex: null, fadeOut: false, moving: false };

let reducer = (state, action) => {
  let { type } = action;

  if (type === "CHANGE") return { ...state, index: action.index };

  if (type === "SELECT") return { ...state, selectedIndex: action.index };

  if (type === "TOGGLE_FADE") return { ...state, fadeOut: !state.fadeOut };

  if (type === "TOGGLE_MOVING") return { ...state, moving: !state.moving };

  return state;
};

export default function CasesCarousel({ slides, onNavigateToCase }) {
  let redux = useReducer(reducer, initialState);
  let [state, dispatch] = redux;
  let flkty = useRef();
  let cube = useRef();
  const { t, i18n } = useTranslation("cases");
  let { length } = slides;
  let turnSize = 1 / length;

  let { selectedIndex, index, fadeOut, moving } = state;

  let [navigating, setNavigating] = useState(false);

  let handleSelect = e => {
    if (selectedIndex != null) return;

    let i = e.target.value;

    dispatch({ type: "CHANGE", index: i });

    flkty.current.select(i);
  };

  let options = {
    prevNextButtons: false,
    pageDots: false,
    setGallerySize: false,
    wrapAround: true,
    autoPlay: true,
    draggable: selectedIndex == null
  };

  return (
    <>
      <Cube slides={slides}></Cube>
      {/* <Carousel disabled={selectedIndex != null} onClick={stop}>
        <Flickity flickityRef={flkty} options={options}>
          {slides.map((slide, i) => (
            <div
              style={{ visibility: navigating ? "hidden" : "visible" }}
              className="text"
              key={slide.title}
            >
              <h2 className={selectedIndex === i ? "selected" : ""}>{t(slide.title)}</h2>
            </div>
          ))}
        </Flickity>
      </Carousel> */}
    </>
  );
}
