import React from "react";
import styled from "@emotion/styled/macro";
import { Wrapper, lateralPadding } from "../../../components";
import { toast } from "react-toastify";
import { useTranslation, Trans } from "react-i18next";

export default props => {
  const { t } = useTranslation("common");

  const toastStyle = {
    fontFamily:
      "Titillium Web, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important",
    borderRadius: "8px !important",
    border: "2px solid #7e91b5"
  };

  const handleEmailSubscription = () => {
    toast("We have received your subscription!", {
      position: toast.POSITION.BOTTOM_RIGHT,
      hideProgressBar: true,
      type: "dark",
      autoClose: 3000,
      className: props.lightToast ? "light-toast" : "",
      style: toastStyle
    });
  };

  return (
    <ContactWrapper id="contact" dark={props.dark}>
      <div className="inner">
        <span className="Contact__title">
          <Trans t={t} i18nKey="contact.strongerTogether">
            Stronger together.
            <br />
            join our network.
          </Trans>
        </span>
        <div className="form-container">
          <form
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <label htmlFor="name">
              <input id="name" name="name" type="name" placeholder={t("contact.name")} />
            </label>
            <label htmlFor="email">
              <input id="email" name="email" type="email" placeholder="Email" />
            </label>
            <SubscribeButton
              type="submit"
              onClick={() => handleEmailSubscription()}
              value={t("contact.subscribe")}
              dark={props.dark}
            />
          </form>
        </div>
      </div>
    </ContactWrapper>
  );
};

let ContactWrapper = styled(Wrapper)`
  margin: 0 auto 64px;

  .inner {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
  }

  .Contact__title {
    font-size: 2.25rem;
    color: ${props => (props.dark ? "#000" : "#7e91b5")};
    line-height: 3rem;
  }

  h2 {
    font-size: 32px;
    line-height: 36px;
    margin: 0 64px 0 0;
    color: #7e90b4;
    flex: 1;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
  }

  form {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-bottom: 24px;
    margin-top: 36px;
    @media (max-width: 800px) {
      margin-top: 0px;
    }
  }

  label {
    margin: 0 16px 0 0;
    width: 100%;
  }

  input {
    background: transparent;
    appearance: none;
    color: #fff;
    font-family: "Titillium Web", sans serif;
    font-weight: 500;
    font-size: 16px;
    padding: 16px 0;
    min-width: 332px;
    width: 100%;
    outline: 0;

    color: ${props => (props.dark ? "#000" : "#7e91b5")};

    &::placeholder {
      color: #fff;
    }
  }

  input:not([type="submit"]) {
    border: none;
    border-bottom: 2px solid ${props => (props.dark ? "#000" : "#7e91b5")};
  }

  ${"" /* input + div {
    background: ${props => props.dark ? '#000' : '#7e91b5'};;
    width: 100%;
    height: 2px;
    transform: translateY(-1px) scaleX(0);
    transform-origin: left;
    transition: transform 0.2s ease;
  } */}

  input:focus + div {
    transform: translateY(-1px) scaleX(1);
  }

  @media (max-width: ${1000 + 2 * lateralPadding}px) {
    .inner {
      justify-content: space-between;
    }

    form {
      margin-left: auto;
    }

    input {
      min-width: 200px;
    }
  }

  @media (max-width: 500px) {
    .inner {
      flex-direction: column;
      align-items: stretch;
    }

    .Contact__title {
      font-size: 1.25rem;
      font-weight: 600;
      color: ${props => (props.dark ? "#000" : "#7e91b5")};
      margin-bottom: 2rem;
      line-height: 1.625rem;
    }

    h2 {
      margin: 0 0 64px 0;
    }

    .form-container {
      flex-direction: column;
      align-items: stretch;
    }

    label {
      margin: 0 0 32px 0;
    }

    form {
      flex-direction: column;
      width: 100%;
    }
  }
`;

let SubscribeButton = styled.input`
  white-space: nowrap;
  appearance: none;
  background: transparent;
  border: 2px solid ${props => (props.dark ? "#000" : "#7e91b5")};
  border-radius: 6px;
  padding: 13px;
  cursor: pointer;
  color: ${props => (props.dark ? "#000" : "#7e91b5")};
  font-size: 1rem;
  font-family: "Titillium Web", sans serif;
  font-weight: 600 !important;
  padding: 0.8125rem 2.5em;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.5s ease-in;
  outline: 0;
  flex: 1;
  margin-top: 50px;
  @media (max-width: 800px) {
    margin-top: 24px;
  }
  &:hover,
  &:focus {
    color: ${props => (props.light ? "#fff" : "#111")};
    background: ${props => (props.light ? "#111" : "#fff")};
  }
`;
