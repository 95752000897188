import styled from "@emotion/styled";
import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Footer, Nav, Wrapper, Logo, Reveal } from "../../components";
import Contact from "../Home/sections/Contact";
import { PostGrid } from "./components/post";
import wavesBG from "../../assets/vision_bg.png";

const CMS_URL = "https://cms.blockforce.in";
//https://cms.blockforce.in/posts?_where[categorias.Nome]=DCC
export default function Blog({ location }) {
  const [categories, setCategories] = useState([]);
  const [posts, setPosts] = useState([]);
  const [startIndex, setStartIndex] = useState(6);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { t, i18n } = useTranslation("blog");
  useEffect(() => {
    const qs = new URLSearchParams(location.search);
    const category = qs.get("category");
    console.log(category);
    if (category) {
      setSelectedCategory(category);
    }
    fetchCategories().then(categories => {
      setCategories(categories);
    });

    fetchPosts(category).then(posts => {
      setPosts(posts);
    });
  }, []);

  useEffect(() => {
    if (i18n.isInitialized) {
      console.log(i18n.language);
    }
  }, [i18n.isInitialized, i18n.language]);

  const fetchCategories = async () => {
    const res = await fetch(`${CMS_URL}/categorias`);

    return await res.json();
  };

  const fetchPosts = async category => {
    const qs = category ? `&_where[categorias.Nome]=${encodeURIComponent(category)}` : "";
    const res = await fetch(`${CMS_URL}/posts?_limit=6${qs}`);

    return await res.json();
  };

  const fetchMorePosts = async () => {
    const qs = selectedCategory ? `&_where[categorias.Nome]=${selectedCategory}` : "";
    const res = await fetch(`${CMS_URL}/posts?_limit=3&_start=${startIndex}${qs}`);

    const morePosts = await res.json();
    setPosts([...posts, ...morePosts]);
    setStartIndex(startIndex + 3);
  };
  const handleCategory = category => {
    window.location.search =
      category === selectedCategory ? "" : `?category=${encodeURIComponent(category)}`;
  };
  return (
    <BlogMain>
      <WavesBG
        style={{
          position: "absolute",
          height: "100%",
          backgroundImage: `url(${wavesBG})`,
          marginBottom: 0,
          width: "100vw",
          zIndex: 0,
          marginTop: 70,
          opacity: 0.1
        }}
      />
      <Wrapper>
        <LogoMargin>
          <Logo light={true} />
        </LogoMargin>
      </Wrapper>
      <Nav light={true} />
      <BlogContainer>
        <BlogHeading>
          <BlogTitleContainer>
            <BlogTitle>{t("title")} </BlogTitle>
            <BlogSubtitle>
              <Trans t={t} i18nKey="subtitle">
                We believe in the blockchain power to create new collaborative realities{" "}
                <ShowOnLG>
                  <br />
                </ShowOnLG>
                as a key to accelerate the exponential positive transformations.{" "}
                <ShowOnLG>
                  <br />
                </ShowOnLG>
              </Trans>
              <br />
              <Trans t={t} i18nKey="subtitle-2">
                From the team at Blockforce, invited experts, and eventually you. Talk to us and
                learn how to <MailLink href="mailto:hello@blockforce.in">contribute</MailLink>.
              </Trans>
            </BlogSubtitle>
          </BlogTitleContainer>
        </BlogHeading>
        <CategoryTitle>{t("categories")}</CategoryTitle>
        <BlogCategoryContainer>
          {categories.map(category => (
            <Category
              onClick={() => handleCategory(category["Nome"])}
              selected={category["Nome"] == selectedCategory}
            >
              {category["Nome"]}
            </Category>
          ))}
        </BlogCategoryContainer>
        {i18n.language && (
          <>
            <PostGrid lang={i18n.language} posts={posts} />
            <ButtonContainer>
              <Button onClick={fetchMorePosts}>{t("load-more")}</Button>
            </ButtonContainer>
          </>
        )}
      </BlogContainer>
      <Contact dark={true} lightToast={true} />
      <Footer dark={true} />
    </BlogMain>
  );
}
const MailLink = styled.a`
  font-weight: bold;
  text-decoration: none;
`;

const ShowOnLG = styled.div`
  @media screen and (max-width: 883px) {
    display: none;
  }
`;
const BlogMain = styled.div`
  background-color: white;
  height: 100%;
  color: black;
  body {
    background: white;
  }
`;

const BlogContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 80px auto;
  padding: 0 48px;
  max-width: 1496px;

  @media screen and (max-width: 800px) {
    padding: 0 24px;
  }
`;
const LogoMargin = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem 0 0 0;
`;
const BlogTitle = styled.h1`
  font-weight: 600;
  font-size: 2.5em;
  margin-bottom: 0;
`;
const BlogSubtitle = styled.h3`
  font-weight: 500;
`;
const BlogHeading = styled.div`
  display: flex;
`;

const BlogTitleContainer = styled.div`
  flex: 1;
`;
const BlogCategoryContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  @media screen and (min-width: 883px) {
    overflow-x: visible;
  }
`;
const Category = styled.div`
  color: ${({ selected }) => (selected ? "#000" : "#7e91b5")};
  text-transform: uppercase;
  margin-right: 24px;
  padding: 16px;
  border: 1px solid ${({ selected }) => (selected ? "#000" : "#7e91b5")};
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    color: black;
    border-color: black;
  }
  white-space: nowrap;
`;

const CategoryTitle = styled.h4`
  color: #7e91b5;
  text-transform: uppercase;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const Button = styled.a`
  height: 3.25rem;
  width: 464px;

  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  color: #f9f9f9;
  font-size: 15px;
  font-family: "Titillium Web", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 5px;
  margin: 0 0 16px 0;

  background: -moz-linear-gradient(
    90deg,
    rgba(0, 205, 144, 1) 0%,
    rgba(103, 46, 204, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(0, 205, 144, 1) 0%,
    rgba(103, 46, 204, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(0, 205, 144, 1) 0%,
    rgba(103, 46, 204, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );

  border: 0;
  &:hover {
    border: 0;
  }
`;
const WavesBG = styled.div`
  background-repeat: no-repeat;
  background-size: contain;
  pointer-events: none;
`;
